import { Link, useNavigate, generatePath } from 'react-router-dom';
import { SubmissionCard, submissionListPropTypes } from 'features/submission';
import { AutoScrollLoader, CardGrid, Button } from 'ui';
import ForumIcon from '@mui/icons-material/Forum';
import routes from 'default/routes';
import PropTypes from 'prop-types';

export default function ReceivedFeedback({ submissions }) {
    const navigate = useNavigate();
    return (
        <div className="old-all-mb-5">
            <CardGrid
                entries={submissions.all}
                card={SubmissionCard}
                onClick={(submission) => navigate(generatePath(routes.feedbackHubSubmission, { submissionId: submission.id }))}
                isOrg
                sizing={{
                    md: 6,
                    lg: 4,
                }}
            />
            <AutoScrollLoader
                fetchNextPage={submissions.fetchNextPage}
                hasNextPage={submissions.hasNextPage}
                isLoading={submissions.isLoading || submissions.isFetchingNextPage}
                error={submissions.error}
                empty={!submissions.all.length}
                errorButtonVariant="black"
                textClassName=""
            >
                <div className="bg-default-100 rounded-lg p-6 mb-4 text-center w-full">
                    <ForumIcon className="mx-auto mb-4 text-primary-200" />
                    <h3 className="text-xl uppercase font-semibold mb-2 text-primary-200">You have not received any feedback yet</h3>
                    <p className="text-gray-600 mb-4">
                        Getting feedback from other designers is one of the fastest ways to grow. Find a brief and make your first submission!
                    </p>
                    <Button
                        as={Link}
                        to={routes.briefs}
                        trackingName="view briefs"
                        color="primary"
                        size="lg"
                    >
                        Browse Briefs
                    </Button>
                </div>
            </AutoScrollLoader>
        </div>
    );
}
ReceivedFeedback.propTypes = {
    submissions: PropTypes.arrayOf(submissionListPropTypes).isRequired,
};
