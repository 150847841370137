import { useEffect, useState, useContext } from 'react';
import { UserContext } from 'features/user';
import { ProgressBar } from 'ui';
import AuthContext from 'features/authentication/context/AuthContext';
import OnboardingTopics from 'features/authentication/components/modal/Onboarding/OnboardingTopics';
import OnboardingUser from 'features/authentication/components/modal/Onboarding/OnboardingUser';
import OnboardingUsername from 'features/authentication/components/modal/Onboarding/OnboardingUsername';
import OnboardingComplete from 'features/authentication/components/modal/Onboarding/OnboardingComplete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const TOTALSTEPS = 4;

export default function Onboarding() {
    const { user } = useContext(UserContext);
    const { hideAuth } = useContext(AuthContext);
    const [step, setStep] = useState(1);

    useEffect(() => {
        // If the user has already completed the onboarding then we don't want them to see this page.
        if (user.metadata?.completed_onboarding)
            hideAuth();
    }, [user]);

    const handleCompleteOnboarding = () => {
        // If on mobile, scroll back to the top of the page
        window.scrollTo({ top: 0, left: 0 });
        hideAuth();
    };

    return (
        <div className="old-bg-white old-p-5 old-rounded-4" data-testid="user-onboarding">
            <div className="old-all-mb-3 old-mb-3">
                <div className="old-d-flex old-align-items-center">
                    {(step > 1) && (
                        <ArrowBackIcon className="!size-5 old-me-2" role="button" onClick={() => setStep((prev) => prev - 1)} />
                    )}
                    <div className="old-bg-white old-p-1 old-rounded-pill old-border old-border-2 old-border-secondary old-w-100">
                        <ProgressBar now={(100 / TOTALSTEPS) * step} className="old-rounded-pill old-bg-transparent" />
                    </div>
                </div>
                <b className="old-d-block old-fs-5 old-mt-3">
                    Step
                    {' '}
                    {step}
                    /
                    {TOTALSTEPS}
                </b>
            </div>

            {(step === 1) && (
                <OnboardingUsername onComplete={() => setStep(2)} />
            )}

            {(step === 2) && (
                <OnboardingUser onComplete={() => setStep(3)} />
            )}

            {(step === 3) && (
                <OnboardingTopics onComplete={() => setStep(4)} />
            )}

            {(step === 4) && (
                <OnboardingComplete onComplete={handleCompleteOnboarding} />
            )}
        </div>
    );
}
