import { useState, useMemo } from 'react';
import ErrorContext from 'features/report/context/ErrorContext';
import ReportFeedbackModal from 'features/report/components/modal/ReportFeedbackModal';
import PropTypes from 'prop-types';

export default function GlobalError({ isOrg = false, children }) {
    const [error, setError] = useState({
        show: false,
        options: {},
    });

    const showError = (options) => setError({ ...error, show: true, options });
    const hideError = () => setError({ ...error, show: false });

    const errorContextProviderValue = useMemo(() => ({ showError, hideError }), [showError, hideError]);

    return (
        <ErrorContext.Provider value={errorContextProviderValue}>
            { children }

            <ReportFeedbackModal
                show={error.show}
                onHide={hideError}
                isOrg={isOrg}
                {...error.options}
            />
        </ErrorContext.Provider>
    );
}

GlobalError.propTypes = {
    isOrg: PropTypes.bool,
    children: PropTypes.node.isRequired,
};
