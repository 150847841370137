import { PulseLoader } from 'react-spinners';
import OverlayTrigger from 'ui/popups/OverlayTrigger';
import Tooltip from 'ui/popups/Tooltip';
import Mixpanel from 'adapters/mixpanel';
import PropTypes from 'prop-types';

export default function StickyActionButton({
    name,
    tooltip,
    icon,
    onClick = undefined,
    trackingName = '',
    trackingLocation = '',
    className = '!size-10 old-p-2 old-rounded-circle old-bg-white old-text-black',
    wrapperClassName = '',
    loading = false,
    children = undefined,
    ...iconProps
}) {
    const Icon = icon;

    const handleTrackOnClick = (e) => {
        Mixpanel.trackButton('Action Button', trackingName, trackingLocation);

        if (onClick)
            onClick(e);
    };

    return (
        <div
            className="old-mb-4 text-center"
            onClick={handleTrackOnClick}
            role="button"
            tabIndex={0}
        >
            <div className={wrapperClassName}>
                <OverlayTrigger
                    placement="left"
                    overlay={(
                        <Tooltip id={name.toString().toLowerCase().replace(' ', '-')}>
                            <div className="old-bg-white old-rounded-pill old-px-2 old-py-2 old-d-flex old-shadow-xl">
                                <small className="font-roman old-fs-10">{tooltip}</small>
                            </div>
                        </Tooltip>
                      )}
                >
                    <div className={loading ? 'old-d-inline-flex' : ''}>
                        {(loading) ? (
                            <PulseLoader className={`old-d-block ${className}`} loading speedMultiplier={0.5} size={5} color="black" />
                        ) : (
                            <Icon
                                role="button"
                                className={className}
                                {...iconProps}
                            />
                        )}

                    </div>
                </OverlayTrigger>
                {children}
            </div>
            <p className="font-roman old-fs-10 old-mt-2 old-lh-sm old-text-reset old-d-block">{name}</p>
        </div>
    );
}

StickyActionButton.propTypes = {
    name: PropTypes.node.isRequired,
    tooltip: PropTypes.string.isRequired,
    icon: PropTypes.elementType.isRequired,
    onClick: PropTypes.func,
    trackingName: PropTypes.string,
    trackingLocation: PropTypes.string,
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
    loading: PropTypes.bool,
    children: PropTypes.node,
};
