import { useRef, useState, useContext } from 'react';
import { UserContext } from 'features/user';
import { ValidateUsername } from 'forms/wrappers/ValidationWrappers';
import { Form, Button } from 'ui';
import api from 'adapters/api';
import PropTypes from 'prop-types';
import FormControl from 'forms/FormControl';

export default function OnboardingUsername({ onComplete }) {
    const [loading, setLoading] = useState(false);
    const { user, setUser } = useContext(UserContext);
    const { email, username } = user;
    const usernameValidRef = useRef(true);

    const handleUpdateUsername = async (e) => {
        e.preventDefault();
        if (!usernameValidRef.current)
            return;

        setLoading(true);
        const { username: newUsername } = Object.fromEntries(new FormData(e.currentTarget));
        const res = await api.patch({ url: 'users/', data: { username: newUsername }, headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

        if (!res.success) {
            setLoading(false);
            return;
        }

        setUser(res.data);
        setLoading(false);
        onComplete();
    };

    return (
        <div data-testid="user-onboarding-username">
            <h2 className="capitalize old-mb-5 old-fs-1">Claim Your Username</h2>

            <Form onSubmit={handleUpdateUsername}>
                <Form.Group controlId="email" className="old-mb-5">
                    <Form.Text className="old-text-muted">
                        <p className="old-mb-0">Email</p>
                    </Form.Text>
                    <p>{email}</p>
                </Form.Group>

                <ValidateUsername
                    currentUsername={username}
                    ref={usernameValidRef}
                >
                    {({
                        handleCheckUsername,
                        usernameError,
                    }) => (
                        <>
                            <FormControl
                                name="username"
                                placeholder="Username"
                                label="Username"
                                aria-label="username"
                                className="old-bg-offprimary old-border-offprimary old-p-3 old-rounded-4 uppercase old-d-inline-block"
                                defaultValue={username || ''}
                                onUpdate={handleCheckUsername}
                                error={usernameError}
                                debounceTime={100}
                                key={`username${username}`}
                                maxLength={15}
                            />

                            <Button
                                type="submit"
                                color="primary"
                                trackingName="continue onboarding"
                                isDisabled={usernameError || loading}
                                isLoading={loading}
                                fullWidth
                            >
                                Continue
                            </Button>
                        </>
                    )}
                </ValidateUsername>
            </Form>
        </div>
    );
}

OnboardingUsername.propTypes = {
    onComplete: PropTypes.func.isRequired,
};
