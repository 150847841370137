import { useContext } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, generatePath } from 'react-router-dom';
import routes from 'default/routes';
import { ErrorToast, ErrorContext } from 'features/report';
import { useProtectedAction } from 'features/authentication';
import { useEnterBrief as useApiEnterBrief } from 'features/brief/api';

const useEnterBrief = (briefId) => {
    const { showError } = useContext(ErrorContext);
    const navigate = useNavigate();
    const { mutate, isPending } = useApiEnterBrief();
    const redirectToPath = generatePath(routes.brief, { id: briefId });

    const [enterBrief] = useProtectedAction((briefUrlId) => {
        mutate({ id: briefUrlId }, {
            onSuccess: (_, { id }) => {
                if (id !== briefId)
                    navigate(redirectToPath, { replace: true, preventScrollReset: true });
            },
            onError: (error) => {
                toast(<ErrorToast
                    errorMessage={error.details.message}
                    showErrorModal={() => showError({
                        defaultReason: 'issue',
                        defaultPage: 'enter_brief',
                        apiError: error.details,
                    })}
                />, {
                    limit: 1,
                });
            },
        });
    }, {
        actionName: 'enterBrief',
        redirectToPath,
    });

    return { enterBrief, isPending };
};

export default useEnterBrief;
