import PropTypes from 'prop-types';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'api/client';

function QueryProvider({ children }) {
    return (
        <QueryClientProvider client={queryClient}>
            {children}
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
}

QueryProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { QueryProvider };
