import { useEffect, useContext } from 'react';
import { PulseLoader } from 'react-spinners';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import NavigationConfigContext from 'features/navigation/context/NavigationConfigContext';
import ProspectNavbar from 'features/navigation/components/navbar/ProspectNavbar';
import ProspectFooter from 'features/navigation/components/footer/ProspectFooter';
import DocumentMetaContext from 'features/navigation/context/DocumentMetaContext';
import usePageViewTracker from 'hooks/usePageViewTracker';
import DocumentMeta from 'react-document-meta';
import PropTypes from 'prop-types';

const defaultMeta = {
    title: 'Oditi',
    meta: {
        image: 'https://prospect100.com/static/media/prospect100icon.29fb2fdd8fd24d5a42e6.png',
        description: 'The home of design competitions',
        type: 'website',
    },
};

export default function PageLayout({
    children,
    className = '',
    isLoading = false,
    meta = defaultMeta.meta,
    pageName = '',
    showNav = true,
    showFooter = true,
    navbarProps = {},
}) {
    const { config } = useContext(NavigationConfigContext);
    const location = useLocation();
    usePageViewTracker();

    meta.image = meta?.image || defaultMeta.meta.image;
    meta.description = meta?.description || defaultMeta.meta.description;
    meta.type = meta?.type || defaultMeta.meta.type;
    meta.url = window.location.href;

    useEffect(() => {
        if (location.state && location.state.scrollToTop === false) {
            window.history.replaceState({}, document.title);
            return;
        }

        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, [location]);

    return (
        <DocumentMetaContext.Provider value={meta}>
            <DocumentMeta
                title={[defaultMeta.title, ...[config?.baseName].concat(pageName)].filter(Boolean).join(' | ')}
                description={meta.description}
                canonical={meta.url}
                meta={{
                    property: {
                        'og:image': meta.image,
                        'og:type': meta.type,
                        'og:description': meta.description,
                        'og:url': meta.url,
                        'og:site_name': 'Oditi',
                    },
                }}
            >
                <div
                    className={twMerge('min-h-dvh flex flex-col justify-between', className)}
                    data-testid={`${[...[''].concat(pageName)].filter(Boolean).join('-').toLocaleLowerCase()}-page`}
                >
                    <div>
                        {(showNav) && (
                            <ProspectNavbar {...navbarProps} />
                        )}

                        {(isLoading) ? (
                            <div className="old-full-size old-w-100 old-d-flex old-align-items-center old-justify-content-center old-text-white">
                                <PulseLoader className="old-loader text-foreground" loading speedMultiplier={0.5} size={20} />
                            </div>
                        ) : (
                            children
                        )}
                    </div>

                    {(showFooter) && (
                        <ProspectFooter />
                    )}
                </div>
            </DocumentMeta>
        </DocumentMetaContext.Provider>
    );
}

PageLayout.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    meta: PropTypes.shape({
        image: PropTypes.string,
        description: PropTypes.string,
        type: PropTypes.string,
        url: PropTypes.string,
    }),
    pageName: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    showNav: PropTypes.bool,
    showFooter: PropTypes.bool,
    navbarProps: PropTypes.shape({}),
};
