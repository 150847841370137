import axios from 'axios';
import {
    QueryClient,
    MutationCache,
    matchQuery,
} from '@tanstack/react-query';
import { axiosErrorToObject } from 'adapters/api';

const defaultStaleTime = 30 * 1000; // 30 seconds

const client = axios.create({
    baseURL: process.env.REACT_APP_API_SERVER_URL + process.env.REACT_APP_API_BASE_URL,
    paramsSerializer: {
        indexes: null,
    },
});

client.interceptors.response.use(
    (res) => res,
    (err) => Promise.reject(
        Object.assign(err, {
            parseError: (formData) => axiosErrorToObject(err.response?.data?.errors, formData) || {},
            details: {
                message: err.response?.data?.reason || err.message,
                timestamp: Date.now(),
            },
        }),
    ),
);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: defaultStaleTime,
        },
    },
    mutationCache: new MutationCache({
        onSuccess: (_data, _variables, _context, mutation) => {
            queryClient.invalidateQueries({
                predicate: (query) => (
                    // invalidate all matching tags at once
                    // or everything if no meta is provided
                    mutation.meta?.invalidates?.some((queryKey) => matchQuery({ queryKey }, query)) ?? true
                ),
            });
        },
    }),
});

export { client, queryClient };
