import PropTypes from 'prop-types';
import { publicUserPropTypes, smallUserPropTypes } from 'features/user';
import { smallBriefPropTypes } from 'features/brief';

const submissionPropTypes = PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    user: publicUserPropTypes.isRequired,
    brief: smallBriefPropTypes.isRequired,
    description: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    voted: PropTypes.bool.isRequired,
    votes: PropTypes.number.isRequired,
    can_edit: PropTypes.bool.isRequired,
    is_winner: PropTypes.string,
});

const submissionListPropTypes = PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    user: smallUserPropTypes.isRequired,
    brief: smallBriefPropTypes.isRequired,
    voted: PropTypes.bool.isRequired,
    votes: PropTypes.number.isRequired,
    is_winner: PropTypes.string,
    created_at: PropTypes.string.isRequired,
});

const submissionSpotlightPropTypes = PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    user: publicUserPropTypes.isRequired,
    brief: smallBriefPropTypes.isRequired,
    created_at: PropTypes.string.isRequired,
});

export default submissionPropTypes;
export {
    submissionListPropTypes,
    submissionSpotlightPropTypes,
};
