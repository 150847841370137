import { useState, useEffect } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { Tabs, Tab, Modal } from 'ui';
import { ProfileDataCard } from 'features/profile';
import api from 'adapters/api';
import routes from 'default/routes';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export default function SubmissionVotesModal({
    submissionId,
    onHide,
    show = false,
}) {
    const [voters, setVoters] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchVoters = async () => {
            setLoading(true);
            const res = await api.get({ url: `submissions/${submissionId}/voters/` });

            if (res.success)
                setVoters(res.data);

            setLoading(false);
        };

        if (submissionId && show)
            fetchVoters();
    }, [submissionId, show]);

    return (
        <Modal
            isOpen={show}
            onOpenChange={onHide}
            data-testid="submission-votes-modal"
            scrollBehavior="outside"
            classNames={{
                base: 'overflow-y-visible p-6',
                closeButton: '-mt-10 bg-content1 shadow-2xl',
            }}
        >
            <Tabs
                color="primary"
                radius="full"
                fullWidth
            >
                <Tab key="voters" title="Your Voters">
                    {(!loading) ? (
                        <div className="old-mt-3">
                            {voters.map((voter) => (
                                <Link
                                    to={generatePath(routes.userProfile, { username: voter.user.username })}
                                    className="old-w-100 old-d-flex old-align-items-center old-justify-content-between old-overflow-hidden"
                                    key={voter.user.id}
                                >
                                    <ProfileDataCard
                                        profile={voter.user}
                                        icon={voter.user.icon}
                                        name={voter.user.username}
                                        profileType="People"
                                    />
                                    <small className="old-text-muted text-center old-text-truncate">{dayjs().to(dayjs(voter.created_at))}</small>
                                </Link>
                            ))}
                        </div>
                    ) : (
                        <div className="old-mt-3">
                            <ProfileDataCard.Loading />
                            <ProfileDataCard.Loading />
                        </div>
                    )}
                </Tab>
            </Tabs>
        </Modal>
    );
}

SubmissionVotesModal.propTypes = {
    submissionId: PropTypes.number.isRequired,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
};
