import { forwardRef, useEffect } from 'react';
import { Dropdown, Form } from 'ui';
import useToggleButtons, { reducer as onChange } from 'hooks/useToggleButtons';
import PropTypes from 'prop-types';

const DropdownItem = forwardRef(({
    children, controlId, checked, handleOnChange,
}, ref) => (
    <Form.Group ref={ref} className="old-py-1 old-px-3 old-mb-0" controlId={controlId}>
        <Form.Check
            id={controlId}
            type="checkbox"
            label={children}
            checked={checked}
            onChange={() => handleOnChange(controlId)}
        />
    </Form.Group>
));

DropdownItem.propTypes = {
    children: PropTypes.node.isRequired,
    controlId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    checked: PropTypes.bool.isRequired,
    handleOnChange: PropTypes.func.isRequired,
};

const FormCheckboxDropdown = forwardRef(({
    onUpdate, defaultValue, placeholder, oneOrTheOther, id, ...toggleProps
}, ref) => {
    const [state, dispatch] = useToggleButtons(defaultValue);

    useEffect(() => dispatch({ payload: defaultValue }), [defaultValue]);

    const handleOnChange = (idToChange) => {
        // selecting any key already selected does nothing
        if (oneOrTheOther && Object.keys(state).find((option) => state[option].checked) === idToChange)
            return;

        const checkNewState = { type: 'TOGGLE', id: idToChange, oneOrTheOther };
        const newState = onChange(state, checkNewState);

        if (onUpdate)
            onUpdate(newState);

        dispatch(checkNewState);
    };

    return (
        <Dropdown align="end" ref={ref}>
            <Dropdown.Toggle {...toggleProps}>
                {placeholder}
            </Dropdown.Toggle>

            <Dropdown.Menu className="old-shadow-sm old-border-0 old-rounded-4 old-checkbox-dropdown">
                {Object.keys(state).map((option) => (
                    <Dropdown.Item
                        as={DropdownItem}
                        checked={state[option].checked}
                        controlId={option + id}
                        key={option + id}
                        handleOnChange={handleOnChange}
                    >
                        <span>{state[option].label}</span>
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
});

FormCheckboxDropdown.propTypes = {
    onUpdate: PropTypes.func.isRequired,
    defaultValue: PropTypes.objectOf(PropTypes.shape({
        checked: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
    })),
    placeholder: PropTypes.string,
    oneOrTheOther: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FormCheckboxDropdown.defaultProps = {
    defaultValue: {},
    placeholder: 'Sort By',
    oneOrTheOther: false,
    id: '',
};

export default FormCheckboxDropdown;
