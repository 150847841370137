import {
    client, useMutation, useQueryClient, useQuery,
} from 'api';

const feedbackQueries = {
    list: (id) => [{ url: `submissions/${id}/feedback/`, view: 'list' }],
};

const useFeedback = (id) => useQuery({
    queryKey: feedbackQueries.list(id),
    queryFn: async ({ queryKey: [{ url }] }) => {
        const response = (await client.get(url));
        return response.data;
    },
});

const useCreateFeedback = (id) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (feedbackData) => client.post(`submissions/${id}/feedback/`, feedbackData),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: feedbackQueries.list(id) });
        },
    });
};

export { useFeedback, useCreateFeedback };
