import { briefStatusContent } from 'features/brief/data/briefData';
import { Button, Modal } from 'ui';
import PropTypes from 'prop-types';
import PaletteIcon from '@mui/icons-material/Palette';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import briefPropTypes from 'features/brief/schemas/briefSchema';

export default function BriefStatusModal({
    onHide,
    show = false,
    brief,
}) {
    const { instructions } = briefStatusContent(brief)[brief.status];

    return (
        <Modal
            isOpen={show}
            onOpenChange={onHide}
            data-testid="brief-status-modal"
        >
            <Modal.ModalHeader>{`This brief is in the ${brief.status === 'completed' ? 'after-party' : brief.status} phase`}</Modal.ModalHeader>

            <Modal.ModalBody>
                <div className="flex items-center mb-3 gap-4">
                    <PaletteIcon className="!size-8" color={instructions.submission[0] ? 'success' : 'disabled'} />
                    <small className="font-roman">{instructions.submission[1]}</small>
                </div>

                <div className="flex items-center mb-4 gap-4">
                    {instructions.visibility[0] ? (
                        <VisibilityIcon className="!size-8" color="success" />
                    ) : (
                        <VisibilityOffIcon className="!size-8" color="disabled" />
                    )}
                    <small className="font-roman">{instructions.visibility[1]}</small>
                </div>

                <div className="flex items-center gap-4">
                    <Diversity1Icon className="!size-8" color={instructions.voting[0] ? 'success' : 'disabled'} />
                    <small className="font-roman">{instructions.voting[1]}</small>
                </div>
            </Modal.ModalBody>

            <Modal.ModalFooter className="mx-auto">
                <Button
                    color="primary"
                    onClick={onHide}
                >
                    I Understand
                </Button>
            </Modal.ModalFooter>
        </Modal>
    );
}
BriefStatusModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
    brief: briefPropTypes.isRequired,
};
