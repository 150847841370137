const bootstrapPrefixes = {
    accordion: 'old-accordion',
    alert: 'old-alert',
    badge: 'old-badge',
    btn: 'old-btn',
    'btn-check': 'old-btn-check',
    'btn-close': 'old-btn-close',
    btnGroup: 'old-btn-group',
    card: 'old-card',
    container: 'old-container',
    carousel: 'old-carousel',
    collapse: 'old-collapse',
    row: 'old-row',
    col: 'old-col',
    dropdown: 'old-dropdown',
    'dropdown-menu': 'old-dropdown-menu',
    'dropdown-item': 'old-dropdown-item',
    'dropdown-toggle': 'old-dropdown-toggle',
    fade: 'old-fade',
    form: 'old-form',
    'form-control': 'old-form-control',
    'form-check': 'old-form-check',
    'form-check-input': 'old-form-check-input',
    'form-check-label': 'old-form-check-label',
    'form-label': 'old-form-label',
    img: 'old-img',
    figure: 'old-figure',
    nav: 'old-nav',
    'nav-link': 'old-nav-link',
    'nav-item': 'old-nav-item',
    navbar: 'old-navbar',
    offcanvas: 'old-offcanvas',
    'offcanvas-body': 'old-offcanvas-body',
    overlay: 'old-overlay',
    pagination: 'old-pagination',
    popover: 'old-popover',
    spinner: 'old-spinner',
    table: 'old-table',
    tabs: 'old-tabs',
    tab: 'old-tab',
    'tab-pane': 'old-tab-pane',
    'tab-content': 'old-tab-content',
    toast: 'old-toast',
    tooltip: 'old-tooltip',
    stack: 'old-stack',
    modal: 'old-modal',
    'modal-title': 'old-modal-title',
    'modal-body': 'old-modal-body',
    placeholder: 'old-placeholder',
    progress: 'old-progress',
};

export default bootstrapPrefixes;
