function SvgP100VerifiedBadge(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 44.01 42.01"
            fill="currentColor"
            {...props}
        >
            <path
                d="m15.3 42.01-3.8-6.5-7.55-1.55.85-7.35L0 21l4.8-5.55-.85-7.35 7.55-1.55L15.3 0 22 3.1 28.7 0l3.85 6.55 7.5 1.55-.85 7.35L44 21l-4.8 5.6.85 7.35-7.5 1.55L28.7 42 22 38.9 15.3 42Z"
            />
            <path
                d="M17.19 33.1c-1.29-2.78-2.69-7.64-2.69-12.2s.74-8.88 2.32-11.8c-2.58.95-5.6 3.62-7.04 7.23-.48 1.28-.85 2.7-.88 4.27.48-1.02 2.8-4.09 3.5-5.01-.48 1.24-.77 3.58-.77 6.14 0 4.64 1.4 7.53 2.17 9.43.99.73 2.06 1.46 3.39 1.94m-9.5-12.06c0-7.86 6.41-14.25 14.33-14.25s14.29 6.39 14.29 14.25-6.37 14.18-14.29 14.18S7.69 28.83 7.69 21.04m7.88-.04c0 3.73 1.03 8.51 2.43 10.96.89 1.5 1.99 2.05 3.61 2.05s2.69-.55 3.54-2.05c1.4-2.45 2.47-7.23 2.47-10.96s-.59-7.86-1.66-10.23c-1.14-2.48-2.77-2.78-4.35-2.78s-3.28.29-4.38 2.78c-1.07 2.37-1.66 6.5-1.66 10.23M20 21c0 3.51.22 6.39.55 8.48.18 1.28.37 1.5 1.07 1.5.66 0 .81-.22 1.03-1.5.33-2.08.55-4.97.55-8.48 0-2.59-.22-6.36-.55-8.44-.22-1.28-.44-1.5-1.03-1.5-.63 0-.88.22-1.07 1.5-.33 2.08-.55 5.85-.55 8.44m6.41 11.69c0 .22.11.4.33.4 1.03 0 8.36-3.73 8.36-12.09S28.18 8.98 26.78 8.98c-.22 0-.37.11-.37.33s.22.51.37.84c1.33 2.67 1.92 6.8 1.92 10.85s-1.18 8.11-2.14 10.96c-.07.22-.15.48-.15.73m4.02-5.04c.55-1.61.81-4.53.81-6.65s-.26-5.04-.81-6.65c-.11-.37-.18-.62.15-.62.29 0 .66.44.88.84.67 1.42 1.11 4.02 1.11 6.43s-.48 5.01-1.15 6.43c-.18.29-.52.84-.92.84-.26 0-.18-.26-.07-.62"
                fill="#fff"
            />
        </svg>
    );
}
export default SvgP100VerifiedBadge;
