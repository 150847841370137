import { useState } from 'react';
import BootstrapAlert from 'react-bootstrap/Alert';
import PropTypes from 'prop-types';

export default function Alert({
    className = 'old-text-purple old-rounded-4 old-border-0 text-center',
    variant = 'info',
    children,
    ...props
}) {
    const [show, setShow] = useState(true);

    if (!show)
        return null;

    return (
        <BootstrapAlert
            className={className}
            onClose={() => setShow(false)}
            variant={variant}
            {...props}
        >
            {children}
        </BootstrapAlert>
    );
}
Alert.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string,
    children: PropTypes.node.isRequired,
};
