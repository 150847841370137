const enterBriefPolicies = [
    {
        name: 'age',
        agreement: () => 'You agree that you are over 18, or if not we require the consent of a parent or guardian. By continuing, you confirm that you have obtained the relevant permission.',
    },
    {
        name: 'terms',
        agreement: () => (
            <>
                You accept the Oditi (formerly PROSPECT 100)
                {' '}
                <a href="https://docs.google.com/document/d/110GBcyDBMh8JgiUykEgyOcuacAAxUwINBaU7-78ZY9E/edit?usp=sharing" target="__blank" className="old-text-underline">TERMS AND CONDITIONS</a>
                {' '}
                and consent to the processing of your personal data in accordance with our
                {' '}
                <a className="old-text-underline" href="https://www.termsfeed.com/live/6ec61b62-d177-4fb4-866b-6491a5595d9b">PRIVACY POLICY</a>
                . You also certify that your entry and artwork belongs to you and is your original work. It has not been plagiarised or copied in any way from another artist, brand, company or entity. You can find more information on copyright infringement here.
            </>
        ),
    },
    {
        name: 'marketing',
        agreement: ({ organisation }) => (
            <>
                Oditi (formerly PROSPECT 100) and
                {' '}
                {organisation?.name || 'the affiliated organisation'}
                {' '}
                might also look to create marketing materials to support this brief and, as such, may make your entry available on social media or any other media in connection with promotion of the brief. For editorial and compliance reasons, Oditi and
                {' '}
                {organisation?.name || 'the affiliated organisation'}
                {' '}
                might need to alter, reformat or edit your entry, as necessary. You agree, in connection with such marketing materials, to the extent applicable in accordance with law, to (1) waive all moral rights; and (2) to authorise Oditi and
                {' '}
                {organisation?.name || 'the affiliated organisation'}
                {' '}
                to use your name, likeness, voice, biography (as applicable) as detailed in your entry, for a period of ninety days after the brief closes.
            </>
        ),
    },
    {
        name: 'amfar',
        agreement: ({ id }) => (id === 10 ? <>I confirm that I am happy to donate my submitted artwork to amfAR</> : null),
    },
    {
        name: 'umusic',
        agreement: ({ organisation }) => (organisation?.name === 'Illenium' ? (
            <>
                Emails will be sent by or on behalf of UMG Recordings Services, Inc. 2220 Colorado Avenue, Santa Monica , CA 90404 (310) 865-4000. You may withdraw your consent at any time.
                {' '}
                <a className="old-text-underline" href="https://privacy.umusic.com/">Privacy Policy.</a>
                {' '}
                <a className="old-text-underline" href="https://www.universalmusic.com/CCPA/">Do Not Sell My Personal Information.</a>
            </>
        ) : null),
    },
];

export {
    enterBriefPolicies,
};
