import { useContext } from 'react';
import {
    useParams, useNavigate, useLocation, useSearchParams,
} from 'react-router-dom';
import { UserContext } from 'features/user';
import { Modal, Button } from 'ui';
import { useFeedback } from 'features/feedback';
import useSubmissionWithActions from 'features/submission/hooks/useSubmissionWithActions';
import PropTypes from 'prop-types';
import SubmissionBody from 'features/submission/components/modal/SubmissionModal/SubmissionBody';
import SubmissionHeader from 'features/submission/components/modal/SubmissionModal/SubmissionHeader';
import CloseIcon from '@mui/icons-material/Close';

export default function SubmissionModal({
    showVoteButton = true,
    isOrg = false,
}) {
    const { submissionId } = useParams();
    const {
        submission, isLoading: isSubmissionLoading, handleVote, handleSelectWinner, handleDeselectWinner,
    } = useSubmissionWithActions(submissionId);
    const { data: feedback } = useFeedback(submissionId);

    const { state } = useLocation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { user } = useContext(UserContext);

    const handleCloseSubmissionModal = () => {
        let [targetURL] = window.location.pathname.split('submission');
        if (isOrg)
            [targetURL] = window.location.pathname.split('org')[1].split('submission');

        return navigate(`${state?.referrer || targetURL}?${searchParams.toString()}`, {
            state: {
                scrollToTop: false,
            },
        });
    };

    return (
        <Modal
            defaultOpen
            onClose={handleCloseSubmissionModal}
            data-testid="submission-modal"
            scrollBehavior="outside"
            hideCloseButton
            classNames={{
                base: 'bg-transparent border-none shadow-none max-w-6xl w-full sm:w-4/5 !m-0 sm:!-mt-8',
                backdrop: 'bg-overlay/80',
            }}
        >
            <Button
                isIconOnly
                className="bg-content1 w-8 h-8 min-w-0 absolute sm:sticky self-end right-0 top-8 mr-4 sm:-mr-14"
                onClick={handleCloseSubmissionModal}
            >
                <CloseIcon className="p-1" />
            </Button>

            {(!isSubmissionLoading && submission) ? (
                <>
                    <SubmissionHeader
                        submission={submission}
                        handleVote={handleVote}
                        handleSelectWinner={handleSelectWinner}
                        handleDeselectWinner={handleDeselectWinner}
                        allowSelectWinner={isOrg}
                        allowSelectSpotlight={isOrg && submission.brief?.type === 'public' && submission.brief?.status !== 'submitting'}
                        showVoteButton={!isOrg && showVoteButton}
                        hasGivenFeedback={feedback?.comments?.some((f) => f.user.id === user.id)}
                    />

                    <SubmissionBody
                        submission={submission}
                        feedback={feedback ?? {}}
                        handleVote={handleVote}
                        allowSelectWinner={isOrg}
                        showVoteButton={!isOrg && showVoteButton}
                        handleCloseSubmissionModal={handleCloseSubmissionModal}
                    />
                </>
            ) : (
                <>
                    <SubmissionHeader.Loading />
                    <SubmissionBody.Loading />
                </>
            )}
        </Modal>
    );
}

SubmissionModal.propTypes = {
    submission: PropTypes.shape({}).isRequired,
    showVoteButton: PropTypes.bool,
    isOrg: PropTypes.bool,
};
