import { twMerge } from 'tailwind-merge';
import Modal from 'ui/modals/Modal';
import PropTypes from 'prop-types';
import Button from 'ui/buttons/Button';

export default function ModalActionsFooter({
    onCancel,
    onConfirm = undefined,
    cancelProps = {},
    confirmProps = {},
    cancelText = 'Cancel',
    confirmText = 'Confirm',
    className = '',
}) {
    return (
        <Modal.ModalFooter className={twMerge('justify-center', className)}>
            <Button
                onClick={onCancel}
                variant="faded"
                {...cancelProps}
            >
                {cancelText}
            </Button>

            <Button
                onClick={onConfirm}
                type="submit"
                color="primary"
                {...confirmProps}
            >
                {confirmText}
            </Button>
        </Modal.ModalFooter>
    );
}

ModalActionsFooter.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    cancelProps: PropTypes.shape({}),
    confirmProps: PropTypes.shape({}),
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
    className: PropTypes.string,
};
