import { useLocation, Link } from 'react-router-dom';
import { css } from 'aphrodite';
import { Container, Navbar } from 'ui';
import NavbarSpacer from 'features/navigation/components/layout/NavbarSpacer';
import PropTypes from 'prop-types';
import animationStyles from 'ui/animations';
import useScrollDirection from 'features/navigation/hooks/useScrollDirection';
import useScrollAmount from 'features/navigation/hooks/useScrollAmount';

export default function NavbarMobile({
    homeLink,
    icon,
    collapse,
    size = undefined,
    mobileRoutes = [],
}) {
    const { pathname, state } = useLocation();
    const scrollDirection = useScrollDirection();
    const scrollReached = useScrollAmount(0.01);

    return (
        <div className={`old-w-100 old-d-${collapse}-none`}>
            <NavbarSpacer size={size} />

            <Navbar
                classNames={{
                    base: `backdrop-blur-none backdrop-saturate-100 fixed old-z-4 old-bg-opacity-50 transition-[top] ease-linear duration-100 delay-0 ${scrollReached ? 'backdrop-blur-lg bg-background/50 text-foreground' : 'bg-transparent'} ${(scrollDirection !== 'down') ? 'top-0' : '-top-16'}`,
                    wrapper: 'px-0 flex items-center justify-center',
                }}
                maxWidth="full"
                height="4rem"
            >
                <Link to={homeLink}>
                    <img src={icon} alt="oditi Company Icon" className="!size-10" />
                </Link>
            </Navbar>

            <footer className="fixed bottom-0 left-0 w-full h-16 old-z-5 old-shadow bg-background text-foreground">
                <Container className="h-full">
                    <div className="h-full flex flex-row justify-around items-center">
                        {mobileRoutes.map(({
                            onClick, name, matchPath, icon: Icon, selectedIcon: SelectedIcon,
                        }) => (
                            <div
                                key={name}
                                role="button"
                                tabIndex={0}
                                onClick={onClick}
                                className="flex flex-col items-center justify-center gap-1"
                            >
                                {((pathname.split('/')[1] === name.toLowerCase()) || (pathname === matchPath)) ? (
                                    <SelectedIcon className={`!size-8 rounded-full p-1 flex items-center ${state?.referrer === 'navbar' ? css(animationStyles.flipIn) : ''}`} />
                                ) : (
                                    <Icon className="!size-8 rounded-full p-1 flex items-center" />
                                )}
                                <small className="font-roman -mt-1 tracking-wider text-2xs">{name}</small>
                            </div>
                        ))}
                    </div>
                </Container>
            </footer>
        </div>
    );
}

NavbarMobile.propTypes = {
    homeLink: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    collapse: PropTypes.string.isRequired,
    size: PropTypes.string,
    mobileRoutes: PropTypes.arrayOf(PropTypes.shape({})),
};
