import { useState, forwardRef } from 'react';
import { abbreviateNumber } from 'utils/helpers';
import { StickyActionButton } from 'ui';
import useAnimateVoteButton from 'hooks/useAnimateVoteButton';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import animationStyles from 'ui/animations';
import VoteIcon from 'icons/VoteIcon';
import PropTypes from 'prop-types';
import Mixpanel from 'adapters/mixpanel';

const VotedIcon = forwardRef(({
    votes, animateVerified, animateVotes, ...props
}, ref) => (
    <div ref={ref} {...props}>
        <div className="old-d-flex old-flex-column old-align-items-center old-justify-content-center old-h-100">
            <VerifiedOutlinedIcon ref={animateVerified} className="!size-4" />
            <small className="old-fs-12" ref={animateVotes}>{abbreviateNumber(votes, 1)}</small>
        </div>
    </div>
));

VotedIcon.propTypes = {
    votes: PropTypes.number.isRequired,
    animateVerified: PropTypes.func.isRequired,
    animateVotes: PropTypes.func.isRequired,
};

export default function SubmissionStickyActionVoteButton({
    handleVote,
    hasVoted = true,
    numberVotes = 0,
    allowSelectWinner = false,
    ...props
}) {
    const [loading, setLoading] = useState(false);
    const { animateVerified, animateVotes } = useAnimateVoteButton(hasVoted, animationStyles.bounceInUp);

    const handleVoteButtonVote = async (e) => {
        setLoading(true);
        await handleVote(e);
        setLoading(false);

        Mixpanel.trackButton('Action Button', 'vote', 'submission actions');
    };

    if (hasVoted || allowSelectWinner) {
        return (
            <StickyActionButton
                name={allowSelectWinner ? 'Votes' : 'Voted'}
                tooltip={allowSelectWinner ? 'Votes Received' : 'Thanks For Your Vote'}
                wrapperClassName="old-d-inline-block"
                icon={VotedIcon}
                votes={numberVotes}
                animateVerified={animateVerified}
                animateVotes={animateVotes}
                {...props}
            />
        );
    }

    return (
        <StickyActionButton
            name="Vote"
            tooltip="Vote for this submission"
            wrapperClassName="old-d-inline-block"
            icon={VoteIcon}
            onClick={(e) => handleVoteButtonVote(e)}
            loading={loading}
            {...props}
        />
    );
}

SubmissionStickyActionVoteButton.propTypes = {
    handleVote: PropTypes.func.isRequired,
    hasVoted: PropTypes.bool,
    numberVotes: PropTypes.number,
    allowSelectWinner: PropTypes.bool,
};
