import { useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';
import PropTypes from 'prop-types';
import { ChevronRight, ChevronLeft } from '@mui/icons-material';
import useScroll from 'hooks/useScroll';

const swiper = tv({
    slots: {
        base: 'relative my-2',
        wrapper: 'flex w-full flex-nowrap px-6 gap-x-5 overflow-x-auto overflow-hide scrollbar-hide',
        leftGradient: 'absolute flex h-full inset-y-0 bg-gradient-to-r to-40% transition-opacity duration-300',
        rightGradient: 'absolute flex h-full inset-y-0 right-0 bg-gradient-to-l to-40% transition-opacity duration-300',
        button: 'touch-device:invisible mx-3 self-center rounded-full flex justify-center items-center bg-background bg-opacity-90 drop-shadow',
    },
    variants: {
        color: {
            default: {
                leftGradient: 'from-default-800',
                rightGradient: 'from-default-800',
            },
            primary: {
                leftGradient: 'from-primary-800',
                rightGradient: 'from-primary-800',
            },
            content2: {
                leftGradient: 'from-content2',
                rightGradient: 'from-content2',
            },
            transparent: {
                leftGradient: 'from-transparent',
                rightGradient: 'from-transparent',
            },
            background: {
                leftGradient: 'from-background',
                rightGradient: 'from-background',
            },
        },
    },
    defaultVariants: {
        color: 'default',
    },
});

export default function Swiper({ color = 'default', children }) {
    const scrollRef = useRef(null);
    const {
        velocity, atEnd, atStart, scrollRight, scrollLeft,
    } = useScroll(scrollRef);
    const {
        base, wrapper, leftGradient, rightGradient, button,
    } = swiper({ color });

    return (
        <div className={base()}>
            <div className={wrapper()} ref={scrollRef}>
                {children}
            </div>
            <div className={twMerge(leftGradient(), (atStart || velocity.x < 0) && 'opacity-0')}>
                <div
                    role="button"
                    tabIndex={0}
                    className={button()}
                    onClick={(e) => { e.stopPropagation(); scrollLeft(); }}
                >
                    <ChevronLeft fontSize="large" className="text-foreground" />
                </div>
            </div>
            <div className={twMerge(rightGradient(), (atEnd || velocity.x > 0) && 'opacity-0')}>
                <div
                    role="button"
                    tabIndex={0}
                    className={button()}
                    onClick={(e) => { e.stopPropagation(); scrollRight(); }}
                >
                    <ChevronRight fontSize="large" className="text-foreground" />
                </div>
            </div>
        </div>
    );
}

Swiper.propTypes = {
    color: PropTypes.string,
    children: PropTypes.node.isRequired,
};
