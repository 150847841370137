import { useState, forwardRef } from 'react';
import { Form } from 'ui';
import useDebounce from 'hooks/useDebounce';
import PropTypes from 'prop-types';
import FormWrapper, { getWrapperProps } from 'forms/wrappers/FormWrapper';

const FormControl = forwardRef(({
    onUpdate, defaultValue, debounceTime, className, ...props
}, ref) => {
    const [state, setState] = useState(defaultValue);
    const [wrapperProps, inputProps] = getWrapperProps(props);

    useDebounce(() => {
        if (onUpdate)
            onUpdate(state);
    }, [state], debounceTime);

    return (
        <FormWrapper {...wrapperProps}>
            <Form.Control
                className={className}
                value={state}
                onChange={(e) => setState(e.target.value)}
                data-role="form-error-component"
                ref={ref}
                {...inputProps}
            />
            {(inputProps.maxLength) && (
                <small className="old-position-absolute old-end-2 old-pe-2 old-bottom-2 font-bold">
                    {state.length}
                    {' '}
                    /
                    {' '}
                    {inputProps.maxLength}
                </small>
            )}
        </FormWrapper>
    );
});

FormControl.propTypes = {
    onUpdate: PropTypes.func,
    defaultValue: PropTypes.string,
    debounceTime: PropTypes.number,
    className: PropTypes.string,
};

FormControl.defaultProps = {
    onUpdate: undefined,
    defaultValue: '',
    debounceTime: 800,
    className: '!bg-content2 !border-content2 !placeholder-primary-300 !text-foreground old-rounded-4 old-p-3 old-d-inline-block',
};

export default FormControl;
