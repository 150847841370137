import useWindowSize from 'hooks/useWindowSize';
import {
    useState, useMemo, useEffect, useCallback,
} from 'react';

export default function useScroll(ref) {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [velocity, setVelocity] = useState({ x: 0, y: 0 });
    const [windowWidth] = useWindowSize();
    const el = useMemo(() => ref?.current, [ref?.current]);

    useEffect(() => {
        if (!el)
            return undefined;

        let prevPosition = { x: 0, y: 0 };
        let ticking = false;

        const updatePosition = () => {
            ticking = false; // reset the tick so we can capture the next onScroll

            const newPosition = {
                x: el?.scrollLeft ?? 0,
                y: el?.scrollTop ?? 0,
            };

            setPosition(newPosition);
            setVelocity({
                x: newPosition.x - prevPosition.x,
                y: newPosition.y - prevPosition.y,
            });

            prevPosition = newPosition;
        };

        updatePosition();

        function onScroll() {
            ticking = ticking || requestAnimationFrame(updatePosition);
        }

        el.addEventListener('scroll', onScroll);
        return () => {
            el.removeEventListener('scroll', onScroll);
        };
    }, [el, setPosition]);

    const scroll = useCallback((amount, behavior) => {
        if (!el)
            return;

        const desiredX = el.clientWidth * amount.x + position.x;
        const desiredY = el.clientHeight * amount.y + position.y;
        const maxScrollX = el.scrollWidth - el.clientWidth;
        const maxScrollY = el.scrollHeight - el.clientHeight;

        el.scrollTo({
            left: Math.min(Math.max(desiredX, 0), maxScrollX),
            top: Math.min(Math.max(desiredY, 0), maxScrollY),
            behavior,
        });
    }, [el?.scrollTo, el?.clientWidth, el?.clientHeight, el?.scrollWidth, el?.scrollHeight, position]);

    const atEdge = useMemo(() => {
        if (!el) {
            return {
                atStart: true, atEnd: true, atTop: true, atBottom: true,
            };
        }

        return {
            atStart: Math.floor(position.x) === 0,
            atEnd: Math.ceil(position.x) >= el.scrollWidth - el.clientWidth,
            atTop: Math.floor(position.y) === 0,
            atBottom: Math.ceil(position.y) >= el.scrollHeight - el.clientHeight,
        };
    }, [el?.scrollWidth, el?.clientWidth, position, windowWidth]);

    return {
        position,
        velocity,
        ...atEdge,
        scrollRight: (amount = 0.75, behavior = 'smooth') => scroll({ x: amount, y: 0 }, behavior),
        scrollLeft: (amount = 0.75, behavior = 'smooth') => scroll({ x: -amount, y: 0 }, behavior),
        scrollDown: (amount = 0.75, behavior = 'smooth') => scroll({ x: 0, y: amount }, behavior),
        scrollUp: (amount = 0.75, behavior = 'smooth') => scroll({ x: 0, y: -amount }, behavior),
    };
}
