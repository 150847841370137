import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { generatePath } from 'utils/helpers';
import { UserBadge } from 'features/user';
import { ProfileIcon } from 'features/profile';
import { NavigationConfigContext } from 'features/navigation';
import { Shimmer, ButtonGroup } from 'ui';
import routes from 'default/routes';
import submissionPropTypes from 'features/submission/schemas/submissionSchema';
import SubmissionVoteButton from 'features/submission/components/button/SubmissionVoteButton';
import SubmissionSpotlightSelectButton from 'features/submission/components/button/SubmissionSpotlightSelectButton';
import SubmissionWinnerSelectButton from 'features/submission/components/button/SubmissionWinnerSelectButton';
import PropTypes from 'prop-types';

function SubmissionHeaderLoading() {
    return (
        <div className="old-d-flex old-justify-content-between old-align-items-center old-pb-5 old-mt-6">
            <div className="old-d-flex old-align-items-center old-w-50">
                <Shimmer className="old-bg-blank old-ratio old-ratio-1x1 !size-16 old-rounded-circle" />

                <div className="old-mx-3 old-w-50">
                    <Shimmer className="old-bg-blank old-w-100 old-p-2 old-rounded old-h-25" />
                    <Shimmer className="old-bg-blank old-w-50 old-p-2 old-mt-2 old-rounded old-h-25" />
                </div>
            </div>

            <div className="old-w-50 old-text-end old-d-flex old-justify-content-end">
                <Shimmer className="old-bg-blank old-w-25 old-p-4 old-rounded-pill old-h-100" />
            </div>
        </div>
    );
}

export default function SubmissionHeader({
    submission,
    handleVote,
    handleSelectWinner,
    handleDeselectWinner,
    allowSelectWinner = false,
    allowSelectSpotlight = false,
    showVoteButton = true,
    hasGivenFeedback = false,
}) {
    const { config } = useContext(NavigationConfigContext);

    return (
        <div className="flex flex-col md:flex-row justify-between md:items-center py-8 text-primary-foreground px-4 sm:px-0 gap-4">
            <div className="flex items-center w-full">
                <ProfileIcon
                    icon={submission.user?.icon}
                    className="!size-16"
                />
                <div className="old-mx-3">
                    <div className="old-d-flex old-align-items-center old-flex-wrap old-gap-2">
                        <span className="old-text-thin font-roman old-description-1 me-8 sm:me-0">
                            {submission.title || `"${submission.brief.title}" Submission`}
                        </span>

                        {(!submission.published) && (
                            <>
                                <span className="old-p-1 old-bg-white old-rounded-circle" />
                                <span className="old-bg-purple old-text-white old-px-2 old-p-1 old-rounded-pill">Draft</span>
                            </>
                        )}

                        {(submission.type === 'proposal') && (
                            <>
                                <span className="old-p-1 old-bg-white old-rounded-circle" />
                                <span className="old-bg-purple old-text-white old-px-2 old-p-1 old-rounded-pill">Proposal</span>
                            </>
                        )}
                    </div>

                    <Link
                        className="inline-flex items-center"
                        to={generatePath(config.redirectDefault, { wildcard: generatePath(routes.userProfile, { username: submission.user?.username || '' }) })}
                    >
                        <span className="old-d-inline-block old-transition-underline uppercase">{submission.user?.username}</span>
                        <UserBadge className="!size-4 old-ms-1" profile={submission.user} />
                    </Link>
                </div>
            </div>

            {(allowSelectSpotlight || allowSelectSpotlight) && (
                <ButtonGroup fullWidth>
                    {(allowSelectSpotlight) && (
                    <SubmissionSpotlightSelectButton submission={submission} />
                    )}

                    {(allowSelectWinner) && (
                    <SubmissionWinnerSelectButton
                        handleSelectWinner={handleSelectWinner}
                        handleDeselectWinner={handleDeselectWinner}
                        isWinner={submission.is_winner}
                        text={submission.type === 'submission' ? 'Make Winner' : 'Accept'}
                        undoText={submission.type === 'submission' ? 'Winner' : 'Accepted'}
                    />
                    )}
                </ButtonGroup>
            )}

            {(showVoteButton) && (
                <div className="hidden md:block">
                    <SubmissionVoteButton
                        hasVoted={submission.voted}
                        numberVotes={parseInt(submission.votes, 10)}
                        handleVote={handleVote}
                        trackingLocation="submission header"
                        hasGivenFeedback={hasGivenFeedback}
                        submissionId={submission.id}
                    />
                </div>
            )}
        </div>
    );
}

SubmissionHeader.propTypes = {
    submission: submissionPropTypes.isRequired,
    handleVote: PropTypes.func.isRequired,
    handleSelectWinner: PropTypes.func.isRequired,
    handleDeselectWinner: PropTypes.func.isRequired,
    allowSelectWinner: PropTypes.bool,
    allowSelectSpotlight: PropTypes.bool,
    showVoteButton: PropTypes.bool,
    hasGivenFeedback: PropTypes.bool,
};

SubmissionHeader.Loading = SubmissionHeaderLoading;
