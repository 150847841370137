import { forwardRef, useState } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { ProfileIcon } from 'features/profile';
import { Shimmer } from 'ui';
import routes from 'default/routes';
import { formatRelativeTime } from 'utils/helpers';
import PropTypes from 'prop-types';
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import feedbackPropTypes from '../feedbackSchema';

function FeedbackCardLoading() {
    return (
        <div className="old-rounded-4 old-bg-white old-p-3">
            <div className="old-d-flex old-align-items-center">
                <Shimmer className="old-bg-blank old-ratio old-ratio-1x1 !size-16 old-rounded-circle" />

                <div className="old-mx-3 old-w-50">
                    <Shimmer className="old-bg-blank old-w-100 old-p-2 old-rounded old-h-25" />
                    <Shimmer className="old-bg-blank old-w-50 old-p-2 old-mt-2 old-rounded old-h-25" />
                </div>
            </div>

            <Shimmer className="old-bg-blank old-w-100 old-rounded-4 old-mt-3" height={200} />
            <Shimmer className="old-bg-blank old-rounded-4 old-mt-3 old-w-25" height={30} />
        </div>
    );
}

const FeedbackCard = forwardRef(({
    comment,
    depth = 0,
    onReply,
    loading = false,
}, ref) => {
    const [showReplies, setShowReplies] = useState(false);
    const {
        user,
        content,
        children,
    } = comment;

    const relativeTime = formatRelativeTime(comment.created_at);
    const toggleReplies = () => setShowReplies((prev) => !prev);

    if (loading)
        return <FeedbackCardLoading />;

    return (
        <div className={`py-2 mx-1 bg-white ${depth > 0 ? 'pe-0 ml-3 sm:ml-6' : 'border-b-4'}`}>
            <div className="flex items-center gap-2">
                <div className="flex flex-grow items-center gap-2">
                    <Link
                        to={generatePath(routes.userProfile, { username: user.username })}
                        className="flex items-start items-center rounded-lg"
                    >
                        <div className="old-d-flex old-align-items-center">
                            <ProfileIcon
                                icon={user.icon}
                                className="!size-12"
                                variant="border"
                                country={user.country_code || ''}
                            />
                            <div className="flex flex-col ms-3 mb-2">
                                <span className="uppercase text-xl hover:text-blue-500">{user.username}</span>
                                <span className="text-xs text-default-300">{relativeTime === 'an hour ago' ? 'just now' : relativeTime}</span>
                            </div>
                        </div>
                    </Link>
                </div>
                {/* <div className="flex-shrink-0">
                    <button className="text-gray-400 hover:text-gray-600 transition-colors duration-300">
                        <MoreHorizIcon className="h-5 w-5" />
                    </button>
                </div> */}
            </div>

            <p className="text-gray-800 my-4 ms-1 leading-relaxed text-pretty overflow-clip">{content}</p>

            <div className="flex text-lg ms-1 mb-4">
                {children.length > 0 && (
                    <div className="text-default-500 flex-grow">
                        <button
                            onClick={toggleReplies}
                            className="hover:text-default-800 transition-colors duration-300 flex items-center"
                            type="button"
                        >
                            <span>{showReplies ? `Hide Replies (${children.length})` : `View Replies (${children.length})`}</span>
                        </button>
                    </div>
                )}
                <div className="flex gap-4 items-center text-default-500">
                    {/* <button className="flex items-center hover:text-pink-500 transition-colors duration-300">
                    <FavoriteBorderIcon className="h-6 w-6" />
                    <span>{like_count > 0 ? like_count : ''}</span>
                    </button> */}
                    {depth === 0 && (
                        <button
                            onClick={() => {
                                onReply(comment);
                                if (ref && ref.current)
                                    ref.current.focus();
                            }}
                            className="hover:text-default-800 transition-colors duration-300 flex items-center"
                            type="button"
                        >
                            <span>Reply</span>
                        </button>
                    )}
                </div>
            </div>
            <div className={depth === 0 && 'mt-2'}>
                {showReplies && children.map((childComment) => (
                    <FeedbackCard
                        key={childComment.id}
                        comment={childComment}
                        depth={depth + 1}
                    />
                ))}
            </div>
        </div>
    );
});
FeedbackCard.propTypes = {
    comment: feedbackPropTypes.isRequired,
    depth: PropTypes.number.isRequired,
    onReply: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

FeedbackCard.Loading = FeedbackCardLoading;

export default FeedbackCard;
