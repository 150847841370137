import {
    forwardRef, useEffect, Children, useRef, useMemo, cloneElement, useState,
} from 'react';
import useScrollTabs from 'hooks/useScrollTabs';
import PropTypes from 'prop-types';
import Container from 'ui/layout/Container';

function ScrollSections({ children, ...props }) {
    const elementsRef = useRef([]);
    const tabs = useMemo(() => Children.toArray(children).filter((child) => child.type?.displayName === 'ScrollSection' || child.type?.displayName === 'ScrollSectionFluid'), [children]);
    const [elements, setElements] = useState([]);
    const fixTabs = useScrollTabs(elements, [elements]);

    useEffect(() => {
        elementsRef.current = elementsRef.current.slice(0, tabs.length);
        setElements(elementsRef.current.map((element) => ({ current: element })));
    }, [tabs]);

    return (
        <div id="tabs" {...props}>
            {Children.toArray(children).map((child) => {
                if (child.type?.displayName !== 'ScrollSection' && child.type?.displayName !== 'ScrollSectionFluid')
                    return child;

                const tabWithRef = cloneElement(child, {
                    ref: (node) => elementsRef.current.push(node),
                    fixed: fixTabs,
                });
                return tabWithRef;
            })}
        </div>
    );
}

ScrollSections.propTypes = {
    children: PropTypes.node.isRequired,
};

const ScrollSection = forwardRef(({
    children, first, last, fixed, className,
}, ref) => (
    <>
        <div className={`old-w-100 ${fixed && last ? 'old-min-vh-100' : ''} ${fixed && !last ? 'old-min-vh-150' : ''} ${!first ? 'old-mt-n6' : ''} old-sb-6 ${className}`}>
            <Container
                className="old-min-vh-100 old-pt-6 old-pt-md-section-margin old-pb-section-margin old-d-flex old-flex-column old-justify-content-center old-overflow-hidden"
                ref={ref}
            >
                {children}
            </Container>
        </div>
        <div className={last && fixed ? 'old-min-vh-50' : 'old-d-none'} />
    </>
));

ScrollSection.propTypes = {
    children: PropTypes.node.isRequired,
    first: PropTypes.bool,
    last: PropTypes.bool,
    fixed: PropTypes.bool,
    className: PropTypes.string,
};

ScrollSection.defaultProps = {
    first: false,
    last: false,
    fixed: false,
    className: '',
};

const ScrollSectionFluid = forwardRef(({
    children, className,
}, ref) => (
    <div className={`old-w-100 ${className}`}>
        <div ref={ref}>
            {children}
        </div>
    </div>
));

ScrollSectionFluid.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

ScrollSectionFluid.defaultProps = {
    className: '',
};

ScrollSection.displayName = 'ScrollSection';
ScrollSections.ScrollSection = ScrollSection;

ScrollSectionFluid.displayName = 'ScrollSectionFluid';
ScrollSections.ScrollSectionFluid = ScrollSectionFluid;

export default ScrollSections;
