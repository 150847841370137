import { useState, useContext } from 'react';
import { UserContext } from 'features/user';
import { useBriefs } from 'features/brief';
import { Container, Swiper } from 'ui';
import SmallBriefCard from 'features/brief/components/card/SmallBriefCard';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function BriefSwiper() {
    const { user } = useContext(UserContext);
    if (!user.doesSessionExist)
        return null;
    const [toggleHide, setToggleHide] = useState(false);
    const inProgressBriefs = useBriefs({
        user: user.username,
        status: ['proposing', 'selecting', 'submitting', 'voting', 'judging', 'completed'],
    });

    return inProgressBriefs.all.length > 0 && (
        <Container id="your-briefs" role="button" tabIndex={0} onClick={() => setToggleHide(!toggleHide)}>
            <div className="select-none bg-content4 text-primary-foreground old-py-3 old-rounded-4">
                <div className="mx-6 old-d-flex old-align-items-top old-mt-1 old-justify-content-between">
                    <span className="old-mx-2 mt-1 uppercase old-fs-3 old-text-offwhite">
                        {`Your Briefs (${inProgressBriefs.total})`}
                    </span>
                    <KeyboardArrowDownIcon className={`mb-2 !size-12 ${!toggleHide ? 'old-rotate-180' : ''}`} />
                </div>
                {!toggleHide && (
                    <Swiper>
                        {inProgressBriefs.all.sort((a, b) => a.submitted - b.submitted).map((brief) => (
                            <SmallBriefCard entry={brief} trackingLocation="your briefs" />
                        ))}
                    </Swiper>
                )}
            </div>
        </Container>
    );
}
