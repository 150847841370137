import { Outlet } from 'react-router-dom';
import { browserRoutes as sharedBrowserRoutes } from 'config';
import { generatePath, redirectDocument } from 'utils/helpers';
import { ProfileIcon } from 'features/profile';
import { SubmissionModal } from 'features/submission';
import { ProtectedRoute } from 'features/authentication';
import prospectIcon from 'images/icons/prospect100icon.png';
import prospectIconInverted from 'images/icons/prospect100icon-inverted.svg';
import WorkIcon from '@mui/icons-material/Work';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import StarIcon from '@mui/icons-material/Star';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import SearchIcon from '@mui/icons-material/Search';
import Home from 'default/pages/Home';
import Briefs from 'default/pages/Briefs';
import Brief from 'default/pages/Brief';
import EnterBrief from 'default/pages/EnterBrief';
import Voting from 'default/pages/Voting';
import About from 'default/pages/About';
import Organisation from 'default/pages/Organisation';
import UserProfile from 'default/pages/UserProfile';
import UserEdit from 'default/pages/UserEdit';
import Spotlight from 'default/pages/Spotlight';
import FeedbackHub from 'default/pages/FeedbackHub';
import EnterBriefComplete from 'default/pages/EnterBriefComplete';
import routes from 'default/routes';

export const redirects = {
    '/amfar': { to: routes.organisation, params: { id: 4 } },
    '/zellerfeld': { to: routes.organisation, params: { id: 9 } },
    '/lp': { to: routes.organisation, params: { id: 12 } },
    '/ketnipz': { to: routes.organisation, params: { id: 3 } },
    '/illenium': { to: routes.brief, params: { id: 37 } },
    '/duranduran': { to: routes.brief, params: { id: 42 } },
};

export const browserRoutes = {
    ...sharedBrowserRoutes,
    home: {
        path: routes.home,
        element: <Home />,
        index: true,
    },
    get dashboardRedirect() {
        return {
            path: routes.dashboard,
            loader: ({ params }) => redirectDocument(routes.home, params),
        };
    },
    briefs: {
        path: routes.briefs,
        element: <Briefs />,
    },
    brief: {
        path: routes.brief,
        element: (
            <>
                <Brief />
                <Outlet />
            </>
        ),
        children: {
            submission: {
                path: routes.briefSubmission,
                element: <SubmissionModal />,
            },
        },
    },
    voting: {
        path: routes.voting,
        element: (
            <>
                <Voting />
                <Outlet />
            </>
        ),
        children: {
            submission: {
                path: routes.votingSubmission,
                element: <SubmissionModal />,
            },
        },
    },
    spotlight: {
        path: routes.spotlight,
        element: (
            <>
                <Spotlight />
                <Outlet />
            </>
        ),
        children: {
            submission: {
                path: routes.spotlightSubmission,
                element: <SubmissionModal showVoteButton={false} />,
            },
        },
    },
    about: {
        path: routes.about,
        element: <About />,
    },
    organisation: {
        path: routes.organisation,
        element: <Organisation />,
    },
    userProfile: {
        path: routes.userProfile,
        element: (
            <>
                <UserProfile />
                <Outlet />
            </>
        ),
        children: {
            submission: {
                path: routes.userProfileSubmission,
                element: <SubmissionModal />,
            },
        },
    },
    userProfileEdit: {
        path: routes.userProfileEdit,
        element: <ProtectedRoute component={UserEdit} />,
    },
    enterBrief: {
        path: routes.enterBrief,
        element: <ProtectedRoute component={EnterBrief} />,
    },
    enterBriefComplete: {
        path: routes.enterBriefComplete,
        element: <ProtectedRoute component={EnterBriefComplete} />,
    },
    feedbackHub: {
        path: routes.feedbackHub,
        element: (
            <>
                <ProtectedRoute component={FeedbackHub} />
                <Outlet />
            </>
        ),
        children: {
            submission: {
                path: routes.feedbackHubSubmission,
                element: <SubmissionModal />,
            },
        },
    },
    redirectOrg: {
        path: routes.redirectOrg,
        loader: ({ params }) => redirectDocument(process.env.REACT_APP_ORG_URL, params),
    },
    get briefSubmissionRedirect() {
        return {
            path: '/voting/*',
            loader: ({ params }) => redirectDocument('/briefs', params),
        };
    },
    // We need this for existing submission routes (such as those shared through links) that will still work
    get submission() {
        const id = window.location.pathname.split('/').slice(-1)[0];
        return {
            path: '/submission/:id',
            loader: ({ params }) => redirectDocument(generatePath(routes.votingSubmission, { submissionId: id }), params),
        };
    },
    // Add our redirects (e.g. /zellerfeld and /amfar)
    ...Object.fromEntries(Object.entries(redirects).map(([key, { to, params }]) => [
        key, {
            path: key,
            loader: ({ params: routeParams }) => redirectDocument(generatePath(to, params), routeParams),
        },
    ])),
};

const navbarRoutes = {
    briefs: ({ navigate }) => ({
        name: 'Briefs',
        matchPath: routes.briefs,
        onClick: () => navigate(routes.briefs),
        icon: WorkOutlineOutlinedIcon,
        selectedIcon: WorkIcon,
    }),
    voting: ({ navigate }) => ({
        name: 'Voting',
        matchPath: routes.voting,
        onClick: () => navigate(routes.voting),
        icon: FavoriteBorderOutlinedIcon,
        selectedIcon: FavoriteIcon,
    }),
    search: ({ handleToggleOffcanvas }) => ({
        name: 'Search',
        onClick: () => handleToggleOffcanvas({ page: 'search', isProtectedAction: false }),
        icon: SearchIcon,
        selectedIcon: SearchIcon,
    }),
    spotlight: ({ navigate }) => ({
        name: 'Spotlight',
        matchPath: routes.spotlight,
        onClick: () => navigate(routes.spotlight),
        icon: StarBorderOutlinedIcon,
        selectedIcon: StarIcon,
    }),
    activity: ({ handleToggleOffcanvas }) => ({
        name: 'Activity',
        onClick: () => handleToggleOffcanvas({ page: 'activity', isProtectedAction: true }),
        icon: NotificationsNoneOutlinedIcon,
        selectedIcon: NotificationsIcon,
    }),
    account: ({ user, handleToggleOffcanvas }) => ({
        name: 'Account',
        ...(user.username ? { matchPath: generatePath(routes.userProfile, { username: user.username }) } : {}),
        onClick: () => handleToggleOffcanvas({ page: 'account', isProtectedAction: true }),
        icon: (props) => (
            <ProfileIcon
                icon={user?.icon}
                color="transparent"
                selected={false}
                defaultLoaded
                data-testid={user?.doesSessionExist ? 'account-button' : 'show-auth-button'}
                {...props}
            />
        ),
        selectedIcon: (props) => (
            <ProfileIcon
                icon={user?.icon}
                color="transparent"
                defaultLoaded
                data-testid={user?.doesSessionExist ? 'account-button' : 'show-auth-button'}
                {...props}
            />
        ),
    }),
    feedback: ({ navigate }) => ({
        name: 'Feedback',
        matchPath: routes.feedbackHub,
        onClick: () => navigate(routes.feedbackHub),
        icon: ChatOutlinedIcon,
        selectedIcon: ChatIcon,
    }),
    settings: ({ navigate }) => ({
        name: 'Settings',
        onClick: () => navigate(`${routes.userProfileEdit}#marketing`),
        icon: SettingsOutlinedIcon,
        selectedIcon: SettingsIcon,
    }),
    orgDashboard: {
        name: 'Org Dashboard',
        onClick: () => window.open(generatePath(routes.redirectOrg, { '*': `?${new URLSearchParams({ redirectFromPath: window.location.pathname }).toString()}` }), '_blank'),
        icon: SpaceDashboardOutlinedIcon,
        selectedIcon: SpaceDashboardIcon,
    },
    reportProblem: ({ showError }) => ({
        name: 'Report Problem',
        onClick: () => showError(),
        icon: ErrorOutlineOutlinedIcon,
        selectedIcon: ErrorIcon,
    }),
};

const config = {
    redirectDefault: '/*',
    redirectOrg: routes.redirectOrg,
    redirectHome: '/',
    navbar: {
        collapse: 'md',
        allowSearch: true,
        icon: {
            light: prospectIcon,
            dark: prospectIconInverted,
        },
        routes: ({ user, ...props }) => ({
            mobileRoutes: [
                navbarRoutes.briefs(props),
                navbarRoutes.voting(props),
                navbarRoutes.spotlight(props),
                navbarRoutes.account({ user, ...props }),
            ],
            desktopRoutes: [
                navbarRoutes.briefs(props),
                navbarRoutes.voting(props),
                navbarRoutes.spotlight(props),
            ],
            desktopIconRoutes: [
                navbarRoutes.search(props),
                navbarRoutes.account({ user, ...props }),
            ],
            offcanvasRoutes: [
                navbarRoutes.search(props),
                navbarRoutes.feedback(props),
                navbarRoutes.settings(props),
                ...(!user.organisation || !user.organisation.id ? [] : [
                    navbarRoutes.orgDashboard,
                ]),
                navbarRoutes.reportProblem(props),
            ],
        }),
    },
};

export default config;
