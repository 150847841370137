import { useContext } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from 'api';
import { ErrorToast, ErrorContext } from 'features/report';
import { submissionQueries } from 'features/submission';
import api from 'adapters/api';

const useSubmissionDelete = () => {
    const queryClient = useQueryClient();
    const { showError } = useContext(ErrorContext);

    const handleDeleteSubmission = async (sumissionId) => {
        const res = await api.delete({ url: `submissions/${sumissionId}` });

        if (!res.success) {
            toast(<ErrorToast
                errorMessage={res.errorDetails.message}
                showErrorModal={() => showError({
                    defaultReason: 'issue',
                    defaultPage: 'other',
                    apiError: res.errorDetails,
                })}
            />, {
                limit: 1,
            });
            return;
        }

        queryClient.invalidateQueries({ queryKey: submissionQueries.lists() });
    };

    return handleDeleteSubmission;
};

export default useSubmissionDelete;
