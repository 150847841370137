import {
    useOne, useMany, queryFactory, useMutation, client, useQueryClient,
} from 'api';

const briefQueries = queryFactory('briefs/');

const useBriefs = (params) => useMany({ queryKey: briefQueries.list(params) });
const useBrief = (id) => useOne({ queryKey: briefQueries.detail(id) });

const useEnterBrief = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id }) => client.put(`briefs/${id}/enter/`),
        onSuccess: ({ data: brief }) => {
            queryClient.setQueryData(briefQueries.detail(brief.id), brief);
            queryClient.invalidateQueries({ queryKey: briefQueries.detail(brief.id) });
        },
    });
};

export {
    useBrief, useBriefs, useEnterBrief, briefQueries,
};
