import { useContext } from 'react';
import { UserContext } from 'features/user';
import { Button } from 'ui';
import PropTypes from 'prop-types';

export default function OnboardingComplete({ onComplete }) {
    const { user, setUser } = useContext(UserContext);

    const handleCompleteOnboarding = () => {
        user.metadata.completed_onboarding = true;
        setUser(user);
        onComplete();
    };

    return (
        <div data-testid="user-onboarding-complete">
            <h2 className="capitalize old-fs-1 text-center">Welcome to Oditi</h2>
            <h1 className="text-center">🏆</h1>

            <p className="text-center old-mb-5">Start exploring new briefs, vote for your favourite submissions, and climb the Oditi leaderboard!</p>

            <Button
                onClick={handleCompleteOnboarding}
                color="primary"
                trackingName="completed onboarding"
                fullWidth
            >
                Finish
            </Button>
        </div>
    );
}

OnboardingComplete.propTypes = {
    onComplete: PropTypes.func.isRequired,
};
