/*
    --------------------------------------
    Add custom form schema validation here
    --------------------------------------
*/

import dayjs from 'dayjs';
import { getFileProps, formatBytes } from 'utils/helpers';

const allowedImages = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'];

async function asyncFilter(arr, callback) {
    const fail = Symbol('filter');
    return (await Promise.all(arr.map(async (item) => ((await callback(item)) ? item : fail)))).filter((i) => i !== fail);
}

function validateFileDimensions(width, height, errMsg = '') {
    return this.test('fileDimensions', errMsg, async (file, ctx) => {
        const { path, createError } = ctx;
        const files = Array.isArray(file) ? file : [file];

        const inBounds = await asyncFilter(files, async (f) => {
            try {
                const fileProps = await getFileProps(f);
                return fileProps.width > width || fileProps.height > height;
            } catch (err) {
                return false;
            }
        });

        return !inBounds.length || createError({
            path,
            message: (errMsg) || `${path} must be no greater than ${width} x ${height} in size`,
        });
    });
}

function validateFileSize(maxBytes, errMsg = '') {
    return this.test('fileSize', errMsg, (file, ctx) => {
        const { path, createError } = ctx;
        const files = Array.isArray(file) ? file : [file];

        return !files.filter((f) => f.size > maxBytes).length || createError({
            path,
            message: (errMsg) || `${path} cannot be a file size larger than ${formatBytes(maxBytes)}`,
        });
    });
}

function validateFileRequired(errMsg = '') {
    return this.test('requiredFile', errMsg, (file, ctx) => {
        const { path, createError } = ctx;

        return file.size > 0 || file.length > 1 || createError({
            path,
            message: (errMsg) || `${path} is a required field`,
        });
    });
}

function validateFileTypes(fileTypes = allowedImages, errMsg = '') {
    return this.test('fileTypes', errMsg, (file, ctx) => {
        const { path, createError } = ctx;
        let files = Array.isArray(file) ? file : [file];
        files = files.filter((f) => f.size > 0);

        return !files.filter((f) => !fileTypes.includes(f.type)).length || createError({
            path,
            message: (errMsg) || `${path} must be one of ${fileTypes.join(',')}`,
        });
    });
}

function validateDateTime(format = 'YYYY-MM-DD HH:mm', errMsg = '') {
    return this.test('dateTime', errMsg, (dateTime, ctx) => {
        const { path, createError } = ctx;

        return dayjs(dateTime, format, true).isValid() || createError({
            path,
            message: (errMsg) || `${path} is not a valid format`,
        });
    });
}

function validateDateTimeAfter(prevDateTime, errMsg = '') {
    return this.test('dateTimeAfter', errMsg, (dateTime, ctx) => {
        const { path, createError, parent } = ctx;

        return dayjs(dateTime).isAfter(dayjs(parent[prevDateTime])) || createError({
            path,
            message: (errMsg) || `${path} must be after ${prevDateTime},`,
        });
    });
}

export {
    validateFileDimensions,
    validateFileSize,
    validateFileRequired,
    validateFileTypes,
    validateDateTime,
    validateDateTimeAfter,
};
