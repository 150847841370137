import PropTypes from 'prop-types';

const organisationPropTypes = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    description: PropTypes.string.isRequired,
    follower_count: PropTypes.number.isRequired,
    metadata: PropTypes.shape({
        socials: PropTypes.objectOf(PropTypes.string),
    }),
});

const organisationListPropTypes = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    description: PropTypes.string.isRequired,
});

export default organisationPropTypes;
export {
    organisationListPropTypes,
};
