import { useState } from 'react';
import { tv } from 'tailwind-variants';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PropTypes from 'prop-types';
import ReactCountryFlag from 'react-country-flag';

const profileIcon = tv({
    base: 'old-rounded-circle aspect-square',
    variants: {
        icon: {
            gray: 'old-bg-gray old-text-white old-p-2',
            border: 'old-bg-gray old-text-white old-border old-border-2 old-border-white old-p-2',
            transparent: 'old-bg-transparent old-text-reset',
        },
        image: {
            gray: '',
            transparent: '',
            border: 'old-border old-border-2 old-border-white',
        },
    },
});

export default function ProfileIcon({
    icon = '',
    country = '',
    color = 'gray',
    className = '', // Applied to both
    iconClassName = '', // Applied to PersonIcon
    selected = true,
    alt = 'User Icon',
    defaultLoaded = false,
    ...props
}) {
    const [loaded, setLoaded] = useState(defaultLoaded);
    const Icon = selected ? PersonIcon : PersonOutlineOutlinedIcon;

    return (
        <div className="old-position-relative old-d-inline-block">
            {(country) && (
                <ReactCountryFlag
                    className="old-emojiFlag old-position-absolute old-top-0 old-end-0 old-me-n2 old-mt-n2 old-bg-white old-text-black old-rounded-circle old-d-flex old-align-items-center old-justify-content-center font-ios !size-8 old-fs-18"
                    countryCode={country}
                />
            )}

            {(icon) && (
                <img
                    src={icon}
                    className={`${profileIcon({ image: color })} ${className} ${!loaded ? 'old-d-none' : ''}`}
                    alt={alt}
                    onLoad={() => setLoaded(true)}
                    {...props}
                />
            )}

            {(!icon || !loaded) && (
                <Icon className={`${profileIcon({ icon: color })} ${iconClassName} ${className}`} {...props} />
            )}
        </div>
    );
}

ProfileIcon.propTypes = {
    icon: PropTypes.string,
    country: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    selected: PropTypes.bool,
    alt: PropTypes.string,
    defaultLoaded: PropTypes.bool,
};
