import {
    useState, forwardRef, useEffect,
} from 'react';
import { ImageCropModal, ImageCard } from 'ui';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';

function RemovePreviewOverlay({ onDelete }) {
    return (
        <div className="old-w-100">
            <div className="old-position-absolute old-top-2 old-start-2" data-testid="remove-preview">
                <CloseIcon
                    className="old-bg-white old-rounded-circle old-text-black old-p-1 !size-8 old-shadow"
                    role="button"
                    onClick={onDelete}
                />
            </div>
        </div>
    );
}
RemovePreviewOverlay.propTypes = {
    onDelete: PropTypes.func.isRequired,
};

function PreviewImage({ acceptedFileTypes, className, isDragReject }) {
    return (
        <div
            className={`border border-dashed border-2 border-foreground old-p-5 old-w-100 old-d-flex old-flex-column old-align-items-center old-justify-content-center old-gap-2 aspect-4/3 transition-colors duration-300 ease-in-out ${className} ${isDragReject ? 'old-bg-danger old-text-white' : ''}`}
            data-role="form-error-component"
            role="button"
            tabIndex={0}
        >
            <div className="old-d-inline-flex old-align-items-center old-mx-auto">
                <AddIcon className="old-me-1" />
                <span className="uppercase">Add New Image</span>
            </div>
            <p className="text-primary-300 old-d-block old-mb-0 old-mw-brief-toggle">{acceptedFileTypes()}</p>
        </div>
    );
}
PreviewImage.propTypes = {
    acceptedFileTypes: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
    isDragReject: PropTypes.bool.isRequired,
};

const FormFilePreviewImage = forwardRef(({
    className, defaultValue, ...props
}, ref) => {
    const [previewSrc, setPreviewSrc] = useState(defaultValue);
    const [didDelete, setDidDelete] = useState(0);

    useEffect(() => {
        if (ref)
            ref.current = previewSrc;
    }, [previewSrc]);

    const handleDelete = () => {
        setPreviewSrc(null);
        // When we delete an image we want to reset the FormFile so that the filelist is empty again
        // this prevents any errors from previous images still being shown
        setDidDelete((prev) => prev + 1);
    };

    return (
        <div className="w-full sm:w-80 mb-8">
            <ImageCropModal
                key={didDelete}
                onComplete={setPreviewSrc}
                wrapperClassName="old-mb-0 old-d-inline-block old-w-100"
                className={`${className} ${previewSrc ? 'old-d-none' : ''}`}
                fileAs={PreviewImage}
                {...props}
            />

            {(previewSrc) && (
                <ImageCard
                    image={previewSrc}
                    overlay={RemovePreviewOverlay}
                    overlayProps={{
                        onDelete: handleDelete,
                    }}
                />
            )}
        </div>
    );
});
FormFilePreviewImage.propTypes = {
    className: PropTypes.string,
    defaultValue: PropTypes.string,
};
FormFilePreviewImage.defaultProps = {
    className: 'bg-content2 hover:bg-default-focus rounded-3xl',
    defaultValue: null,
};

export default FormFilePreviewImage;
