import {
    createContext,
} from 'react';

const AuthContext = createContext({
    logout: () => {},
    showAuth: () => {},
    hideAuth: () => {},
});

export default AuthContext;
