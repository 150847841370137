import { useState, cloneElement } from 'react';
import { useProtectedAction } from 'features/authentication';
import PropTypes from 'prop-types';

export default function ModalClickContainer({
    modal,
    protectedAction = false,
    children,
    ...modalProps
}) {
    const [show, setShow] = useState(false);
    const [handleOpenProtected] = useProtectedAction(() => setShow(true));
    const Modal = modal;

    const childWithClick = cloneElement(children, {
        role: 'button',
        tabIndex: 0,
        onClick: protectedAction ? handleOpenProtected : () => setShow(true),
    });

    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                {...modalProps}
            />

            {childWithClick}
        </>
    );
}

ModalClickContainer.propTypes = {
    modal: PropTypes.elementType.isRequired,
    protectedAction: PropTypes.bool,
    children: PropTypes.node.isRequired,
};
