import { Button } from 'ui';
import PropTypes from 'prop-types';
import RefreshIcon from '@mui/icons-material/Refresh';
import ReportIcon from '@mui/icons-material/Report';

export default function ErrorToast({ showErrorModal }) {
    return (
        <div className="flex flex-col text-center gap-4" data-testid="error-toast">
            <p className="font-bold mb-0">Something went wrong :(</p>

            <small className="old-d-block">Try refreshing the page and making the request again, or if the issue persists please send an error report.</small>

            <Button
                color="primary"
                size="sm"
                startContent={<RefreshIcon />}
                onClick={() => window.location.reload()}
                data-testid="error-toast-refresh"
            >
                Refresh Page
            </Button>

            <Button
                variant="faded"
                startContent={<ReportIcon />}
                size="sm"
                onClick={showErrorModal}
                data-testid="error-toast-report"
            >
                Report an Issue
            </Button>
        </div>
    );
}

ErrorToast.propTypes = {
    showErrorModal: PropTypes.func.isRequired,
};
