function SvgLinkFill0Wght500Grad0Opsz48(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 33 33"
            width={32.807}
            height={32.807}
            fill="currentColor"
            {...props}
        >
            <path
                d="m21.047 22.802-4.484 4.485a7.525 7.525 0 0 1-5.52 2.295 7.521 7.521 0 0 1-5.518-2.293 7.546 7.546 0 0 1-2.298-5.523 7.513 7.513 0 0 1 2.29-5.524l4.485-4.485 1.832 1.832-4.481 4.482a5.05 5.05 0 0 0-1.548 3.694 5.025 5.025 0 0 0 1.541 3.692 5.054 5.054 0 0 0 3.696 1.545 5.035 5.035 0 0 0 3.7-1.542l4.481-4.481Zm-8.064-1.289-1.685-1.684 8.524-8.524 1.685 1.684Zm9.813-.46-1.826-1.825 4.482-4.482a5.05 5.05 0 0 0 1.547-3.694 5.025 5.025 0 0 0-1.541-3.692 5.054 5.054 0 0 0-3.696-1.545 5.035 5.035 0 0 0-3.7 1.542l-4.481 4.482-1.832-1.833 4.484-4.484a7.531 7.531 0 0 1 5.526-2.293 7.533 7.533 0 0 1 5.527 2.294 7.514 7.514 0 0 1 2.29 5.522 7.54 7.54 0 0 1-2.298 5.522Z"
            />
        </svg>
    );
}
export default SvgLinkFill0Wght500Grad0Opsz48;
