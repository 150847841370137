import { useState, Fragment } from 'react';
import { reportSchema } from 'utils/validation/validationSchemas';
import { Collapse, Form, Modal } from 'ui';
import ValidateForm from 'forms/wrappers/ValidationWrappers';
import api from 'adapters/api';
import FormControl from 'forms/FormControl';
import PropTypes from 'prop-types';
import SendIcon from '@mui/icons-material/Send';

const defaultReasons = {
    spam: 'Spam',
    adult: 'Adult Content',
    fraud: 'Fraud/Scam',
    illegal: 'Harmful or illegal',
    harmful: 'Imminent physical harm',
    rights: 'Infringes my rights',
    misinformation: 'Misinformation and Disinformation',
    plagiarism: 'Plagiarism',
    other: 'Other',
};

function Reasons({
    reasons, reason: currentReason, setReason, errors,
}) {
    const [openCollapse, setOpenCollapse] = useState(false);

    const handleSetReason = (e) => {
        setOpenCollapse(reasons[e.currentTarget.id] === defaultReasons.plagiarism);
        setReason(reasons[e.currentTarget.id]);
    };

    return (
        <>
            <small className="text-center font-roman old-w-100 old-d-block old-mb-3 old-text-black">Please review our Community Guidelines for additional information about what is and isn&#39;t permitted on Oditi</small>

            {Object.keys(reasons).map((reason) => (
                <Fragment key={reason}>
                    <Form.Check
                        type="radio"
                        id={reason}
                        className="old-mb-1"
                    >
                        <Form.Check.Input
                            type="radio"
                            name="reason"
                            onChange={handleSetReason}
                            checked={currentReason === reasons[reason]}
                        />
                        <Form.Check.Label className="old-ps-2 old-fs-14 old-text-black">{reasons[reason]}</Form.Check.Label>
                    </Form.Check>

                    {(reasons[reason] === defaultReasons.plagiarism) && (
                        <Collapse in={openCollapse}>
                            <div>
                                <FormControl
                                    placeholder="Please provide a link to the original artwork"
                                    wrapperClassName="old-mb-2"
                                    className="old-bg-offwhite old-border-offwhite old-p-3 old-rounded-4 old-fs-14 old-placeholder-gray"
                                    name="url"
                                    error={errors.url}
                                />
                            </div>
                        </Collapse>
                    )}
                </Fragment>
            ))}
        </>
    );
}

Reasons.propTypes = {
    reasons: PropTypes.objectOf(PropTypes.string).isRequired,
    reason: PropTypes.string.isRequired,
    setReason: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default function ReportModal({
    onHide,
    title,
    url,
    show = false,
    reasons = defaultReasons,
}) {
    const [reason, setReason] = useState('');
    const [reportSent, setReportSent] = useState(false);

    const handleSendReport = async (formData) => {
        delete formData.show_url;

        // Set the reason to the selected checkbox
        formData.reason = reason;

        const res = await api.post({ url, data: formData });
        if (res.success) {
            setReportSent(true);
            return {};
        }

        return {
            errors: {
                ...res.parseError(formData),
                api: res.errorDetails,
            },
        };
    };

    const toggleValidateUrl = (formData) => {
        formData.show_url = reason === reasons.plagiarism;
        return formData;
    };

    return (
        <Modal
            isOpen={show}
            onOpenChange={onHide}
        >
            <Modal.ModalHeader>{title}</Modal.ModalHeader>

            <ValidateForm
                onSubmit={handleSendReport}
                validationSchema={reportSchema}
                parseData={toggleValidateUrl}
                scrollOnSubmit={false}
                errorPage="other"
            >
                {({
                    handleSubmit,
                    errors,
                    loading,
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Modal.ModalBody className="font-roman gap-0">
                            {(!reportSent) ? (
                                <>
                                    <Reasons
                                        reasons={reasons}
                                        reason={reason}
                                        setReason={setReason}
                                        errors={errors}
                                    />
                                    <FormControl
                                        label="Additional details (optional)"
                                        labelClassName="font-bold fs-14 text-uppercase text-black"
                                        wrapperClassName="old-mt-3 old-text-black"
                                        className="old-bg-offwhite old-border-offwhite old-p-3 old-rounded-4 old-d-inline-block old-placeholder-gray"
                                        placeholder="Please provide additional details"
                                        name="details"
                                        as="textarea"
                                        maxLength={200}
                                        rows={4}
                                        error={errors.details}
                                    />
                                </>
                            ) : (
                                <div className="text-center">
                                    <small>
                                        Thank you for your report.
                                        <br />
                                        We may be in touch to ask for further information.
                                    </small>
                                </div>
                            )}
                        </Modal.ModalBody>

                        <Modal.ModalActionsFooter
                            onCancel={onHide}
                            cancelText={reportSent ? 'Close' : 'Cancel'}
                            confirmText="Send"
                            confirmProps={{
                                isDisabled: reason === '' || loading || reportSent,
                                isLoading: loading,
                                endContent: <SendIcon className="!size-4" />,
                            }}
                        />
                    </Form>
                )}
            </ValidateForm>
        </Modal>
    );
}

ReportModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    show: PropTypes.bool,
    reasons: PropTypes.objectOf(PropTypes.string),
};
