import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { Tabs, Tab } from 'ui';
import { useBriefs } from 'features/brief';
import prospectImage from 'images/prospectImage.webp';

import BriefFilter from './BriefFilter';

const allBrief = {
    organisation: { name: 'All' },
    thumbnail: prospectImage,
    id: 0, // An id that won't exist in the backend
};
const sortOptions = [
    { id: 'highest_points', label: 'Most Loved', icon: StarIcon },
    { id: 'trending', label: 'New Ideas', icon: LightbulbIcon },
];

const DEFAULT_BRIEF = allBrief.id;
const DEFAULT_SORT = 'highest_points'; // must match API default

export default function Filters() {
    const [searchParams, setSearchParams] = useSearchParams();
    const { all } = useBriefs({ status: ['voting', 'judging'] });
    const briefs = useMemo(() => (all ? [allBrief, ...all] : []), [all]);

    return (
        <div className="flex flex-col-reverse md:flex-row justify-center position-relative gap-5 mb-4">
            <Tabs
                size="lg"
                variant="underlined"
                color="primary"
                items={sortOptions}
                onSelectionChange={(sortId) => {
                    setSearchParams((prev) => {
                        if (sortId === DEFAULT_SORT)
                            prev.delete('sort');
                        else
                            prev.set('sort', sortId);
                        return prev;
                    }, { replace: true });
                }}
                classNames={{
                    tab: 'h-auto',
                    tabList: 'gap-0',
                    base: 'h-full flex items-center justify-center',
                }}
            >
                {({ id, icon: Icon, label }) => (
                    <Tab
                        key={id}
                        title={(
                            <div className="flex flex-col items-center space-x-1 sm:space-y-1">
                                <Icon className="!size-5" />
                                <span className="old-text-nowrap old-pb-2 old-px-3 old-fs-6 capitalize">{label}</span>
                            </div>
                        )}
                    />
                )}
            </Tabs>

            {(briefs.length > 1 && !searchParams.get('search')) && (
                <BriefFilter
                    briefs={briefs}
                    handleSelectBrief={(briefId) => {
                        setSearchParams((prev) => {
                            if (Number(briefId) === DEFAULT_BRIEF)
                                prev.delete('brief');
                            else
                                prev.set('brief', briefId);
                            return prev;
                        }, { replace: true });
                    }}
                    defaultSelectedId={Number(DEFAULT_BRIEF)}
                />
            )}
        </div>
    );
}
