import {
    useOne, useMany, queryFactory, useQueryClient, useMutation, client,
} from 'api';
import { briefQueries } from 'features/brief';

const submissionQueries = queryFactory('submissions/');

const useSubmissions = (params) => useMany({ queryKey: submissionQueries.list(params) });
const useSubmission = (id) => useOne({ queryKey: submissionQueries.detail(id) });
const useSubmissionFeedback = (id) => useOne({ queryKey: submissionQueries.detail(id, 'feedback') });

const useSubmissionMutations = () => {
    const queryClient = useQueryClient();
    return {
        createSubmission: useMutation({
            mutationFn: ({ briefId, data }) => client.post(`briefs/${briefId}/submissions`, data, { headers: { 'Content-Type': 'multipart/form-data' } }),
            onSuccess: ({ data: submission }) => {
                queryClient.setQueryData(submissionQueries.detail(submission.id), submission);
                queryClient.invalidateQueries({ queryKey: submissionQueries.lists() });
                queryClient.invalidateQueries({ queryKey: briefQueries.detail(submission.brief.id) });
            },
        }),
        updateSubmission: useMutation({
            mutationFn: ({ id, data }) => client.patch(`submissions/${id}`, data, { headers: { 'Content-Type': 'multipart/form-data' } }),
            onSuccess: ({ data: submission }) => {
                queryClient.setQueryData(submissionQueries.detail(submission.id), submission);
                queryClient.invalidateQueries({ queryKey: submissionQueries.lists() });
                queryClient.invalidateQueries({ queryKey: briefQueries.detail(submission.brief.id) });
            },
        }),
    };
};

export {
    useSubmission, useSubmissions, useSubmissionFeedback, useSubmissionMutations, submissionQueries,
};
