import { createAnimation } from 'ui/animations';
import PropTypes from 'prop-types';

export default function ScrollPopup({
    className = 'old-d-lg-none bg-content1 shadow-2xl',
    show = false,
    children,
}) {
    const handleShowToggleButtons = (e) => {
        if (show)
            return;

        e.target.style.setProperty('visibility', 'visible');
    };

    const handleHideToggleButtons = (e) => {
        if (!show)
            return;

        e.target.style.setProperty('visibility', 'hidden');
    };

    return (
        <div
            className={`old-position-fixed bottom-16 old-bottom-md-0 old-w-100 old-rounded-top-4 old-p-3 old-d-flex old-align-items-center old-justify-content-between ${className} ${show ? createAnimation({
                name: 'fadeOutDown',
                duration: 0.3,
            }) : createAnimation({
                name: 'fadeInUp',
                duration: 0.3,
            })}`}
            onAnimationStart={handleShowToggleButtons}
            onAnimationEnd={handleHideToggleButtons}
            style={{
                visibility: 'hidden', // We hide using style instead of a class so there is no !important
            }}
        >
            {children}
        </div>
    );
}

ScrollPopup.propTypes = {
    className: PropTypes.string,
    show: PropTypes.bool,
    children: PropTypes.node.isRequired,
};
