function SvgIcons8Youtube(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 72 72"
            width={64}
            height={64}
            fill="currentColor"
            {...props}
        >
            <path
                d="M61.115 18.856C63.666 21.503 64 25.709 64 36s-.334 14.497-2.885 17.144C58.563 55.791 55.906 56 36 56s-22.563-.209-25.115-2.856C8.334 50.497 8 46.291 8 36s.334-14.497 2.885-17.144S16.094 16 36 16s22.563.209 25.115 2.856zm-29.651 25.62 13.603-8.044-13.603-7.918v15.962z"
            />
        </svg>
    );
}
export default SvgIcons8Youtube;
