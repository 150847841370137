import { useState, useRef } from 'react';
import ForumIcon from '@mui/icons-material/Forum';
import { submissionPropTypes } from 'features/submission';
import { userPropTypes } from 'features/user';
import { FeedbackCard, FeedbackInput } from 'features/feedback';

import feedbackPropTypes from '../feedbackSchema';

function EngagementCTA({ user }) {
    return (
        <div className="bg-default-100 rounded-lg p-6 mb-4 text-center w-full">
            <ForumIcon className="mx-auto mb-4 text-primary-200" />
            <h3 className="text-xl uppercase font-semibold mb-2 text-primary-200">
                {`What did you think of @${user.username}'s design?`}
            </h3>
            <p className="text-gray-600 mb-4">
                Your insights can inspire and elevate fellow designers. Join the conversation and make a difference!
            </p>
        </div>
    );
}
EngagementCTA.propTypes = {
    user: userPropTypes.isRequired,
};

export default function FeedbackContainer({ submission, feedback }) {
    const [parent, setParent] = useState(null);
    const inputRef = useRef(null);

    const handleReply = (comment) => {
        setParent(comment);
        if (inputRef.current)
            inputRef.current.focus();
    };

    return (
        <div className="bg-white rounded-3xl flex flex-col max-h-[90vh] min-h-[300px]">
            {feedback.comments?.length > 0 ? (
                <>
                    <div className="flex-1 overflow-y-auto px-6 pt-6">
                        {feedback.comments?.map((comment) => (
                            <div key={comment.id} className="mb-4">
                                <FeedbackCard
                                    ref={inputRef}
                                    comment={comment}
                                    onReply={handleReply}
                                />
                            </div>
                        ))}
                        <EngagementCTA user={submission.user} />
                    </div>
                    <div className="px-6 pb-4 border-t-6 border-gray-200 relative">
                        <div className="absolute inset-x-0 top-[-40px] h-10 bg-gradient-to-t from-white via-white to-transparent pointer-events-none w-full" />
                        <FeedbackInput
                            ref={inputRef}
                            submission={submission}
                            parent={parent}
                            onCancelReply={() => setParent(null)}
                        />
                    </div>
                </>
            ) : (
                <div className="flex flex-col justify-center items-center h-full p-4">
                    <EngagementCTA user={submission.user} />
                    <div className="w-full">
                        <FeedbackInput
                            ref={inputRef}
                            submission={submission}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
FeedbackContainer.propTypes = {
    submission: submissionPropTypes.isRequired,
    feedback: feedbackPropTypes.isRequired,
};
