import {
    useState, useEffect, useRef, useContext,
} from 'react';
import { PulseLoader } from 'react-spinners';
import { Link, generatePath } from 'react-router-dom';
import { FeedbackCard, FeedbackContainer, feedbackPropTypes } from 'features/feedback';
import { UserContext, userPropTypes } from 'features/user';
import { ProfileIcon } from 'features/profile';
import { ReportModal } from 'features/report';
import {
    SubmissionVoteButton, SubmissionVotesModal, SubmissionActions, submissionPropTypes,
} from 'features/submission';
import {
    Section, ShareButton, Shimmer, ModalClickContainer, Button, Chip, Swiper,
} from 'ui';
import routes from 'default/routes';
import api from 'adapters/api';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

function isFollowing(a, b) {
    let sort = 0;
    if (a.following && !b.following)
        sort = -1;
    else if (!a.following && b.following)
        sort = 1;
    return sort;
}

function SubmissionVoters({ submission }) {
    const [voters, setVoters] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchVoters = async () => {
            setLoading(true);

            const res = await api.get({ url: `submissions/${submission.id}/voters/` });

            if (res.success)
                setVoters(res.data.map((vote) => vote.user));

            setLoading(false);
        };

        fetchVoters();
    }, [submission.id]);

    if (loading)
        return <PulseLoader />;

    return (
        <Swiper>
            {voters.sort(isFollowing).map((voter) => (
                <Link
                    to={generatePath(routes.userProfile, { username: voter.username })}
                    className="min-w-56 old-rounded-4 old-h-100 old-d-flex old-flex-column old-align-items-center old-justify-content-center old-p-6 old-bg-primary old-text-secondary"
                >
                    <ProfileIcon
                        icon={voter.icon}
                        country={voter.country_code}
                        className="!size-24 old-rounded-circle old-mb-3 old-border-5"
                        variant="border"
                        alt={`${voter.username} icon`}
                    />
                    <p className="uppercase old-fs-6 font-bold old-mb-0">{voter.username}</p>
                </Link>
            ))}
        </Swiper>
    );
}
SubmissionVoters.propTypes = {
    submission: submissionPropTypes.isRequired,
};

function SubmissionBodyLoading() {
    return (
        <div className="old-rounded-4 old-overflow-hidden">
            <Shimmer className="old-w-100 old-py-section-margin" />
        </div>
    );
}

function SubmissionFeedback({
    submission,
    feedback,
    user,
}) {
    if (Object.keys(feedback).length <= 0 && user.doesSessionExist)
        return <FeedbackCard.Loading />;

    return <FeedbackContainer submission={submission} feedback={feedback} />;
}
SubmissionFeedback.propTypes = {
    submission: submissionPropTypes.isRequired,
    feedback: feedbackPropTypes.isRequired,
    user: userPropTypes.isRequired,
};

export default function SubmissionBody({
    handleVote,
    submission,
    feedback,
    allowSelectWinner = false,
    showVoteButton = true,
}) {
    const { user } = useContext(UserContext);
    const feedbackRef = useRef(null);
    const votersRef = useRef(null);

    const scrollToFeedback = () => {
        if (!feedbackRef.current)
            return;
        feedbackRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollToVoters = () => {
        if (!votersRef.current)
            return;
        votersRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="old-d-flex old-flex-row-reverse">
            <SubmissionActions
                scrollToFeedback={scrollToFeedback}
                scrollToVoters={scrollToVoters}
                submission={submission}
                handleVote={handleVote}
                allowSelectWinner={allowSelectWinner}
                showVoteButton={showVoteButton}
            />

            <div className="w-full rounded-3xl sm:overflow-hidden">
                <div className="bg-content1 block md:hidden old-p-2 flex justify-between items-center sticky top-0 old-z-3 shadow-2xl">
                    <ShareButton
                        title={`${submission.title} - entry to ${submission.brief.title} - by ${submission.user.username}`}
                        wrapperClassName="old-all-me-3 inline-flex"
                        trackingLocation="submission body"
                        url={window.location.pathname}
                    />

                    {showVoteButton && (
                        <SubmissionVoteButton
                            hasVoted={submission.voted || allowSelectWinner}
                            numberVotes={parseInt(submission.votes, 10)}
                            handleVote={handleVote}
                            trackingLocation="submission body"
                            submissionId={submission.id}
                        />
                    )}
                </div>

                <div className="bg-content1 text-foreground flex flex-col">
                    {(submission.images.sort((a, b) => a.order_id - b.order_id).map((image) => image.image.compressed || image.image.original)).map((file) => (
                        <img
                            key={file.id}
                            src={file.url}
                            className="old-w-100"
                            data-testid="submission-file"
                            alt={file.name}
                        />
                    ))}

                    <Section className="text-center old-py-5 old-px-3 old-mb-0">
                        <h4>Description</h4>
                        <p className="old-description old-mb-0">{submission.description}</p>
                        <p className="old-text-lightgrey">
                            Published:
                            {' '}
                            {dayjs(submission.created_at).format('MMMM DD, YYYY')}
                        </p>
                    </Section>

                    {(showVoteButton) && (
                        <Section className="bg-content4 text-center old-rounded-top-4 text-primary-foreground pt-8 pb-14 px-4">
                            {(submission.votes > 0 && user.id === submission.user.id) ? (
                                <div className="old-py-1" data-testid="submission-voters" ref={votersRef}>
                                    <div className="flex mb-8 justify-center align-center gap-4">
                                        <div className="text-xl flex items-center space-x-1">
                                            <span>Your Voters</span>
                                            <Chip variant="faded" size="sm">{submission.votes}</Chip>
                                        </div>

                                        <ModalClickContainer
                                            modal={SubmissionVotesModal}
                                            submissionId={submission.id}
                                        >
                                            <Button
                                                color="success"
                                                size="sm"
                                            >
                                                View All
                                            </Button>
                                        </ModalClickContainer>
                                    </div>
                                    <SubmissionVoters submission={submission} />
                                </div>
                            ) : (
                                <SubmissionVoteButton
                                    hasVoted={submission.voted || allowSelectWinner}
                                    numberVotes={parseInt(submission.votes, 10)}
                                    handleVote={handleVote}
                                    trackingLocation="submission body"
                                    submissionId={submission.id}
                                />
                            )}
                        </Section>
                    )}

                    <div ref={feedbackRef}>
                        <Section className="old-bg-blank old-rounded-top-4 old-mb-0 old-mt-n4 old-px-3 old-py-4">
                            <SubmissionFeedback
                                submission={submission}
                                feedback={feedback}
                                user={user}
                            />
                        </Section>
                    </div>

                    <Section className="old-bg-blank old-mb-0 old-p-5 old-rounded-top-4 old-mt-n4">
                        <div className="old-d-flex old-justify-content-around old-w-100 old-mb-0 old-text-lightgrey font-roman">
                            <small>&copy; All Rights Reserved</small>

                            <ModalClickContainer
                                modal={ReportModal}
                                protectedAction
                                title="Report Submission"
                                url={`submissions/${submission.id}/report`}
                            >
                                <small className="old-transition-underline" role="button">Report</small>
                            </ModalClickContainer>
                        </div>
                    </Section>
                </div>
            </div>
        </div>
    );
}

SubmissionBody.propTypes = {
    handleVote: PropTypes.func.isRequired,
    allowSelectWinner: PropTypes.bool,
    showVoteButton: PropTypes.bool,
    submission: submissionPropTypes.isRequired,
    feedback: feedbackPropTypes.isRequired,
};

SubmissionBody.Loading = SubmissionBodyLoading;
