function SvgVotingChipFill0Wght500Grad0Opsz24(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={24}
            viewBox="0 -960 960 960"
            width={24}
            fill="currentColor"
            {...props}
        >
            <path
                d="M320-192.109q-120.298 0-204.094-83.807-83.798-83.807-83.798-204.12 0-120.312 83.798-204.084Q199.702-767.891 320-767.891h320q120.297 0 204.094 83.807t83.797 204.12q0 120.312-83.797 204.084Q760.297-192.109 640-192.109H320Zm0-91h320q81.71 0 139.301-57.59 57.59-57.591 57.59-139.301 0-81.71-57.59-139.301-57.591-57.59-139.301-57.59H320q-81.71 0-139.301 57.59-57.59 57.591-57.59 139.301 0 81.71 57.59 139.301 57.591 57.59 139.301 57.59Zm3.543-75.217h69.566v-86.891H480v-69.566h-86.891v-86.891h-69.566v86.891h-86.891v69.566h86.891v86.891Zm291.674 0h69.566v-243.348H555.217v69.565h60v173.783ZM480-480Z"
            />
        </svg>
    );
}
export default SvgVotingChipFill0Wght500Grad0Opsz24;
