import highlight1 from 'images/highlights/highlight1.webp';
import highlight2 from 'images/highlights/highlight2.webp';
import highlight3 from 'images/highlights/highlight3.webp';

const highlights = [
    {
        title: 'Chris Sardo, Oditi winner',
        description: 'Invited to Swatch HQ for his upcoming collaboration.',
        thumbnail: highlight1,
        url: 'https://www.instagram.com/p/Co5MyFPKPJp/',
    },
    {
        title: 'Lily Stock, Oditi winner',
        description: 'Releases collaboration with Teenage Mutant Ninja Turtles.',
        thumbnail: highlight2,
        url: 'https://www.instagram.com/p/CTMqJA9KlbO/',
    },
    {
        title: 'Ryan Roddy, Oditi winner',
        description: 'Releases new sneaker with Karl Lagerfeld.',
        thumbnail: highlight3,
        url: 'https://www.instagram.com/p/CnXVxDCq3j-/',
    },
];

export default highlights;
