import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ProfileIcon, socialIcons, socialPrefixes } from 'features/profile';
import { publicUserPropTypes, UserBadge, UserContext } from 'features/user';
import { ReportModal, ErrorToast, ErrorContext } from 'features/report';
import { useProtectedAction } from 'features/authentication';
import { organisationPropTypes } from 'features/organisation';
import {
    Container, Row, Col, OverlayTrigger, Dropdown, Tooltip, ShareButton, Shimmer, ModalClickContainer, Button,
} from 'ui';
import FollowersModal from 'features/profile/components/modal/FollowersModal';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import api from 'adapters/api';
import PropTypes from 'prop-types';
import FlagIcon from '@mui/icons-material/Flag';

function ProfileStat({ name = '', value = undefined, ...props }) {
    if (value === undefined || name === '')
        return null;

    return (
        <div
            className="border-l-2 border-foreground old-ps-2"
            data-testid={`profile-banner-${name.toLowerCase()}`}
            {...props}
        >
            <span className="font-bold old-fs-14 old-fs-sm-16">{name}</span>
            <h2 className="old-mb-0">{value}</h2>
        </div>
    );
}

ProfileStat.propTypes = {
    name: PropTypes.string,
    value: PropTypes.number,
};

function ProfileBannerLoading() {
    return (
        <Container className="old-py-6 old-sb-6">
            <Row>
                <Col xs={2} xl={1}>
                    <Shimmer className="old-ratio old-ratio-1x1 !size-20 old-rounded-circle" />
                </Col>

                <Col xs={12} xxl={11} className="old-d-flex old-justify-content-center old-flex-column old-mt-3 old-mt-xxl-0 old-mb-3">
                    <Shimmer className="old-w-100 old-p-4 old-rounded-pill old-h-25" />
                    <Shimmer className="old-w-50 old-p-2 old-mt-2 old-rounded-pill old-h-25" />
                </Col>
            </Row>

            <Row>
                <Col xs={0} xxl={1} />

                <Col xs={9} className="old-d-flex">
                    <Shimmer className="!size-8 old-p-1 old-rounded-circle old-me-3" />
                    <Shimmer className="!size-8 old-p-1 old-rounded-circle" />
                </Col>

                <Col xs={12} sm={3} xxl={2} className="old-mt-3 old-mt-sm-0 old-d-flex">
                    <Shimmer className="old-w-50 old-p-4 old-rounded-pill old-h-25 old-me-3" />
                    <Shimmer className="!size-12 old-p-1 old-rounded-circle" />
                </Col>
            </Row>
        </Container>
    );
}

function ProfileBanner({
    profile,
    urlPrefix,
    profileType,
    entries = undefined,
    briefs = undefined,
    edit = '',
}) {
    const [following, setFollowing] = useState(profile.following);
    const [followersCount, setFollowersCount] = useState(profile.follower_count);
    const [followingCount, setFollowingCount] = useState(profile.following_count);
    const { showError } = useContext(ErrorContext);
    const { user } = useContext(UserContext);

    const [handleFollow] = useProtectedAction(async (checked) => {
        let res;
        if (checked)
            res = await api.put({ url: `${urlPrefix}/${profile.id}/follow` });
        else
            res = await api.delete({ url: `${urlPrefix}/${profile.id}/follow` });

        if (!res.success) {
            toast(<ErrorToast
                errorMessage={res.errorDetails.message}
                showErrorModal={() => showError({
                    defaultReason: 'issue',
                    defaultPage: 'other',
                    apiError: res.errorDetails,
                })}
            />, {
                limit: 1,
            });
            return;
        }

        setFollowersCount(checked ? followersCount + 1 : followersCount - 1);
        setFollowing(checked);
    });

    return (
        <Container className="old-py-6 old-sb-6" data-testid="profile-banner">
            <Row>
                <Col xs={12} xxl={1}>
                    <ProfileIcon
                        icon={profile.icon}
                        country={profile?.country_code || ''}
                        className="!size-20 old-border-5 aspect-square"
                        iconClassName="old-p-3"
                        color="border"
                    />
                </Col>

                <Col className="old-all-mb-3 old-d-flex old-justify-content-between old-flex-wrap uppercase old-mt-3 old-mt-xxl-0" xs={12} xxl={11}>
                    <div className="old-mb-3">
                        <div className="old-d-flex old-align-items-center">
                            <h2 className="old-mb-0">{profile.username || profile.name}</h2>
                            <UserBadge className="!size-8 old-ms-2" profile={profile} />
                        </div>

                        <small className="font-bold old-text-lightgrey uppercase old-position-relative">{profileType}</small>
                    </div>

                    <div className="old-d-flex old-justify-content-between old-all-me-4 old-all-me-sm-6 old-mb-3">
                        <ProfileStat
                            name="Entries"
                            value={entries}
                        />

                        <ProfileStat
                            name="Briefs"
                            value={briefs}
                        />

                        <ModalClickContainer
                            modal={FollowersModal}
                            user={profile}
                            followersCount={followersCount}
                            followingCount={followingCount}
                            setFollowingCount={setFollowingCount}
                            urlPrefix={urlPrefix}
                        >
                            <ProfileStat
                                name="Followers"
                                value={followersCount}
                                role="button"
                            />
                        </ModalClickContainer>

                        <ModalClickContainer
                            modal={FollowersModal}
                            defaultTab="following"
                            user={profile}
                            followersCount={followersCount}
                            followingCount={followingCount}
                            setFollowingCount={setFollowingCount}
                            urlPrefix={urlPrefix}
                        >
                            <ProfileStat
                                name="Following"
                                value={followingCount}
                                role="button"
                            />
                        </ModalClickContainer>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs={0} xxl={1} />

                <Col xs={9}>
                    <p className="old-description">{profile.description}</p>
                </Col>
            </Row>

            <Row>
                <Col xs={0} xxl={1} />

                <Col xs={9} className="old-d-flex old-align-items-center">
                    {Object.keys(profile.metadata?.socials || []).map((social) => {
                        if (!(social in socialIcons))
                            return null;
                        const Icon = socialIcons[social];
                        let url = profile.metadata.socials[social];

                        if (!(/(http(s?)):\/\//i.test(url)))
                            url = `https://${url}`;

                        if (socialPrefixes[social].slice(-1) !== '@')
                            url = url.replace('@', '');

                        return (
                            <a
                                key={social}
                                href={url}
                                target="__blank"
                                className="old-bg-secondary old-d-inline-block old-rounded-circle old-me-3"
                            >
                                <Icon className="old-fill-primary !size-8 old-p-1" />
                            </a>
                        );
                    })}
                </Col>

                <Col xs={12} sm={3} xxl={2} className="old-d-flex old-align-items-end old-justify-content-start old-justify-content-sm-end old-text-nowrap old-mt-3 old-mt-sm-0">
                    <div className="old-d-flex old-align-items-center old-all-me-2 old-all-me-sm-3">
                        {(edit !== '') ? (
                            <Button
                                color="primary"
                                trackingName="view edit profile"
                                trackingLocation="profile"
                                as={Link}
                                to={edit}
                            >
                                Edit Profile
                            </Button>
                        ) : (
                            <Button
                                color={following ? 'primary' : 'success'}
                                onClick={() => handleFollow(!following)}
                                trackingName={following ? 'unfollow' : 'follow'}
                                trackingLocation="profile"
                            >
                                {following ? 'Unfollow' : 'Follow'}
                            </Button>
                        )}

                        <OverlayTrigger
                            placement="bottom"
                            trigger="click"
                            rootClose
                            overlay={(
                                <Tooltip id="share-tooltip" className="old-rounded-4 old-overflow-hidden old-shadow-sm old-mt-2">
                                    <div className="old-bg-white old-rounded-pill old-shadow old-p-2">
                                        <ShareButton
                                            wrapperClassName="old-py-1 old-d-flex old-flex-column old-all-mb-2 old-all-me-3"
                                            className="!size-8 old-bg-black old-text-white old-rounded-circle old-me-n3 old-p-1"
                                        />
                                    </div>
                                </Tooltip>
                              )}
                        >
                            <ShareOutlinedIcon role="button" className="old-rounded-circle old-p-2 !size-8 sm:!size-10 old-bg-secondary old-text-primary" />
                        </OverlayTrigger>

                        {(user.id !== profile.id) && (
                            <Dropdown
                                autoClose="outside"
                            >
                                <Dropdown.Toggle className="old-bg-transparent old-border-0 old-dropdown-toggle-blank">
                                    <MoreHorizIcon className="!size-8" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="uppercase text-center old-border-0 old-shadow-sm old-rounded-4 old-p-2">
                                    <ModalClickContainer
                                        modal={ReportModal}
                                        protectedAction
                                        title="Report User"
                                        url={`users/${profile.id}/report`}
                                        reasons={{
                                            advertising: 'Advertising',
                                            adult: 'Adult Content',
                                            fraud: 'Fraud/Scam',
                                            illegal: 'Harmful or illegal',
                                            harmful: 'Imminent physical harm',
                                            rights: 'Infringes my rights',
                                            misinformation: 'Misinformation and Disinformation',
                                            other: 'Other',
                                        }}
                                    >
                                        <Dropdown.Item className="old-rounded-4 old-d-flex old-align-items-center old-justify-content-center">
                                            <FlagIcon className="!size-5 old-me-1" />
                                            <span>Report</span>
                                        </Dropdown.Item>
                                    </ModalClickContainer>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

ProfileBanner.propTypes = {
    profile: PropTypes.oneOfType([
        publicUserPropTypes,
        organisationPropTypes,
    ]).isRequired,
    urlPrefix: PropTypes.string.isRequired,
    profileType: PropTypes.oneOf(['Organisation', 'People']).isRequired,
    entries: PropTypes.number,
    briefs: PropTypes.number,
    edit: PropTypes.string,
};

ProfileBanner.Loading = ProfileBannerLoading;
ProfileBanner.Organisation = function (props) {
    return <ProfileBanner urlPrefix="organisations" profileType="Organisation" {...props} />;
};
ProfileBanner.User = function (props) {
    return <ProfileBanner urlPrefix="users" profileType="People" {...props} />;
};

export default ProfileBanner;
