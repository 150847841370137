import { scroller } from 'react-scroll';
import PropTypes from 'prop-types';

export default function SectionLink({
    name,
    title,
    inView = false,
}) {
    const handleScrollToSection = (sectionName) => {
        scroller.scrollTo(sectionName, {
            delay: 0,
            duration: 100,
            offset: -150,
        });
    };

    return (
        <li className={!inView ? 'old-list-unstyled' : 'old-text-success'}>
            <div
                onClick={() => handleScrollToSection(name)}
                role="button"
                tabIndex={0}
            >
                <small>{title}</small>
            </div>
        </li>
    );
}

SectionLink.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    inView: PropTypes.bool,
};
