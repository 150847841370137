import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PageLayout } from 'features/navigation';
import {
    Container, Section, Tabs, Tab,
} from 'ui';
import { useSubmissions } from 'features/submission';
import { UserContext } from 'features/user';
import GiveFeedback from 'default/pages/FeedbackHub/GiveFeedback';
import ReceivedFeedback from 'default/pages/FeedbackHub/ReceivedFeedback';

export default function FeedbackHub() {
    const [searchParams] = useSearchParams();
    const { user } = useContext(UserContext);
    const givenFeedbackSubmissions = useSubmissions({ feedback: 'gf' });
    const receivedFeedbackSubmissions = useSubmissions({ feedback: 'rf', user_id: user.id });

    return (
        <PageLayout
            pageName={['Feedback', 'Hub']}
            navbarProps={{
                size: 'lg',
            }}
        >
            <Container className="old-sb-6 old-mb-6">
                <h2 className="capitalize text-center mb-12">Manage Feedback</h2>

                <Section>
                    <Tabs
                        color="primary"
                        size="lg"
                        radius="full"
                        fullWidth
                        defaultSelectedKey={(['given', 'received'].includes(searchParams.get('type')) && searchParams.get('type')) || 'give'}
                    >
                        <Tab
                            key="given"
                            title={(
                                <span>{`Given ${givenFeedbackSubmissions.total > 0 ? `(${givenFeedbackSubmissions.total})` : ''}`}</span>
                            )}
                        >
                            <GiveFeedback submissions={givenFeedbackSubmissions} />
                        </Tab>

                        <Tab
                            key="received"
                            title={(
                                <span>{`Received ${receivedFeedbackSubmissions.total > 0 ? `(${receivedFeedbackSubmissions.total})` : ''}`}</span>
                            )}
                        >
                            <ReceivedFeedback submissions={receivedFeedbackSubmissions} />
                        </Tab>
                    </Tabs>
                </Section>
            </Container>
        </PageLayout>
    );
}
