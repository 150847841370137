import { useContext } from 'react';
import { Link, generatePath, useLocation } from 'react-router-dom';
import { submissionListPropTypes } from 'features/submission/schemas/submissionSchema';
import { UserContext } from 'features/user';
import { Dropdown, ConfirmationModal, ModalClickContainer } from 'ui';
import routes from 'default/routes';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CollectionsIcon from '@mui/icons-material/Collections';
import SubmissionVotesModal from 'features/submission/components/modal/SubmissionVotesModal';
import useSubmissionDelete from 'features/submission/hooks/useSubmissionDelete';

export default function SubmissionOptionsDropdown({
    submission,
    isOrg = false,
}) {
    const { user } = useContext(UserContext);
    const location = useLocation();
    const handleDeleteSubmission = useSubmissionDelete();

    if (user.id !== submission.user.id || isOrg)
        return null;

    return (
        <Dropdown
            autoClose="outside"
            onClick={(e) => { e.stopPropagation(); }}
            data-testid="submission-options"
        >
            <Dropdown.Toggle className="old-rounded-pill old-d-flex old-align-items-center old-shadow old-p-1 old-px-2 old-bg-white old-text-black old-border-0">
                <SettingsOutlinedIcon />
            </Dropdown.Toggle>

            <Dropdown.Menu className="uppercase text-center old-rounded-4 old-border-0 old-p-2 old-shadow-sm">
                <ModalClickContainer
                    modal={ConfirmationModal}
                    promptText={(
                        <>
                            Are you sure you want to delete this submission?
                            <br />
                            Please not that this submission will be automatically pulled out from any briefs (active or closed) to which this was submitted and cannot be restored.
                            <br />
                            <b className="old-fs-12 uppercase">Submissions that won a brief cannot be deleted from the platform.</b>
                        </>
                        )}
                    action={() => handleDeleteSubmission(submission.id)}
                >
                    <Dropdown.Item
                        className="old-d-flex old-align-items-center old-justify-content-center old-rounded-4"
                        data-testid="submission-options-delete"
                    >
                        <DeleteIcon className="!size-5 old-me-1" />
                        <span>Delete</span>
                    </Dropdown.Item>
                </ModalClickContainer>

                {(submission.can_edit) && (
                    <>
                        <Dropdown.Item
                            className="old-d-flex old-align-items-center old-justify-content-center old-rounded-4"
                            as={Link}
                            to={`${generatePath(routes.enterBrief, { id: submission.brief.id })}?submission=${submission.id}`}
                            data-testid="submission-options-edit"
                        >
                            <EditIcon className="!size-5 old-me-1" />
                            <span>Edit</span>
                        </Dropdown.Item>

                        {(location.pathname.includes('user')) && (
                            <Dropdown.Item
                                className="old-d-flex old-align-items-center old-justify-content-center old-rounded-4"
                                as={Link}
                                to={`${generatePath(routes.brief, { id: submission.brief.id })}?tab=workspace`}
                                data-testid="submission-options-workspace"
                            >
                                <CollectionsIcon className="!size-5 old-me-1" />
                                <span>Workspace</span>
                            </Dropdown.Item>
                        )}
                    </>
                )}

                {(submission.brief.type === 'public' && ['voting', 'judging', 'completed'].includes(submission.brief.status)) && (
                    <ModalClickContainer
                        modal={SubmissionVotesModal}
                        submissionId={submission.id}
                    >
                        <Dropdown.Item
                            className="old-d-flex old-align-items-center old-justify-content-center old-rounded-4"
                            data-testid="submission-options-voters"
                        >
                            <VerifiedOutlinedIcon className="!size-5 old-me-1" />
                            <span>Show Voters</span>
                        </Dropdown.Item>
                    </ModalClickContainer>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
}
SubmissionOptionsDropdown.propTypes = {
    submission: submissionListPropTypes.isRequired,
    isOrg: PropTypes.bool,
};
