import { ImageCard } from 'ui';
import PropTypes from 'prop-types';

const highlightPropTypes = PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
});

export default function HighlightCard({
    entry: highlight = {},
    blank = false,
}) {
    const handleNavigate = () => {
        window.location.href = highlight.url;
    };

    if (!highlight || blank) {
        return (
            <ImageCard
                blank
                footer={ImageCard.SimpleBody}
            />
        );
    }

    return (
        <ImageCard
            className="bg-content1"
            image={highlight.thumbnail}
            onClick={handleNavigate}
            footer={ImageCard.SimpleBody}
            title={highlight.title}
            description={highlight.description}
            overlay={() => {}}
        />
    );
}

HighlightCard.propTypes = {
    entry: highlightPropTypes,
    blank: PropTypes.bool,
};
