import { useEffect, useRef } from 'react';
import { Element } from 'react-scroll';
import { ReactSortable } from 'react-sortablejs';
import PropTypes from 'prop-types';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import DeleteIcon from '@mui/icons-material/Delete';
import useGcsFileUpload, {
    deserializeFileData, deserializeImageData, serializeExistingFileData, serializeNewFileData,
} from 'hooks/useGcsFileUpload';
import { briefPropTypes } from 'features/brief';
import { submissionPropTypes, useSubmissionMutations } from 'features/submission';
import {
    Form, Dropdown, ConfirmationModal, ModalClickContainer, Button,
} from 'ui';
import {
    ValidateForm,
    FormFilePreviewImage,
    FormFile,
    FormControl,
    FormMultiFile,
} from 'forms';
import { enterBriefSchema } from 'utils/validation/validationSchemas';
import { stringifyFormData, writeFilesToInput } from 'utils/helpers';

import { enterBriefPolicies } from './policyData';

const IMAGEMAXSIZE = 26214400;

export default function EnterBriefForm({
    brief, submission, onPublish = () => {}, onSave = () => {},
}) {
    const imageInputRef = useRef(null);
    const formRef = useRef(null);
    const publishedRef = useRef(false);
    const technicalFilesRef = useRef([]);
    const thumbnailRef = useRef(null);
    const {
        files: images, dispatch, handleFileUpload: handleImageFileUpload, handleCancelFileUpload: cancelImageFileUpload,
    } = useGcsFileUpload('image', IMAGEMAXSIZE);
    const { createSubmission, updateSubmission } = useSubmissionMutations();

    useEffect(() => {
        if (submission.images)
            dispatch({ payload: deserializeImageData(submission.images.sort((a, b) => a.order_id - b.order_id)) });
    }, [submission]);

    useEffect(() => writeFilesToInput(images, imageInputRef.current), [images]);

    const serializeFormData = (formData) => {
        formData.new_files = serializeNewFileData(images.filter((f) => f.isNewFile).concat(technicalFilesRef.current.filter((f) => f.isNewFile)));
        formData.existing_files = serializeExistingFileData(images.filter((f) => !f.isNewFile).concat(technicalFilesRef.current.filter((f) => !f.isNewFile)));
        formData.type = brief.status === 'proposing' ? 'proposal' : 'submission';

        delete formData.publishedThumbnail;
        delete formData.publishedImages;
        delete formData.publishedTechnical_file;
        delete formData.require_working;
        delete formData.images;
        delete formData.technical_file; // We use by reference instead of formData

        return formData;
    };

    const handleCreateOrUpdateSubmission = async (formData) => {
        formData = serializeFormData(formData);
        const data = stringifyFormData(formData, ['thumbnail']);

        try {
            const res = submission.id
                ? await updateSubmission.mutateAsync({ id: submission.id, data })
                : await createSubmission.mutateAsync({ briefId: brief.id, data });

            if (publishedRef.current)
                onPublish(res.data);
            else
                onSave(res.data);

            return {};
        } catch (error) {
            return {
                errors: {
                    ...error.parseError(formData),
                    api: error.details,
                },
            };
        }
    };

    const handlePublishSubmission = (e, cb) => {
        publishedRef.current = true;
        cb(e, formRef);
    };

    const handleParsePublishSubmission = (formData) => {
        formData.published = publishedRef.current;
        formData.require_working = brief.require_working;
        formData.publishedThumbnail = thumbnailRef.current;
        formData.publishedImages = images;
        formData.publishedTechnical_file = technicalFilesRef.current;
        return formData;
    };

    return (
        <ValidateForm
            validationSchema={enterBriefSchema}
            onSubmit={handleCreateOrUpdateSubmission}
            parseData={handleParsePublishSubmission}
            errorPage="enter_brief"
        >
            {({ handleSubmit, errors, loading }) => (
                <Form className="old-mt-2" onSubmit={handleSubmit} validated={false} noValidate autoComplete="off" ref={formRef}>
                    <FormControl
                        name="title"
                        label="Title"
                        aria-label="title"
                        placeholder="Enter the title here"
                        maxLength={50}
                        required
                        defaultValue={submission?.title || ''}
                        key={`title${submission?.title}`}
                        error={errors.title}
                    />

                    <Element name="publishedThumbnail" />

                    <FormFilePreviewImage
                        name="thumbnail"
                        label="Thumbnail"
                        defaultValue={submission?.thumbnail}
                        maxSize={IMAGEMAXSIZE}
                        ref={thumbnailRef}
                        error={errors.thumbnail || errors.publishedThumbnail}
                    />

                    <span className="uppercase old-mb-2 old-d-block font-bold old-form-label">
                        Body Images (max 10)
                    </span>

                    <ReactSortable
                        list={images}
                        setList={(newImages) => dispatch({ payload: newImages.map(({ chosen, ...image }) => image) })}
                        animation={200}
                        className={(images.length > 0) ? 'old-rounded-4 old-border old-border-secondary old-border-style-dashed old-bg-offwhite old-mb-3 old-overflow-hidden' : 'old-d-none'}
                    >
                        {(images.length > 0 && images.map((image, index) => (
                            <div key={image.file_id} className={`old-w-100 old-d-flex old-border-secondary ${errors[image.file_id] ? 'old-bg-danger old-text-white' : ''} ${index + 1 < images.length ? 'old-border-bottom' : ''}`}>
                                <div className="old-d-flex old-align-items-center old-px-1">
                                    <DragIndicatorIcon />
                                </div>
                                <div className="old-position-relative old-w-100">
                                    <Element name={String(image.file_id)} />
                                    <img
                                        src={image.url}
                                        className={`old-w-100 old-border old-border-offwhite ${index === 0 ? 'old-rounded-top-4' : ''} ${index + 1 === images.length ? 'old-rounded-bottom-4' : ''}`}
                                        data-testid="enter-brief-image"
                                        alt={`${image.name} upload`}
                                        data-role="form-error-component"
                                    />

                                    {(errors[image.file_id]) && (
                                    <span className="old-py-3 old-d-flex old-align-items-center">
                                        <ErrorOutlineIcon className="old-me-2" />
                                        {errors[image.file_id]}
                                    </span>
                                    )}

                                    <div className="old-position-absolute old-top-0 old-start-0 old-mt-3 old-ms-3">
                                        <Dropdown data-testid="enter-brief-image-options">
                                            <Dropdown.Toggle className="old-rounded-pill old-shadow-sm old-d-flex old-align-items-center">
                                                <SettingsOutlinedIcon />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu
                                                className="uppercase text-center old-rounded-4 old-border-0 old-shadow-sm old-p-2"
                                                data-testid="enter-brief-image-options-dropdown"
                                            >
                                                <Dropdown.Item
                                                    onClick={() => dispatch({ type: 'REORDER', payload: { prevIndex: index, nextIndex: index - 1 } })}
                                                    className="old-d-flex old-align-items-center old-justify-content-center old-rounded-4"
                                                >
                                                    <ArrowCircleUpIcon className="!size-5 old-me-1" />
                                                    <span>Move Up</span>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => dispatch({ type: 'REORDER', payload: { prevIndex: index, nextIndex: index + 1 } })}
                                                    className="old-d-flex old-align-items-center old-justify-content-center old-rounded-4"
                                                >
                                                    <ArrowCircleDownIcon className="!size-5 old-me-1" />
                                                    <span>Move Down</span>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => dispatch({ type: 'REMOVE', payload: image.file_id })}
                                                    className="old-d-flex old-align-items-center old-justify-content-center old-rounded-4"
                                                >
                                                    <DeleteIcon className="!size-5 old-me-1" />
                                                    <span>Delete</span>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        )))}
                    </ReactSortable>

                    <Element name="publishedImages" />
                    <FormFile.Dropzone
                        name="images"
                        aria-label="images"
                        className="bg-content2 hover:bg-default-focus rounded-3xl old-p-md-6"
                        wrapperClassName="old-mb-5 old-w-100"
                        onUpdate={handleImageFileUpload}
                        onCancel={cancelImageFileUpload}
                        error={errors.images || errors.publishedImages}
                        maxSize={IMAGEMAXSIZE}
                        ref={imageInputRef}
                    />

                    <FormControl
                        placeholder="You have 600 characters to tell the world about your idea. Describe your work and tell everyone about your inspiration too."
                        name="description"
                        label="Description"
                        aria-label="description"
                        as="textarea"
                        rows={10}
                        maxLength={600}
                        required
                        error={errors.description}
                        defaultValue={submission?.description || ''}
                        key={`description${submission?.description}`}
                        promptText="Here you have 600 characters to tell the world about your idea. Describe your work and tell everyone about your inspiration too."
                    />

                    <Element name="publishedTechnical_file" />
                    <FormMultiFile
                        name="technical_file"
                        maxFiles={1}
                        fileType="technical"
                        label="Upload Working File (.AI/.PSD/.TIFF/.EPS/.OBJ)"
                        fileTypes={[]} // TODO: do we want to define a type here
                        ref={technicalFilesRef}
                        key={submission.files ? `technical${submission.files.map((f) => f.file.id)}` : 'technical'}
                        error={errors.technical_file || errors.publishedTechnical_file}
                        fileError={(fileId) => errors[fileId]}
                        defaultValue={submission.files ? deserializeFileData(submission.files).filter((f) => f.type === 'technical') : []}
                        required={(submission.files && deserializeFileData(submission.files).filter((f) => f.type === 'technical').length > 0) ? true : brief.require_working}
                    />

                    <div className="old-pt-3" data-testid="enter-brief-policies">
                        <h6 className="uppercase font-bold">By Continuing you are agreeing to the following terms:</h6>
                        <ul>
                            {enterBriefPolicies.map((policy) => {
                                const agreement = policy.agreement(brief);
                                if (!agreement)
                                    return null;

                                return (
                                    <li key={policy.name}><small className="font-roman">{agreement}</small></li>
                                );
                            })}
                        </ul>
                    </div>

                    <div className="old-d-flex old-align-items-center old-justify-content-end">
                        {(!submission?.published) ? (
                            <div className="flex gap-2">
                                <Button
                                    variant="faded"
                                    type="submit"
                                    size="lg"
                                    data-testid="enter-brief-draft"
                                    isLoading={loading}
                                    onClick={() => { publishedRef.current = false; }}
                                    aria-label="draft"
                                    trackingName="enter brief"
                                >
                                    Save Draft
                                </Button>

                                <ModalClickContainer
                                    modal={ConfirmationModal}
                                    protectedAction
                                    promptText="Are you sure want to publish this submission? Published submissions will be entered into the brief."
                                    confirmText="Publish"
                                    action={(e) => handlePublishSubmission(e, handleSubmit)}
                                >
                                    <Button
                                        color="primary"
                                        size="lg"
                                        isLoading={loading}
                                        data-testid="enter-brief-submit"
                                        trackingName="enter brief"
                                        aria-label="publish"
                                    >
                                        Publish
                                    </Button>
                                </ModalClickContainer>
                            </div>
                        ) : (
                            <Button
                                type="submit"
                                color="primary"
                                size="lg"
                                data-testid="enter-brief-submit"
                                aria-label="update"
                                isLoading={loading}
                                trackingName="enter brief"
                                onClick={() => { publishedRef.current = true; }}
                            >
                                Save Changes
                            </Button>
                        )}
                    </div>
                </Form>
            )}
        </ValidateForm>
    );
}
EnterBriefForm.propTypes = {
    brief: briefPropTypes.isRequired,
    submission: PropTypes.oneOfType([submissionPropTypes, PropTypes.shape({})]).isRequired,
    onPublish: PropTypes.func,
    onSave: PropTypes.func,
};
