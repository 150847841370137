import { useState } from 'react';
import { Button } from 'ui';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CancelIcon from '@mui/icons-material/Cancel';
import PropTypes from 'prop-types';

export default function SubmissionWinnerSelectButton({
    handleSelectWinner,
    handleDeselectWinner,
    isWinner = false,
    text = 'Make Winner',
    undoText = 'Winner',
}) {
    const [loading, setLoading] = useState(false);

    const handleSelectOrDeselectWinner = async () => {
        setLoading(true);
        if (isWinner)
            await handleDeselectWinner();
        else
            await handleSelectWinner();
        setLoading(false);
    };

    return (
        <Button
            color="primary"
            endContent={isWinner ? <CancelIcon /> : <EmojiEventsIcon />}
            size="lg"
            isLoading={loading}
            onClick={handleSelectOrDeselectWinner}
        >
            {isWinner ? undoText : text}
        </Button>
    );
}
SubmissionWinnerSelectButton.propTypes = {
    handleSelectWinner: PropTypes.func.isRequired,
    handleDeselectWinner: PropTypes.func.isRequired,
    isWinner: PropTypes.bool,
    text: PropTypes.string.isRequired,
    undoText: PropTypes.string.isRequired,
};
