import {
    useState, useRef, forwardRef,
} from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import {
    cropImage, dataURLtoFile, readFile, writeFilesToInput,
} from 'utils/helpers';
import Modal from 'ui/modals/Modal';
import useDebounce from 'hooks/useDebounce';
import PropTypes from 'prop-types';
import FormFile from 'forms/file/FormFile';

const MAXHEIGHT = 300;

const DefaultAs = forwardRef(({ fileAs, ...props }, ref) => (
    <FormFile.Dropzone
        ref={ref}
        as={fileAs}
        {...props}
    />
));
DefaultAs.propTypes = {
    fileAs: PropTypes.elementType,
};
DefaultAs.defaultProps = {
    fileAs: undefined,
};

const ImageCropModal = forwardRef(({
    onComplete, as, aspect, maxWidth, maxHeight, defaultSrc, show: open, ...asRefProps
}, ref) => {
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();
    const [src, setSrc] = useState(defaultSrc);
    const [show, setShow] = useState(open && defaultSrc);
    const previewCanvasRef = useRef(null);
    const outputCanvasRef = useRef(null);
    const asRef = useRef(null);
    const imgRef = useRef(null);
    const As = as;

    const makeInitialCrop = (width, height, unit = '%s') => centerCrop(
        makeAspectCrop({
            unit,
            width: Math.min(width, height),
        }, aspect.width / aspect.height, width, height),
        width,
        height,
    );

    const imageLoad = (e) => {
        const { width, height } = e.currentTarget;
        const newCropPx = makeInitialCrop(width, height, 'px');
        const newCropPs = makeInitialCrop(width, height, '%');
        cropImage(imgRef.current, previewCanvasRef.current, outputCanvasRef.current, newCropPx.x, newCropPx.y, newCropPx.width, newCropPx.height, 'white', maxWidth, maxHeight);
        setCrop({
            px: newCropPx,
            ps: newCropPs,
        });
    };

    const handleCropImage = async () => {
        const mime = src.substring(src.indexOf(':') + 1, src.indexOf(';'));

        // Convert the canvas image to data url
        const fileDataUrl = outputCanvasRef.current.toDataURL(mime);

        // Convert the data url to file
        const file = await dataURLtoFile(fileDataUrl, asRef.current?.files[0].name);

        writeFilesToInput([file], asRef.current);

        if (ref)
            ref.current = file;

        if (onComplete)
            onComplete(fileDataUrl);
        setShow(false);
    };

    useDebounce(() => {
        if (!completedCrop)
            return;

        cropImage(imgRef.current, previewCanvasRef.current, outputCanvasRef.current, crop.px?.x, crop.px?.y, crop.px?.width, crop.px?.height, 'white', maxWidth, maxHeight);
    }, [completedCrop], 100);

    asRefProps.onUpdate = async (file) => {
        setSrc(await readFile(file));
        setShow(true);
    };

    return (
        <>
            {(as) && (
                <As
                    ref={asRef}
                    {...asRefProps}
                />
            )}

            <Modal
                isOpen={show}
                isDismissable={false}
                isKeyboardDismissDisabled={false}
                onOpenChange={() => setShow(false)}
                data-testid="image-crop-modal"
                scrollBehavior="outside"
            >
                <Modal.ModalHeader>Crop Your Photo</Modal.ModalHeader>

                <Modal.ModalBody className="block mx-auto">
                    <ReactCrop
                        crop={crop?.ps}
                        onChange={(pixelCrop, percentageCrop) => setCrop({
                            px: pixelCrop,
                            ps: percentageCrop,
                        })}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={aspect.width / aspect.height}
                        maxWidth={maxWidth}
                        maxHeight={maxHeight}
                        className="old-mb-5"
                        keepSelection
                        minWidth={50}
                        minHeight={50}
                    >
                        <img
                            ref={imgRef}
                            src={src}
                            onLoad={imageLoad}
                            style={{
                                maxHeight: `${MAXHEIGHT}px`,
                            }}
                            data-testid="image-crop-control"
                            alt="cropped upload"
                        />
                    </ReactCrop>

                    <div className="old-w-100 text-center">
                        <h4>Preview</h4>

                        <div
                            className={`old-ratio old-ratio-${aspect.width}x${aspect.height} old-m-auto`}
                            style={{
                                maxWidth,
                                maxHeight,
                            }}
                        >
                            <canvas
                                ref={previewCanvasRef}
                                width={maxWidth}
                                height={maxHeight}
                                data-testid="image-crop-preview"
                            />
                        </div>

                        <canvas
                            hidden
                            ref={outputCanvasRef}
                        />
                    </div>
                </Modal.ModalBody>

                <Modal.ModalActionsFooter
                    confirmText="Save"
                    onCancel={() => setShow(false)}
                    onConfirm={handleCropImage}
                    cancelProps={{
                        'data-testid': 'image-crop-modal-cancel',
                    }}
                    confirmProps={{
                        'data-testid': 'image-crop-modal-confirm',
                    }}
                />
            </Modal>
        </>
    );
});

ImageCropModal.propTypes = {
    onComplete: PropTypes.func,
    as: PropTypes.elementType,
    aspect: PropTypes.exact({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
    }),
    maxWidth: PropTypes.number,
    maxHeight: PropTypes.number,
    defaultSrc: PropTypes.string,
    show: PropTypes.bool,
};

ImageCropModal.defaultProps = {
    onComplete: undefined,
    as: DefaultAs,
    aspect: { width: 4, height: 3 },
    maxWidth: 800,
    maxHeight: 600,
    defaultSrc: null,
    show: false,
};

export default ImageCropModal;
