import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';

export default function Section({
    children,
    className = '',
}) {
    return (
        <section
            className={twMerge('w-full relative flex flex-col flex justify-center items-center', className)}
        >
            <div className="max-w-screen-lg w-full m-auto [&>*:not(:last-child):not(section)]:mb-4 lg:[&>*:not(:last-child):not(section)]:mb-8">
                {children}
            </div>
        </section>
    );
}

Section.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
