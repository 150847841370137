import { useQuery, client, queryFactory } from 'api';

const spotlightQueries = queryFactory('submissions/spotlight');

const useSpotlight = () => useQuery({
    queryKey: spotlightQueries.list(),
    queryFn: async ({ queryKey: [{ url }] }) => (await client.get(url)).data,
    select: (data) => data.map((spotlight) => ({ ...spotlight.submission, created_at: spotlight.created_at })),
});

export {
    useSpotlight, spotlightQueries,
};
