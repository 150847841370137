import { useEffect, useState } from 'react';
import api from 'adapters/api';

const TagsWrapper = ({
    fetchUrl = 'tags/',
    selectedUrl = '',
    group,
    children,
}) => {
    const [tags, setTags] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getTags = async () => {
            const [tagsRes, selectedRes] = await Promise.all([
                api.get({ url: fetchUrl, params: { group } }),
                ...(selectedUrl) ? [api.get({ url: selectedUrl })] : [],
            ]);

            if (!tagsRes.success || !(selectedRes?.success || true)) {
                setLoading(false);
                return;
            }

            const selected = selectedRes?.data || [];
            const newTags = tagsRes.data.map((tag) => ({
                [tag.id]: {
                    label: tag.value.replace('_', ' '),
                    value: tag.value,
                    checked: selected.some((st) => st.id === tag.id),
                },
            }));

            setTags(Object.assign({}, ...newTags));
            setLoading(false);
        };
        getTags();
    }, [selectedUrl]);

    return children({
        tags,
        loading,
    });
};

export default TagsWrapper;
