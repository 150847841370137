import { ValidationError } from 'yup';
import axios, { AxiosError, CanceledError } from 'axios';

const backendApiInstance = axios.create({
    baseURL: process.env.REACT_APP_API_SERVER_URL + process.env.REACT_APP_API_BASE_URL,
    name: 'Backend API',
    retryOnError: true, // Enable retry for all requests
    maxRetries: 2, // Maximum number of retries
    retryDelay: 500, // Delay between retries in milliseconds
    paramsSerializer: {
        indexes: null,
    },
});

// Interceptor: retry 5xx errors 3 times
backendApiInstance.interceptors.response.use(
    null,
    (error) => {
        if (error.constructor === AxiosError) {
            const { config } = error;
            config.retryCount = config.retryCount || 0;
            if (config.retryCount < backendApiInstance.defaults.maxRetries && backendApiInstance.defaults.retryOnError) {
                config.retryCount += 1;
                console.log(`Retry No: ${config.retryCount}`);
                return new Promise((resolve) => {
                    setTimeout(() => resolve(backendApiInstance(config)), backendApiInstance.defaults.retryDelay);
                });
            }
        }
        return Promise.reject(error);
    },
);

const GCSInstance = axios.create({
    timeout: 3600000,
    headers: {
        'content-type': 'text/plain',
    },
    name: 'GCS API',
});

const maxPageLimit = 12;

const isSuccess = (status) => status >= 200 < 300;

/**
* @function:    validationErrorToObject
* @description: Take an error thrown by YUP and parse it into an object
*               of type {errorKey: errorValue} in which each value is a string
*               of the first occuring error, if multiple of the same keys are found.
*
* @param {object} errors:   The error object thrown by YUP whenever validation fails.
*                           The inner value contains an array of all the errors which contains
*                           attributes such as the path, message etc.
*
* @returns {object}:    The parsed error object of type {errorKey: errorValue}
*/
const validationErrorToObject = (errors) => {
    const keys = [];
    errors = errors.inner.map((e) => {
        if (keys.includes(e.path))
            return {};
        keys.push(e.path);
        return { [e.path]: e.message };
    });
    return Object.assign({}, ...errors);
};

/**
* @function:    axiosErrorToObject
* @description: Take an error returned by the backend to axios and parse it into an object
*               of type {errorKey: errorValue}
*
* @param {any} error:       The error from the response data
* @param {Object} data:     The original formdata (non stringified) to grab the errorKeys from
* @param {string} key:      (optional) - The current existing key from the data
*                           - If a key from the error does not exist in the data, we use the current value of the key
*                           - The current key value is set whenever a new, existing key is found
* @param {Object} result:   (optional) - The result of type {errorKey: errorValue} prepopulated
*
* @returns {Object}:    The parsed error object of type {errorKey: errorValue}
*/
const axiosErrorToObject = (error, data, key = 'test', result = {}) => {
    if (!error || data == null)
        return {};

    if (typeof error !== 'object') {
        result[key] = error;
    } else {
        Object.entries(error).forEach(([k, v]) => {
            key = (k in data || k === String(data[key])) ? k : key;
            let containsObj = false;

            if (Array.isArray(data[key]) && data[key].length > 0) {
                data[key].forEach((value, index) => {
                    result = axiosErrorToObject(v[index], value, key, result);
                });

                if (Object.keys(result).length > 0)
                    return;

                containsObj = data[key].some((value) => typeof value === 'object');
            }

            if (typeof data[key] === 'object' && (!Array.isArray(data[key]) || containsObj))
                data = data[key];

            result = axiosErrorToObject(v, data, key, result);
        });
    }

    return result;
};

/**
*   @function:      registerInstance
*   @description:   Register an axios instance to use REST methods.
*                   Returns either success: true or false if the response is OK or returns any error.
*                   If response returns any error, return serialized error object with message.
*
*   @param {Object} instance:   The axios instance to register
*   @param {string[]} methods:  An array of REST methods to register
*
*   @returns {Promise<Object>}: Either success: true or serialized error object with message
*/
const registerInstance = (instance, methods) => {
    const api = {};

    methods.forEach((method) => {
        /**
        * @function:    api {obj} [method: REST method]
        * @description: Register an individual REST method to the api axios instance
        *
        * @param {string} url:              The shorthand url of the request to append to the api axios instance baseUrl
        * @param {Object} headers:          (optional) - A list of additional headers to pass to the request
        * @param {Object} validationSchema: (optional) - If supplied, validate the request data against the schema
        * @param {Object} options:          (optional) - An object of optional arguments for the request
        *
        * @returns {Promise}:   Promise representing the HTTP request
        */
        api[method] = async ({
            url,
            headers,
            validationSchema,
            onUploadProgress,
            ...options
        }) => {
            if (!('initialized' in instance.defaults) || !instance.defaults.initialized)
                throw new Error(`Api instance: ${instance.defaults?.name || ''} is not initialized`);

            try {
                // We don't deconstruct data as not every request type will use it (so we don't want to pass it in these cases)
                if (validationSchema && options.data)
                    await validationSchema.validate(options.data, { abortEarly: false });

                onUploadProgress = (
                    !('updateProgress' in instance.defaults) || instance.defaults.updateProgress
                ) ? { onUploadProgress } : {};

                // Check URL is valid
                url = new URL(url, instance.defaults?.baseURL);

                const res = await instance({
                    url: url.href,
                    method,
                    headers,
                    ...onUploadProgress,
                    ...options,
                });
                return {
                    data: await res.data,
                    success: isSuccess(res.status),
                    headers: res.headers,
                    parseError: () => ({}),
                };

            // We check if the promise throws any errors (reject)
            } catch (err) {
                if (!('showDebug' in instance.defaults) || instance.defaults.showDebug)
                    console.log(err);

                switch (err.constructor) {
                    case ValidationError:
                        return {
                            success: false,
                            parseError: () => validationErrorToObject(err),
                            errorDetails: {
                                message: 'Form is not valid',
                                timestamp: Date.now(),
                            },
                        };
                    case AxiosError:
                        return {
                            success: false,
                            parseError: (formData) => axiosErrorToObject(err.response?.data?.errors, formData) || {},
                            errorDetails: {
                                message: err.response?.data?.reason || err.message,
                                timestamp: Date.now(),
                            },
                        };
                    // TODO: in the future we want a better way of handling these so that it doesn't display on the frontend
                    case CanceledError:
                        return {
                            success: false,
                            canceled: true,
                            message: 'File upload cancelled',
                            parseError: () => {},
                        };
                    default:
                        return {
                            success: false,
                            parseError: () => { alert('Something went wrong, please try again later'); },
                            errorDetails: {
                                message: 'Something went wrong, please try again',
                                timestamp: Date.now(),
                            },
                        };
                }
            }
        };
    });

    api.init = (config = {}) => {
        if (instance.defaults?.initialized)
            return console.warn(`instance: ${instance.defaults?.name || ''} already initialized.`);

        instance.defaults = {
            ...instance.defaults,
            ...config,
        };
        instance.defaults.initialized = true;
        return instance;
    };

    return api;
};

const api = registerInstance(backendApiInstance, ['get', 'post', 'patch', 'put', 'delete']);
const GCSApi = registerInstance(GCSInstance, ['put']);

export {
    validationErrorToObject,
    axiosErrorToObject,
    GCSApi,
    maxPageLimit,
};
export default api;
