const routes = {
    home: '/',
    dashboard: '/dashboard',
    briefs: '/briefs',
    brief: '/briefs/:id',
    briefSubmission: '/briefs/:id/submission/:submissionId',
    enterBrief: '/enter/briefs/:id',
    enterBriefComplete: '/enter/briefs/:id/complete',
    voting: '/voting',
    votingSubmission: '/voting/submission/:submissionId',
    spotlight: '/spotlight',
    spotlightSubmission: '/spotlight/submission/:submissionId',
    about: '/about',
    organisation: '/organisation/:id',
    userProfile: '/user/:username',
    userProfileSubmission: '/user/:username/submission/:submissionId',
    userProfileEdit: '/user/edit',
    feedbackHub: '/user/feedback',
    feedbackHubSubmission: '/user/feedback/submission/:submissionId',
    redirectOrg: '/redirect/org/*',
};

export default routes;
