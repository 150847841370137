import { useEffect } from 'react';
import { SignInAndUp } from 'supertokens-auth-react/recipe/thirdpartypasswordless/prebuiltui';
import { toast } from 'react-toastify';
import { Modal } from 'ui';
import Onboarding from 'features/authentication/components/modal/Onboarding';
import PropTypes from 'prop-types';

export default function AuthModal({
    onHide,
    show = false,
    onboarding = false,
}) {
    useEffect(() => {
        if (!show)
            return;

        const userAgent = window.navigator.userAgent.toLowerCase();
        if (userAgent.includes('instagram') || userAgent.includes('webview')) {
            toast.warn('If signing in with Google, please try logging in on your device browser (eg. Safari, Chrome)', {
                position: 'top-center',
                autoClose: false,
                hideProgressBar: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            }, {
                autoClose: 5000, // Do we want this to automatically close?
            });
        }
    }, [show]);

    return (
        <Modal
            isOpen={show}
            onOpenChange={onHide}
            data-testid="auth-modal"
            isDismissable={!onboarding}
            hideCloseButton
            scrollBehavior="inside"
        >
            {(!onboarding) ? (
                <SignInAndUp />
            ) : (
                <Onboarding />
            )}
        </Modal>
    );
}

AuthModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
    onboarding: PropTypes.bool,
};
