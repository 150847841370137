import { useCallback, useEffect, useRef } from 'react';
import { css } from 'aphrodite';
import animationStyles from 'ui/animations';

export default function useAnimateVoteButton(
    voted,
    animation = animationStyles.bounceIn,
) {
    const didMountRef = useRef(false);

    useEffect(() => {
        didMountRef.current = true;
    }, []);

    const animateVerified = useCallback((node) => {
        if (!node || !voted || !didMountRef.current)
            return;

        node.classList.add(css(animationStyles.fadeIn));

        setTimeout(() => {
            node.classList.add(css(animation));
        }, 500);
    }, [voted]);

    const animateVotes = useCallback((node) => {
        if (!node || !voted || !didMountRef.current)
            return;
        node.classList.add('hidden');

        setTimeout(() => {
            node.classList.remove('hidden');
            node.classList.add(css(animationStyles.fadeIn));
        }, 500);
    }, [voted]);

    return { animateVerified, animateVotes };
}
