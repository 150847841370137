import { useEffect, useState, useMemo } from 'react';
import ThemeContext from 'ui/ThemeContext';
import PropTypes from 'prop-types';

export default function ThemeProvider({ defaultTheme = 'light', children }) {
    const [theme, setTheme] = useState(() => localStorage.getItem('theme') ?? defaultTheme);

    // We can use applyTheme to set a theme persistently, setTheme to set a theme per page but not be persistant
    const applyTheme = (newTheme) => {
        localStorage.setItem('theme', newTheme);
        setTheme(newTheme);
    };

    useEffect(() => {
        document.querySelector('body')?.classList.add(theme, 'text-foreground', 'bg-background', 'font-local');
    }, [theme]);

    const themeContextProviderValue = useMemo(() => ({ theme, setTheme, applyTheme }), [theme, setTheme, applyTheme]);

    return (
        <ThemeContext.Provider value={themeContextProviderValue}>
            {children}
        </ThemeContext.Provider>
    );
}
ThemeProvider.propTypes = {
    defaultTheme: PropTypes.string,
    children: PropTypes.node.isRequired,
};
