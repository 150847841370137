import { useState } from 'react';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import PropTypes from 'prop-types';
import StickyActionButton from 'ui/buttons/StickyActionButton';
import ShareButton from 'ui/buttons/ShareButton';
import Collapse from 'ui/layout/Collapse';

export default function StickyActionShareButton({
    title = '',
    url = '',
    trackingLocation = '',
    types = ['facebook', 'twitter', 'whatsapp'],
    style = {
        fg: 'dark',
        bg: 'white',
        open: 'light',
    },
}) {
    const [shareOpen, setShareOpen] = useState(false);

    return (
        <StickyActionButton
            onClick={() => setShareOpen(!shareOpen)}
            name="Share"
            tooltip="Share"
            icon={ShareOutlinedIcon}
            className={`!size-10 old-p-2 old-rounded-circle  old-bg-${shareOpen ? style.open : style.bg} old-text-${style.fg}`}
            wrapperClassName={`old-bg-${style.bg} old-rounded-pill old-overflow-hidden old-d-inline-block`}
        >
            <Collapse in={shareOpen}>
                <div className={`old-bg-${style.bg} old-p-1`}>
                    <ShareButton
                        title={title}
                        url={url}
                        types={types}
                        wrapperClassName="old-d-flex old-flex-column old-all-mb-2"
                        className={`!size-8 old-text-${style.fg}`}
                        trackingLocation={trackingLocation}
                    />
                </div>
            </Collapse>
        </StickyActionButton>
    );
}

StickyActionShareButton.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    trackingLocation: PropTypes.string,
    types: PropTypes.arrayOf(PropTypes.string),
    style: PropTypes.exact({
        fg: PropTypes.string.isRequired,
        bg: PropTypes.string.isRequired,
        open: PropTypes.string.isRequired,
    }),
};
