import { Link, useLocation } from 'react-router-dom';
import { css } from 'aphrodite';
import {
    Container, Navbar, NavbarContent, NavbarItem,
} from 'ui';
import NavbarSpacer from 'features/navigation/components/layout/NavbarSpacer';
import animationStyles from 'ui/animations';
import PropTypes from 'prop-types';
import useScrollDirection from 'features/navigation/hooks/useScrollDirection';
import useScrollAmount from 'features/navigation/hooks/useScrollAmount';

export default function NavbarDesktop({
    homeLink,
    icon,
    collapse,
    size = undefined,
    desktopRoutes = [],
    desktopIcons = [],
}) {
    const { pathname, state } = useLocation();
    const scrollDirection = useScrollDirection();
    const scrollReached = useScrollAmount(0.01);

    return (
        <div className={`w-full hidden ${collapse}:flex`}>
            <NavbarSpacer size={size} />

            <Navbar
                classNames={{
                    base: `backdrop-blur-none backdrop-saturate-100 fixed justify-between items-center old-z-4 old-bg-opacity-50 transition-[top] ease-linear duration-100 delay-0 ${scrollReached ? 'backdrop-blur-lg bg-background/50 text-foreground' : 'bg-transparent'} ${scrollDirection !== 'down' ? 'top-0' : '-top-20'}`,
                    wrapper: 'px-0',
                }}
                maxWidth="full"
                height="5rem"
            >
                <Container className="old-d-flex old-justify-content-between old-py-4">
                    <NavbarContent className="z-3 !grow-0 gap-6 lg:gap-8 p-0 m-0">
                        {(desktopRoutes).map(({ onClick, name, matchPath }) => (
                            <NavbarItem
                                role="button"
                                tabIndex={0}
                                key={name}
                                onClick={onClick}
                                className={`text-large ${`/${pathname.split('/')[1]}` === matchPath ? 'underline underline-offset-4 decoration-4' : 'hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-1 before:origin-left before:transition-transform before:duration-500 before:scale-x-0 before:bg-current before:absolute before:left-0 before:bottom-0'}`}
                            >
                                <span className="uppercase">{name}</span>
                            </NavbarItem>
                        ))}
                    </NavbarContent>

                    <div className="absolute flex items-center justify-center w-full h-full left-0 top-0">
                        <Link to={homeLink}>
                            <img src={icon} alt="Oditi Company Icon" className="!size-12" />
                        </Link>
                    </div>

                    <NavbarContent className="z-3 gap-6 lg:gap-8 p-0 m-0 !grow-0 !basis-auto" justify="end">
                        {(desktopIcons).map(({
                            onClick, name, matchPath, icon: Icon, selectedIcon: SelectedIcon,
                        }) => (
                            <NavbarItem
                                key={name}
                                role="button"
                                tabIndex={0}
                                onClick={onClick}
                                className="inline-flex"
                            >
                                {((pathname.split('/')[1] === name.toLowerCase()) || (pathname === matchPath)) ? (
                                    <SelectedIcon className={`!size-8 rounded-full flex items-center ${state?.referrer === 'navbar' ? css(animationStyles.flipIn) : ''}`} />
                                ) : (
                                    <Icon className="!size-8 rounded-full flex items-center" />
                                )}
                            </NavbarItem>
                        ))}
                    </NavbarContent>
                </Container>
            </Navbar>
        </div>
    );
}

NavbarDesktop.propTypes = {
    homeLink: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    collapse: PropTypes.string.isRequired,
    size: PropTypes.string,
    desktopRoutes: PropTypes.arrayOf(PropTypes.shape({})),
    desktopIcons: PropTypes.arrayOf(PropTypes.shape({})),
};
