import {
    useParams, generatePath, useSearchParams, useNavigate,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import routes from 'default/routes';
import { NotFound } from 'features/report';
import { useBrief } from 'features/brief';
import { useSubmission } from 'features/submission';
import { PageLayout } from 'features/navigation';
import {
    Section, Container, ConfirmationModal, Alert, ModalClickContainer,
} from 'ui';

import EnterBriefForm from './EnterBriefForm';

function RemoveThumbnailOverlay({ onDelete }) {
    return (
        <div className="old-w-100 old-h-100">
            <div className="old-position-absolute old-top-2 old-start-2">
                <CloseIcon
                    className="old-bg-white old-rounded-circle old-text-black old-p-1 !size-8"
                    role="button"
                    onClick={onDelete}
                />
            </div>
        </div>
    );
}

RemoveThumbnailOverlay.propTypes = {
    onDelete: PropTypes.func.isRequired,
};

export default function EnterBrief() {
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const { data: briefData, isLoading: briefLoading } = useBrief(id);
    const { data: submissionData, isLoading: submissionLoading } = useSubmission(searchParams.get('submission'));
    const brief = briefData ?? {};
    const submission = submissionData ?? {};
    const submissionType = brief.status === 'proposing' ? 'proposal' : 'submission';
    const navigate = useNavigate();

    if (Object.keys(brief).length > 0 && Object.keys(submission).length > 0 && (brief.id !== submission.brief.id))
        return <NotFound text="ERROR: Brief and submission do not match" issuePage="enter_brief" />;

    return (
        <PageLayout
            pageName={['Brief', 'Enter']}
            isLoading={briefLoading || submissionLoading}
        >
            {(Object.keys(brief).length > 0) ? (
                <Container className="old-mb-6 old-sb-6">
                    <Section className="mt-4 sm:mt-8">
                        <ModalClickContainer
                            modal={ConfirmationModal}
                            promptText="Are you sure you want to leave this page? Any unsaved changes will be lost."
                            action={() => navigate(generatePath(routes.brief, { id }))}
                        >
                            <div className="text-center old-d-flex old-align-items-center old-mb-5">
                                <ArrowBackIcon />
                                <h4 className="old-mb-0 old-ps-2">Back to Brief</h4>
                            </div>
                        </ModalClickContainer>

                        <div className="old-text-sm-center">
                            <h2 className="uppercase">
                                {submissionType}
                                {' '}
                                Form
                            </h2>
                        </div>

                        {(Object.keys(submission).length > 0 && !submission.published) && (
                            <Alert dismissible>
                                <small className="font-roman">
                                    This is a draft
                                    {' '}
                                    {submissionType}
                                    {' '}
                                    <u>only visible to you.</u>
                                    {' '}
                                    To enter it into the brief, click the publish button at the bottom of the page when you are ready!
                                </small>
                            </Alert>
                        )}

                        {(Object.keys(submission).length > 0 && !submission.published && Date.now() > dayjs(brief.submission_deadline)) && (
                            <Alert dismissible>
                                <small className="font-roman">
                                    The submission deadline for this brief has passed so your design will
                                    {' '}
                                    <u>not</u>
                                    {' '}
                                    be entered into the live competition. However, you can still submit your work and add to your portfolio to earn votes, receive feedback from fellow designers, and have a chance to be spotlighted!
                                </small>
                            </Alert>
                        )}

                        {(Object.keys(submission).length > 0 && submission.published) && (
                            <Alert dismissible>
                                <small className="font-roman">
                                    This is a published submission and has
                                    {' '}
                                    <u>already</u>
                                    {' '}
                                    been entered into the brief. You can edit it before the submission deadline. If you no longer wish to enter you can delete the submission from your profile or the brief workspace.
                                </small>
                            </Alert>
                        )}

                        <EnterBriefForm
                            brief={brief}
                            submission={submission}
                            onPublish={() => navigate(generatePath(routes.enterBriefComplete, { id }))}
                            onSave={(savedSubmission) => {
                                toast.success(<div data-testid="draft-toast">Draft Saved!</div>, { autoClose: 3000 });
                                setSearchParams((prev) => ({ ...prev, submission: String(savedSubmission.id) }));
                            }}
                        />
                    </Section>
                </Container>
            ) : (
                <NotFound text={'Bummer. \n The requested brief could not be found or does not exist.'} issuePage="enter_brief" />
            )}
        </PageLayout>
    );
}
