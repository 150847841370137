import { useState, useContext } from 'react';
import { UserContext } from 'features/user';
import { FeedbackPromptModal } from 'features/feedback';
import { abbreviateNumber } from 'utils/helpers';
import { Button } from 'ui';
import PropTypes from 'prop-types';
import useAnimateVoteButton from 'hooks/useAnimateVoteButton';
import VoteIcon from 'icons/VoteIcon';
import Mixpanel from 'adapters/mixpanel';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';

/* TODO: swap prop drilling to passing submissionId and using useSubmissionWithActions() */
export default function SubmissionVoteButton({
    handleVote,
    trackingLocation,
    hasVoted = false,
    numberVotes = 0,
    hasGivenFeedback = false,
    submissionId,
}) {
    const [loading, setLoading] = useState(false);
    const { animateVerified, animateVotes } = useAnimateVoteButton(hasVoted);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const { user } = useContext(UserContext);

    const handleTrackOnClick = async () => {
        if (hasVoted)
            return;

        setLoading(true);
        await handleVote();
        setLoading(false);

        Mixpanel.trackButton('Action Button', 'vote', trackingLocation);
        if (user.doesSessionExist && !hasGivenFeedback && Math.round(Math.random()) === 1)
            setShowFeedbackModal(true);
    };

    return (
        <>
            <FeedbackPromptModal
                show={showFeedbackModal}
                setShow={setShowFeedbackModal}
                submissionId={submissionId}
            />

            <Button
                color="success"
                size="lg"
                startContent={hasVoted ? <VerifiedOutlinedIcon ref={animateVerified} /> : <VoteIcon />}
                className="gap-2 uppercase"
                onClick={handleTrackOnClick}
                isLoading={loading}
                data-testid="vote-button"
                spinnerPlacement="end"
            >
                {hasVoted ? (
                    <span ref={animateVotes}>{abbreviateNumber(numberVotes, 1)}</span>
                ) : 'Vote'}
            </Button>
        </>
    );
}

SubmissionVoteButton.propTypes = {
    handleVote: PropTypes.func.isRequired,
    trackingLocation: PropTypes.string.isRequired,
    hasVoted: PropTypes.bool,
    numberVotes: PropTypes.number,
    hasGivenFeedback: PropTypes.bool,
    submissionId: PropTypes.number.isRequired,
};
