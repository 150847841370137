import PropTypes from 'prop-types';
import { organisationListPropTypes } from 'features/organisation';

const briefPropTypes = PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    submitted: PropTypes.bool.isRequired,
    entered: PropTypes.bool.isRequired,
    start_time: PropTypes.string.isRequired,
    proposal_deadline: PropTypes.string,
    proposal_selected: PropTypes.string,
    submission_deadline: PropTypes.string.isRequired,
    voting_deadline: PropTypes.string,
    winners_selected: PropTypes.string.isRequired,
    things_to_consider: PropTypes.string.isRequired,
    selection_criteria: PropTypes.string.isRequired,
    other_incentives: PropTypes.string.isRequired,
    prize_money: PropTypes.string,
});

const publicBriefPropTypes = PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    start_time: PropTypes.string.isRequired,
    proposal_deadline: PropTypes.string,
    proposal_selected: PropTypes.string,
    submission_deadline: PropTypes.string.isRequired,
    voting_deadline: PropTypes.string,
    winners_selected: PropTypes.string.isRequired,
    submitted: PropTypes.bool.isRequired,
    entered: PropTypes.bool.isRequired,
});

const briefListPropTypes = PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    submitted: PropTypes.bool.isRequired,
    entered: PropTypes.bool.isRequired,
    start_time: PropTypes.string.isRequired,
    proposal_deadline: PropTypes.string,
    proposal_selected: PropTypes.string,
    submission_deadline: PropTypes.string.isRequired,
    voting_deadline: PropTypes.string,
    winners_selected: PropTypes.string.isRequired,
    organisation: organisationListPropTypes.isRequired,
});

const smallBriefPropTypes = PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
});

export default briefPropTypes;
export {
    briefListPropTypes,
    publicBriefPropTypes,
    smallBriefPropTypes,
};
