import { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { SubmissionCard } from 'features/submission';
import { BriefCard } from 'features/brief';
import { ProfileDataCard } from 'features/profile';
import { css } from 'aphrodite';
import {
    Row, Col, CardGrid, Dropdown, Button,
} from 'ui';
import animationStyles from 'ui/animations';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import routes from 'default/routes';
import api from 'adapters/api';
import FormControl from 'forms/FormControl';
import PropTypes from 'prop-types';
import useKeyGen from 'hooks/useKeyGen';

function ShowMoreButton({
    show,
    to,
    onHide,
}) {
    if (!show)
        return null;

    return (
        <Button
            size="lg"
            color="primary"
            className="uppercase w-full"
            as={Link}
            to={to}
            onClick={onHide}
        >
            Show All
        </Button>
    );
}
ShowMoreButton.propTypes = {
    show: PropTypes.bool.isRequired,
    to: PropTypes.string.isRequired,
    onHide: PropTypes.func.isRequired,
};

function SearchField({
    option,
    setOption,
    handleSearch,
}) {
    const handleClickSearchbar = useCallback((node) => {
        if (node === null)
            return;

        // Wait for offcanvas to open and the animation to finish so you can select the formControl
        setTimeout(() => {
            node.focus();
        }, 500);
    }, []);

    return (
        <div className="flex sticky top-0 right-0 w-full h-full old-z-4 px-8 sm:px-12 py-4 backdrop-blur-lg bg-content2/50 text-foreground">
            <FormControl
                placeholder={`Search ${option}...`}
                className="old-rounded-start-4 old-rounded-end-0 old-p-3 old-d-inline-block old-shadow !bg-content1 !border-content1 !text-foreground !placeholder-primary-300"
                wrapperClassName="old-w-100"
                onUpdate={handleSearch}
                debounceTime={300}
                ref={handleClickSearchbar}
            />

            <Dropdown drop="down" align="end">
                <Dropdown.Toggle className="old-rounded-start-0 old-rounded-end-4 old-p-3 old-d-flex old-dropdown-toggle-blank old-shadow !bg-content1 !border-content1 !text-foreground">
                    <FilterListIcon />
                </Dropdown.Toggle>

                <Dropdown.Menu className="old-w-100 old-rounded-4 text-center old-all-mb-2 old-overflow-hidden old-border-0 old-my-2 old-shadow !text-center">
                    <Dropdown.Item
                        className={`old-py-2 ${option === 'submissions' ? 'old-bg-success old-text-white old-rounded-4' : ''}`}
                        onClick={() => setOption('submissions')}
                    >
                        Submissions
                    </Dropdown.Item>

                    <Dropdown.Item
                        className={`old-py-2 ${option === 'briefs' ? 'old-bg-success old-text-white old-rounded-4' : ''}`}
                        onClick={() => setOption('briefs')}
                    >
                        Briefs
                    </Dropdown.Item>

                    <Dropdown.Item
                        className={`old-py-2 ${option === 'profiles' ? 'old-bg-success old-text-white old-rounded-4' : ''}`}
                        onClick={() => setOption('profiles')}
                    >
                        Profiles
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}
SearchField.propTypes = {
    option: PropTypes.string.isRequired,
    setOption: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
};

export default function OffcanvasSearch({
    collapse,
    handleToggleCurrentPage,
}) {
    const [option, setOption] = useState('submissions');
    const [results, setResults] = useState({});
    const [loading, setLoading] = useState(false);
    const [term, setTerm] = useState('');
    const [showMore, setShowMore] = useState({
        briefs: false,
        submissions: false,
    });
    const keyGen = useKeyGen();

    const handleSearch = async (searchTerm) => {
        setTerm(searchTerm);

        if (!searchTerm)
            return;

        setLoading(true);

        const apiCalls = [
            ...(option === 'profiles' ? [api.get({ url: 'users/', params: { search: searchTerm } })] : []),
            ...(option === 'profiles' ? [api.get({ url: 'organisations/', params: { search: searchTerm } })] : []),
            ...(option === 'briefs' ? [api.get({ url: 'briefs/', params: { search: searchTerm } })] : []),
            ...(option === 'submissions' ? [api.get({ url: 'submissions/', params: { search: searchTerm } })] : []),
        ];

        const allResults = await Promise.all(apiCalls);

        // Only if they all succeed do we want to continue
        if (allResults.filter((res) => res.success).length !== apiCalls.length) {
            setLoading(false);
            return;
        }

        let allData;
        let totalPages = 0;
        if (allResults.length > 1) {
            allData = allResults[0].data.concat(allResults[1].data);
            totalPages = JSON.parse(allResults[0].headers?.get('X-pagination') || '{}').total_pages + JSON.parse(allResults[1].headers?.get('X-pagination') || '{}').total_pages;
        } else {
            allData = allResults[0].data;
            totalPages = JSON.parse(allResults[0].headers?.get('X-pagination') || '{}').total_pages;
        }

        setResults({ [option]: allData });
        setShowMore({ [option]: totalPages > 1 });
        setLoading(false);
    };

    useEffect(() => { handleSearch(term); }, [option]);

    return (
        <div className="w-full flex flex-col">
            <div className="px-8 sm:px-16 pt-8 sm:pt-16 flex items-center old-z-4">
                <span
                    className={`flex items-center gap-1 rounded-3xl px-4 py-2 old-mt-n2 old-ms-n3 old-fs-2 old-fs-${collapse}-3 bg-content2 text-foreground hover:bg-content1`}
                    role="button"
                    tabIndex={0}
                    onClick={handleToggleCurrentPage}
                    onMouseEnter={(e) => e.target.children[0]?.classList.add(css(animationStyles.pulse))}
                    onMouseLeave={(e) => e.target.children[0]?.classList.remove(css(animationStyles.pulse))}
                >
                    <ArrowBackIcon />
                    Search
                </span>
            </div>

            <SearchField
                option={option}
                setOption={setOption}
                handleSearch={handleSearch}
            />

            <div className="px-8 sm:px-12">
                {(option === 'profiles' && results.profiles?.length > 0 && !loading) && (
                <Row className="old-mb-4">
                    {(results.profiles.map((profile) => {
                        let profileData;
                        if ('username' in profile) {
                            profileData = {
                                icon: profile.icon,
                                type: 'People',
                                name: profile.username,
                                link: `/user/${profile.username}`,
                            };
                        } else {
                            profileData = {
                                icon: profile.icon,
                                type: 'Company',
                                name: profile.name,
                                link: `/organisation/${profile.id}`,
                            };
                        }

                        return (
                            <Col key={keyGen.getKey(profileData)} as={Link} to={profileData.link} className="old-my-3" sm={12}>
                                <ProfileDataCard
                                    className="rounded-full border-2 border-primary-100"
                                    profile={profile}
                                    icon={profileData.icon}
                                    name={profileData.name}
                                    profileType={profileData.type}
                                />
                            </Col>
                        );
                    }))}
                </Row>
                )}

                {(option === 'briefs' && results.briefs?.length > 0 && !loading) && (
                <>
                    <CardGrid
                        entries={results.briefs}
                        card={BriefCard}
                        sizing={{
                            xs: 12,
                            sm: 6,
                            md: 12,
                        }}
                    />

                    <ShowMoreButton
                        show={showMore.briefs}
                        to={`${routes.briefs}?search=${term}`}
                        onHide={handleToggleCurrentPage}
                    />
                </>
                )}

                {(option === 'submissions' && results.submissions?.length > 0 && !loading) && (
                <>
                    <CardGrid
                        entries={results.submissions}
                        card={SubmissionCard}
                        sizing={{
                            xs: 12,
                            sm: 6,
                            md: 12,
                        }}
                    />

                    <ShowMoreButton
                        show={showMore.submissions}
                        to={`${routes.voting}?search=${term}`}
                        onHide={handleToggleCurrentPage}
                    />
                </>
                )}

                {((results.profiles?.length <= 0 || results.briefs?.length <= 0 || results.submissions?.length <= 0) && !loading) && (
                <span className="old-fs-4 absolute top-0 left-0 h-full w-full flex flex-col items-center justify-center gap-2">
                    <SentimentVeryDissatisfiedIcon className="!size-10" />
                    No
                    {' '}
                    {option}
                    {' '}
                    could be found
                </span>
                )}

                {(Object.keys(results).length <= 0 && !loading) && (
                <span className="old-fs-4 absolute top-0 left-0 h-full w-full flex flex-col items-center justify-center gap-2">
                    <SearchIcon className="!size-10" />
                    Search Oditi for
                    {' '}
                    {option}
                    !
                </span>
                )}

                {(loading) && (
                    <section className="flex justify-center items-center old-py-5 old-mt-section-margin">
                        <PulseLoader loading speedMultiplier={0.5} size={15} />
                    </section>
                )}
            </div>
        </div>
    );
}
OffcanvasSearch.propTypes = {
    collapse: PropTypes.string.isRequired,
    handleToggleCurrentPage: PropTypes.func.isRequired,
};
