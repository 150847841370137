import { userListPropTypes, UserBadge } from 'features/user';
import { organisationListPropTypes } from 'features/organisation';
import { Shimmer } from 'ui';
import { twMerge } from 'tailwind-merge';
import ProfileIcon from 'features/profile/components/icon/ProfileIcon';
import PropTypes from 'prop-types';

function ProfileDataCardLoading() {
    return (
        <div className="old-rounded-4 old-bg-white old-p-2">
            <div className="old-d-flex old-align-items-center">
                <Shimmer className="old-ratio old-ratio-1x1 !size-16 old-rounded-circle" />

                <div className="old-mx-3 old-w-50">
                    <Shimmer className="old-w-100 old-p-2 old-rounded old-h-25" />
                    <Shimmer className="old-w-50 old-p-2 old-mt-2 old-rounded old-h-25" />
                </div>
            </div>
        </div>
    );
}

function ProfileDataCard({
    profile,
    name,
    icon = '',
    profileType = '',
    className = '',
}) {
    return (
        <div className={twMerge('flex items-center p-2 bg-content1', className)}>
            <ProfileIcon
                icon={icon}
                className="!size-12"
            />

            <div className="flex flex-col ml-4">
                <div className="flex items-center flex-wrap overflow-hidden h-7">
                    <span className="uppercase text-lg">{name}</span>
                    <UserBadge className="!size-5 ml-1" profile={profile} />
                </div>

                {(profileType !== '') && (
                    <small className="font-roman text-xs">{profileType}</small>
                )}
            </div>
        </div>
    );
}

ProfileDataCard.propTypes = {
    profile: PropTypes.oneOfType([
        userListPropTypes,
        organisationListPropTypes,
    ]).isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    profileType: PropTypes.string,
    className: PropTypes.string,
};

ProfileDataCard.Loading = ProfileDataCardLoading;
export default ProfileDataCard;
