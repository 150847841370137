import { OverlayTrigger, Tooltip } from 'ui';
import PropTypes from 'prop-types';

export default function SubmissionBadge({ tooltipText, icon, className = 'old-bg-gold old-text-white old-shine-horizontal' }) {
    return (
        <OverlayTrigger
            id="winner-badge-tooltip"
            placement="bottom"
            overlay={(
                <Tooltip>
                    <div className="old-bg-white old-mt-1 old-px-2 old-py-1 old-rounded-pill old-shadow-xl">
                        <small className="font-roman old-fs-10">{tooltipText}</small>
                    </div>
                </Tooltip>
            )}
        >
            <span className={`old-p-3 old-rounded-circle old-shadow ${className}`}>
                {icon}
            </span>
        </OverlayTrigger>
    );
}
SubmissionBadge.propTypes = {
    tooltipText: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    className: PropTypes.string,
};
