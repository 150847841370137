import { useContext } from 'react';
import { generatePath } from 'utils/helpers';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'features/user';
import { NavigationConfigContext } from 'features/navigation';
import { StickyActionButton, StickyActionShareButton } from 'ui';
import routes from 'default/routes';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import ReviewsOutlinedIcon from '@mui/icons-material/ReviewsOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import PropTypes from 'prop-types';
import submissionPropTypes from 'features/submission/schemas/submissionSchema';
import SubmissionStickyActionVoteButton from 'features/submission/components/button/SubmissionStickyActionVoteButton';

const trackingLocation = 'submission actions';

export default function SubmissionActions({
    submission,
    handleVote,
    scrollToFeedback,
    scrollToVoters,
    allowSelectWinner = false,
    showVoteButton = true,
}) {
    const { config } = useContext(NavigationConfigContext);
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    return (
        <div className="w-14 -mr-14 hidden md:block sticky top-32 h-full text-primary-foreground">
            <div className="ml-4 ">
                {(showVoteButton) && (
                    <SubmissionStickyActionVoteButton
                        handleVote={handleVote}
                        hasVoted={submission.voted}
                        numberVotes={submission.votes}
                        trackingName="vote"
                        trackingLocation={trackingLocation}
                        allowSelectWinner={allowSelectWinner}
                    />
                )}

                <StickyActionButton
                    onClick={() => navigate(`${generatePath(config.redirectDefault, { wildcard: generatePath(routes.brief, { id: submission.brief.id }) })}?tab=details`)}
                    name="View Brief"
                    tooltip={submission.brief.title}
                    icon={FeedOutlinedIcon}
                    trackingName="view brief"
                    trackingLocation={trackingLocation}
                />

                <StickyActionShareButton
                    title={`${submission.title} - entry to ${submission.brief.title} - by ${submission.user.username}`}
                    url={window.location.pathname}
                    trackingLocation={trackingLocation}
                />

                {/*
                <StickyActionButton
                    name='Save'
                    tooltip='Add To Collection'
                    icon={BookmarkBorderOutlinedIcon}
                /> */}

                {(submission.votes > 0 && user.id === submission.user.id) && (
                    <StickyActionButton
                        onClick={scrollToVoters}
                        name={(
                            <>
                                <span className="old-d-block old-mt-2">View</span>
                                <span className="old-d-block">Voters</span>
                            </>
                        )}
                        tooltip="View Your Voters"
                        icon={VerifiedOutlinedIcon}
                        trackingName="view voters"
                        trackingLocation={trackingLocation}
                    />
                )}

                {(submission.user.id !== user.id) && (
                    <StickyActionButton
                        onClick={scrollToFeedback}
                        name={(
                            <span className="old-d-block">Feedback</span>
                        )}
                        tooltip="Share Your Feedback"
                        icon={ReviewsOutlinedIcon}
                        trackingName="view feedback"
                        trackingLocation={trackingLocation}
                    />
                )}
            </div>
        </div>
    );
}

SubmissionActions.propTypes = {
    submission: submissionPropTypes.isRequired,
    handleVote: PropTypes.func.isRequired,
    scrollToFeedback: PropTypes.func.isRequired,
    scrollToVoters: PropTypes.func.isRequired,
    allowSelectWinner: PropTypes.bool,
    showVoteButton: PropTypes.bool,
};
