import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { NavigationConfigContext } from 'features/navigation';
import { Section, Button } from 'ui';
import ErrorContext from 'features/report/context/ErrorContext';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PropTypes from 'prop-types';

const defaultText = 'Bummer. \n The requested page could not be found.';

export default function NotFound({
    text = defaultText,
    issuePage = '',
}) {
    const { config } = useContext(NavigationConfigContext);
    const { showError } = useContext(ErrorContext);

    return (
        <Section className="old-full-size old-w-100 old-d-flex old-align-items-center old-justify-content-center old-flex-column old-p-1 text-center">
            <h3 className="old-description">{text}</h3>

            <div className="old-d-flex old-w-100 old-justify-content-center gap-4">
                <Button
                    startContent={<ErrorOutlineIcon />}
                    onClick={() => showError({
                        defaultReason: 'issue',
                        defaultPage: issuePage,
                    })}
                    size="lg"
                    variant="faded"
                >
                    Report An Issue
                </Button>

                <Button
                    as={Link}
                    to={config.redirectHome}
                    trackingName="view home"
                    color="success"
                    startContent={<HomeOutlinedIcon />}
                    size="lg"
                >
                    Go Home
                </Button>
            </div>
        </Section>
    );
}

NotFound.propTypes = {
    text: PropTypes.string,
    issuePage: PropTypes.string,
};
