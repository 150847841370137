// CSS
import 'css/default.min.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datepicker/dist/react-datepicker.css';

// React
import { useState, useEffect, useMemo } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { UserContext } from 'features/user';
import { GlobalAuth } from 'features/authentication';
import { GlobalError } from 'features/report';
import { NavigationConfigContext } from 'features/navigation';
import { ToastContainer } from 'react-toastify';
import { Section, ThemeProvider } from 'ui';
import { createRoutes } from 'config';
import { QueryProvider } from 'api';
import api, { GCSApi } from 'adapters/api';
import config, { browserRoutes } from 'default/config';
import SuperTokens from 'adapters/supertokens';
import Mixpanel from 'adapters/mixpanel';
import Sentry from 'adapters/sentry';
import rotatingP100 from 'images/rotating-p100.webm';
import configureOSFont from 'utils/fonts';
import BootstrapThemeProvider from 'react-bootstrap/ThemeProvider';
import bootstrapPrefixes from 'bootstrapPrefixes';

// Initialize adapters
SuperTokens.init({
    overrideAuthentication: {
        getRedirectionURL: async (context) => {
            if (context.action !== 'SUCCESS')
                return undefined;

            if (context.isNewUser) {
                // After they have completed onboarding we want to redirect back to where they came from
                const redirect = new URL(context.redirectToPath, document.baseURI);
                redirect.searchParams.append('completeOnboarding', true);
                return redirect.href;
            }
            return context.redirectToPath;
        },
    },
});
api.init();
GCSApi.init();
Sentry.init();

configureOSFont();

const router = Sentry.createBrowserRouter([
    // TODO: refactor with nested routes
    ...createRoutes(browserRoutes),
    ...SuperTokens.getSuperTokensRoutes(),
], {
    future: {
        v7_normalizeFormMethod: true,
    },
});

export default function App() {
    const [userContext, setUserContext] = useState({ doesSessionExist: false });
    const [configContext, setConfigContext] = useState(config);
    const [loading, setLoading] = useState(true);
    const sessionContext = useSessionContext();

    const userContextProviderValue = useMemo(() => ({ user: userContext, setUser: (val) => { setUserContext({ ...val, doesSessionExist: sessionContext.doesSessionExist }); } }), [userContext, sessionContext]);
    const configContextProviderValue = useMemo(() => ({ config: configContext, setConfig: (conf) => { setConfigContext(conf); } }), [configContext]);

    useEffect(() => {
        // TODO: we want to load the rest of the page whilst waiting for access token to be acquired
        if (sessionContext.doesSessionExist === undefined)
            return;
        if (!sessionContext.doesSessionExist) {
            setLoading(false);
            return;
        }

        const fetchUser = async () => {
            const res = await api.get({ url: 'users/me' });

            if (res.success) {
                /**
                 * User context definitions:
                 * - We can use supertokens @param sessionContext.doesSessionExist when we dont need access to the user object.
                 * - We use @param userContext.doesSessionExist when we need access to the user object. This takes an extra state refresh to be set (< 100ms).
                 *
                 * You should default to using the userContext unless you need access to doesSessionExist immediately after load,
                 * and dont need the user context.
                 */
                setUserContext({
                    ...res.data,
                    doesSessionExist: sessionContext.doesSessionExist,
                });
                Sentry.setUser({
                    id: res.data.id,
                    email: res.data.email, // We only send the email as usernames can change
                });
                Mixpanel.identify(res.data.auth0); // link anonymous user to authenticated user
            }

            setLoading(false);
        };

        fetchUser();
    }, [sessionContext.doesSessionExist]);

    if (window.location.hostname === 'dev.prospect100.com')
        window.location.replace(`https://dev.oditi.com${window.location.pathname}`);
    if (window.location.hostname === 'prospect100.com')
        window.location.replace(`https://oditi.com${window.location.pathname}`);

    if (loading) {
        return (
            <Section className="old-full-size old-w-100 old-d-flex old-align-items-center old-justify-content-center old-flex-column old-p-1 text-center">
                <video autoPlay loop muted playsInline preload="auto" width={100} className="old-mx-auto">
                    <source src={rotatingP100} type="video/webm" />
                    Loading Oditi...
                </video>
            </Section>
        );
    }

    return (
        // edit the theme, default font etc here
        <>
            <ThemeProvider>
                <BootstrapThemeProvider prefixes={bootstrapPrefixes}>
                    <UserContext.Provider value={userContextProviderValue}>
                        <NavigationConfigContext.Provider value={configContextProviderValue}>
                            <GlobalAuth>
                                <GlobalError>
                                    <QueryProvider>
                                        <RouterProvider router={router} />
                                    </QueryProvider>
                                </GlobalError>
                            </GlobalAuth>
                        </NavigationConfigContext.Provider>
                    </UserContext.Provider>
                </BootstrapThemeProvider>
            </ThemeProvider>

            <ToastContainer
                toastClassName="old-rounded-3 old-shadow-lg"
                bodyClassName="text-center"
                position="top-center"
                autoClose={false}
                closeOnClick
                hideProgressBar
            />
        </>
    );
}
