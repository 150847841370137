import { useContext } from 'react';
import { tv } from 'tailwind-variants';
import NavigationConfigContext from 'features/navigation/context/NavigationConfigContext';
import PropTypes from 'prop-types';

export default function NavbarSpacer({ size = undefined }) {
    const { config } = useContext(NavigationConfigContext);

    const navbar = tv({
        variants: {
            size: {
                none: 'hidden',
                sm: `w-full h-16 ${config.navbar.collapse}:h-20`,
                lg: `w-full h-16 ${config.navbar.collapse}:h-20 mb-2`,
            },
        },
        defaultVariants: {
            size: 'sm',
        },
    });

    return <div className={navbar({ size })} />;
}
NavbarSpacer.propTypes = {
    size: PropTypes.string,
};
