import { useNavigate, generatePath } from 'react-router-dom';
import { useMemo } from 'react';
import { ImageCard, Badge, ModalClickContainer } from 'ui';
import { ProfileIcon } from 'features/profile';
import { briefListPropTypes } from 'features/brief/schemas/briefSchema';
import { briefStatusContent } from 'features/brief/data/briefData';
import PropTypes from 'prop-types';
import BriefNotInterestedModal from 'features/brief/components/modal/BriefNotInterestedModal';
import CloseIcon from '@mui/icons-material/Close';
import Mixpanel from 'adapters/mixpanel';
import routes from 'default/routes';

function BriefStatusBadge({ brief }) {
    const countdown = useMemo(() => {
        const { reminder } = briefStatusContent(brief)[brief.status];
        return reminder;
    }, [brief]);

    if (brief.status === 'completed') {
        return (
            <Badge className={`old-d-inline-block old-py-2 old-px-3 uppercase old-d-flex old-align-items-center old-fs-14 ${brief.submitted ? 'old-shine-horizontal old-text-white' : 'old-text-offdark'} ${brief.submitted ? 'old-bg-gold' : 'old-bg-white'}`}>
                {brief.submitted ? 'Completed' : 'Submit your design!'}
            </Badge>
        );
    }
    if (countdown) {
        return (
            <Badge
                className="old-bg-danger old-d-inline-block old-py-2 old-px-3 uppercase old-text-white old-d-flex old-align-items-center old-fs-14 old-shake"
            >
                {countdown}
            </Badge>
        );
    }
    if (brief.status !== 'scheduled' || brief.status !== 'completed') {
        return (
            <Badge className="old-bg-danger old-d-inline-block old-py-2 old-px-3 uppercase old-text-white old-d-flex old-align-items-center old-fs-14 old-pulse-shadow">
                Live
            </Badge>
        );
    }
}
BriefStatusBadge.propTypes = {
    brief: briefListPropTypes.isRequired,
};

function Overlay({
    brief = {},
}) {
    return (
        <div className="old-hover-overlay old-bg-dark old-bg-opacity-25 old-w-100 old-h-100">
            {brief.user_submission_count <= 0 && (
                <div className="old-overlay-fade-top old-position-absolute old-h-100 old-top-0 old-w-100 old-pe-3 old-pt-3 old-d-none old-d-lg-flex old-justify-content-end">
                    <div role="button" tabIndex={0} onClick={(e) => e.stopPropagation()} className="old-z-1">
                        <ModalClickContainer
                            modal={BriefNotInterestedModal}
                            brief={brief}
                        >
                            <CloseIcon
                                className="!size-8 sm:!size-10 old-bg-offwhite old-text-dark old-p-2 old-rounded-4"
                            />
                        </ModalClickContainer>
                    </div>
                </div>
            )}
            <div className="old-position-absolute old-h-100 old-w-100 old-top-0 old-pt-3 old-px-3">
                <div className="old-d-flex old-justify-content-between old-align-items-top">
                    <ProfileIcon
                        icon={brief.organisation.icon}
                        className="!size-10 sm:!size-12"
                    />
                    {brief.user_submission_count > 0 ? (
                        <div>
                            <Badge className="old-d-inline-block old-p-2 old-text-white old-d-flex old-align-items-center old-fs-16 capitalize old-bg-opacity-50 old-bg-dark">
                                {`${brief.user_submission_count} ${brief.user_submission_count === 1 ? 'submission' : 'submissions'}`}
                            </Badge>
                        </div>
                    ) : (
                        <div role="button" tabIndex={0} onClick={(e) => e.stopPropagation()} className="old-d-lg-none">
                            <ModalClickContainer
                                modal={BriefNotInterestedModal}
                                brief={brief}
                            >
                                <CloseIcon
                                    className="!size-8 sm:!size-10 old-border-2 old-bg-offwhite old-text-dark old-p-2 old-rounded-4"
                                />
                            </ModalClickContainer>
                        </div>
                    )}
                </div>
            </div>

            <div className="old-position-absolute old-bottom-0 old-d-flex old-flex-wrap old-align-items-center old-justify-content-end old-w-100 old-px-3 old-gap-2 old-pb-3">
                <BriefStatusBadge brief={brief} />
            </div>
        </div>
    );
}
Overlay.propTypes = {
    brief: briefListPropTypes,
};

export default function SmallBriefCard({
    entry: brief = {},
    onClick = undefined,
    blank = false,
    trackingName = 'view brief',
    trackingLocation = '',
    ...optionsProps
}) {
    const navigate = useNavigate();
    const navigateOnClick = () => {
        if (onClick) {
            onClick(brief);
            return;
        }
        navigate(generatePath(routes.brief, { id: brief.id }));
    };

    const handleTrackOnClick = () => {
        Mixpanel.trackButton('Card', trackingName, trackingLocation);
        navigateOnClick();
    };

    return (
        <ImageCard
            name="brief"
            className="w-64 min-w-56"
            blank={!brief || blank}
            image={brief?.thumbnail || ''}
            onClick={handleTrackOnClick}
            overlay={Overlay}
            overlayProps={{
                brief,
                ...optionsProps,
            }}
        />
    );
}
SmallBriefCard.propTypes = {
    entry: briefListPropTypes,
    onClick: PropTypes.func,
    blank: PropTypes.bool,
    trackingName: PropTypes.string,
    trackingLocation: PropTypes.string,
};
