const queryFactory = (baseURL) => {
    const baseQuery = { url: baseURL };

    return {
        all: () => [baseQuery],
        lists: () => [{ ...baseQuery, view: 'list' }],
        list: (params) => [{ ...baseQuery, view: 'list', params }],
        details: () => [{ ...baseQuery, view: 'detail' }],
        detail: (id) => [{ ...baseQuery, view: 'detail', id: id == null ? id : String(id) }],
    };
};

export default queryFactory;
