import { useCallback } from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from 'ui/buttons/IconButton';

function CompletedStep({
    step,
    onClick = undefined,
}) {
    const stepForButton = useCallback(({ className: stepClassName }) => <div className={`old-min-w-100 old-min-h-100 ${stepClassName}`}>{step}</div>, [step]);

    if (!onClick)
        return <CheckIcon />;

    return (
        <IconButton
            hoverColor
            variant="white"
            className="old-rounded-circle old-p-1"
            iconClassName="old-d-flex old-align-items-center old-justify-content-center old-p-2"
            hoverPulse={false}
            icon={[CheckIcon, stepForButton]}
            direction="prev"
            onClick={onClick}
            aria-label={`step-${step}`}
        />
    );
}
CompletedStep.propTypes = {
    step: PropTypes.number.isRequired,
    onClick: PropTypes.func,
};

function CurrentStep({ currentStep, step, onClick = undefined }) {
    if (!onClick || currentStep !== step)
        return step;

    return (
        <div
            onClick={onClick}
            role="button"
            tabIndex={0}
            className="old-w-100 old-h-100 old-d-flex old-align-items-center old-justify-content-center"
            aria-label={`step-${step}`}
        >
            {step}
        </div>
    );
}
CurrentStep.propTypes = {
    currentStep: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    onClick: PropTypes.func,
};

export default function ProgressStep({
    title,
    step,
    currentStep,
    first = false,
    last = false,
    className = 'old-bg-lightgrey old-text-white',
    completedClassName = 'old-bg-black old-text-white',
    onClick = undefined,
}) {
    const completed = currentStep > step;
    const stepClassName = currentStep === step ? 'old-bg-white old-text-black old-border old-border-black old-border-2' : className;

    return (
        <div className="old-d-flex old-align-items-center old-flex-column old-w-100">
            <div className="old-d-flex old-align-items-center old-justify-content-center old-w-100 old-position-relative old-overflow-hidden old-mb-2">
                <span className={`old-p-1 old-position-absolute old-w-${first || last ? '50' : '100'} old-start-${first ? '50' : '0'} ${currentStep - 1 === step ? 'old-rounded-end-4' : ''} ${completed ? completedClassName : className}`} />
                <div className="old-px-2 old-z-1">
                    <span className={`old-rounded-circle !size-10 md:!size-12 old-d-flex old-align-items-center old-justify-content-center old-transition-slide-arrow ${completed ? completedClassName : stepClassName}`}>
                        {(completed) ? (
                            <CompletedStep step={step} onClick={onClick} />
                        ) : (
                            <CurrentStep currentStep={currentStep} step={step} onClick={onClick} />
                        )}
                    </span>
                </div>
            </div>
            <span className="uppercase font-bold old-mb-0 old-fs-10 old-fs-sm-14 old-fs-md-18 text-center">{title}</span>
        </div>
    );
}
ProgressStep.propTypes = {
    title: PropTypes.string.isRequired,
    step: PropTypes.number.isRequired,
    currentStep: PropTypes.number.isRequired,
    first: PropTypes.bool,
    last: PropTypes.bool,
    className: PropTypes.string,
    completedClassName: PropTypes.string,
    onClick: PropTypes.func,
};
