import {
    Modal as NextModal, ModalContent, ModalBody, ModalFooter, ModalHeader,
} from '@nextui-org/react';
import ModalActionsFooter from 'ui/modals/ModalActionsFooter';
import PropTypes from 'prop-types';

function Modal({ children, ...props }) {
    return (
        <NextModal {...props}>
            <ModalContent>
                {children}
            </ModalContent>
        </NextModal>
    );
}
Modal.propTypes = {
    children: PropTypes.node.isRequired,
};

Modal.ModalBody = ModalBody;
Modal.ModalFooter = ModalFooter;
Modal.ModalHeader = ModalHeader;
Modal.ModalActionsFooter = ModalActionsFooter;
export default Modal;
