import { useReducer } from 'react';

export const reducer = (state, action) => {
    const newState = { ...state }; // Shallow clone of state for immutability at the first level
    // console.log("reducer ------> ", state, action)

    if (action.oneOrTheOther) {
        const prev = Object.keys(newState).find((k) => newState[k].checked);
        if (prev)
            newState[prev] = { ...newState[prev], checked: false }; // Clone object when changing
    }

    if (action.type === 'RESET')
        return action.payload ?? {};

    let checked;
    switch (action.type) {
        case 'CHECKED':
            checked = true;
            break;
        case 'UNCHECKED':
            checked = false;
            break;
        case 'TOGGLE':
            checked = !state[action.id].checked;
            break;
        default:
            // Handle default as merging payload into state if needed, without cloning again
            return { ...newState, ...action.payload };
    }

    // Only clone the specific item when it's necessary to update
    newState[action.id] = { ...newState[action.id], checked };
    // console.log("reducer ------< ", newState)

    return newState;
};

/**
 * @function {hook}:    useToggleButtons
 *
 * @param {Obj} defaultValue:   A mapping of key, value pairs that define the toggle buttons of the format:
 *                              {[key]: {
 *                                  checked: Bool - Is the button checked?
 *                                  label: String - Button display label
 *                                  text: String  - Button display long text
 *                              }}
 * @return [state, dispatch]:   useReducer
 */
export default function useToggleButtons(defaultValue) {
    return useReducer(reducer, defaultValue);
}
