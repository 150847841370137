import 'css/index.css';
import { SuperTokensWrapper } from 'supertokens-auth-react';
import { NextUIProvider } from '@nextui-org/react';
import React from 'react';
import ReactDOM from 'react-dom/client';

const App = (process.env.REACT_APP_TYPE === 'org') ? require('./apps/org/App').default : require('./apps/default/App').default;

const root = ReactDOM.createRoot(document.getElementById('root'));

const content = (
    <SuperTokensWrapper>
        <NextUIProvider>
            <App />
        </NextUIProvider>
    </SuperTokensWrapper>
);

if (!process.env.REACT_APP_STRICT_MODE || process.env.REACT_APP_STRICT_MODE === 'false') {
    root.render(content);
} else {
    root.render(
        <React.StrictMode>
            {content}
        </React.StrictMode>,
    );
}
