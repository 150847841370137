import Modal from 'ui/modals/Modal';
import useWindowSize from 'hooks/useWindowSize';
import PropTypes from 'prop-types';

const BREAKPOINT = 768;

export default function Drawer({
    className = '',
    children,
    ...props
}) {
    const [width] = useWindowSize();

    return (
        <Modal
            scrollBehavior="inside"
            placement="center"
            backdrop="transparent"
            size="full"
            classNames={{
                base: 'w-full md:max-w-sm w-full h-screen max-h-screen !shadow-none !rounded-s-large !shadow-2xl bg-content2 text-foreground',
                wrapper: 'flex justify-end old-z-4',
            }}
            hideCloseButton
            isDismissable={!(width < BREAKPOINT)}
            motionProps={{
                variants: {
                    enter: {
                        x: 0,
                        opacity: 1,
                        transition: {
                            delay: 0,
                            duration: 0.3,
                            ease: 'easeOut',
                        },
                    },
                    exit: {
                        x: width < BREAKPOINT ? '100vw' : '25rem',
                        opacity: 1,
                        transition: {
                            delay: 0,
                            duration: 0.3,
                            ease: 'easeIn',
                        },
                    },
                },
            }}
            data-testid="drawer"
            {...props}
        >
            <Modal.ModalBody className={className}>{children}</Modal.ModalBody>
        </Modal>
    );
}
Drawer.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};
