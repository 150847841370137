import { useEffect, useCallback, useState } from 'react';

export default function useScrollTabs(elements = [], dependencies = []) {
    const [fixed, setFixed] = useState(false);

    const handleResize = useCallback((entries) => {
        const isFixed = entries.every((entry) => entry.target.clientHeight <= window.innerHeight);

        entries.forEach((entry) => {
            if (!entry.target)
                return;

            entry.target.parentElement.style.setProperty('position', 'sticky', 'important');

            if (!isFixed) {
                const offset = entry.target.parentElement.offsetHeight - window.innerHeight;
                entry.target.parentElement.style.setProperty('top', `${(offset > 0) ? -offset : 0}px`, 'important');
            } else {
                entry.target.parentElement.style.setProperty('top', '0', 'important');
            }
        });

        setFixed(isFixed);
    }, []);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(handleResize);

        elements.forEach((element) => {
            resizeObserver.observe(element.current);
        });

        return () => {
            resizeObserver.disconnect();
        };
    }, dependencies);

    return fixed;
}
