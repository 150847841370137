import { useMemo, useContext } from 'react';
import routes from 'default/routes';
import { useNavigate, useLocation, generatePath } from 'react-router-dom';
import { submissionListPropTypes } from 'features/submission/schemas/submissionSchema';
import { ProfileIcon } from 'features/profile';
import { UserContext } from 'features/user';
import { DocumentMetaContext } from 'features/navigation';
import { ImageCard } from 'ui';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import PropTypes from 'prop-types';
import SubmissionOptionsDropdown from 'features/submission/components/dropdown/SubmissionOptionsDropdown';
import SubmissionBadge from 'features/submission/components/badge/SubmissionBadge';

const submissionBadgeContent = (submission, isOrg = false) => ({
    get proposal() {
        if (!submission.published || submission.brief.status === 'proposing')
            return {};
        if (submission.brief.status === 'selecting' && (!isOrg || (isOrg && !submission.is_winner))) {
            return {
                badgeText: 'Pending',
                badgeIcon: <HourglassBottomIcon data-testid="submission-badge-pending" />,
                badgeClassName: 'old-bg-black old-text-white',
            };
        }
        if (!submission.is_winner) {
            return {
                badgeText: 'Proposal Not Selected',
                badgeIcon: <CloseIcon data-testid="submission-badge-not-approved" />,
                badgeClassName: 'old-bg-danger',
            };
        }
        return {
            badgeText: 'Proposal Selected',
            badgeIcon: <DoneIcon data-testid="submission-badge-approved" />,
            badgeClassName: 'old-bg-info',
        };
    },
    get submission() {
        if (!submission.published || submission.brief.status !== 'completed' || !submission.is_winner)
            return {};
        return {
            badgeText: 'Brief Winner',
            badgeIcon: <EmojiEventsIcon data-testid="submission-badge-winner" />,
        };
    },
});

function Overlay({
    submission,
    ...optionsProps
}) {
    const { user } = useContext(UserContext);
    const { badgeText, badgeIcon, badgeClassName } = useMemo(() => submissionBadgeContent(submission, user.organisation?.id)[submission.type], [submission]);

    return (
        <div className="old-hover-overlay old-w-100 old-h-100">
            <div className="old-position-absolute old-h-100 old-top-0 old-w-100 old-ps-3 old-pt-3 old-overlay-fade-top old-d-inline-block">
                <div className="old-d-flex old-align-items-center">
                    <ProfileIcon
                        icon={submission.user.icon}
                        className="!size-8 sm:!size-12"
                    />
                    <span className="old-ms-2 old-fs-5 old-text-white old-ps-1 uppercase">{submission.user.username}</span>
                </div>
            </div>

            {(badgeText && badgeIcon) && (
                <div className="old-position-absolute old-top-0 old-w-100 old-mt-n1 old-ms-1 old-pe-3 old-pt-3 old-d-flex old-justify-content-end">
                    <SubmissionBadge tooltipText={badgeText} icon={badgeIcon} className={badgeClassName} />
                </div>
            )}

            <div className="old-position-absolute old-bottom-0 old-d-flex old-flex-wrap old-align-items-center old-justify-content-end old-w-100 old-px-3 old-gap-2 old-pb-3">
                <div className="old-d-flex old-align-items-center old-mw-75 old-gap-2">
                    <span className="old-ml-auto old-text-black old-bg-white old-p-1 old-px-2 old-rounded-pill old-shadow old-text-truncate">{submission.title || `"${submission.brief.title}" Submission`}</span>

                    {(!submission.published) && (
                        <span className="old-bg-purple old-text-white old-p-1 old-px-2 old-rounded-pill">Draft</span>
                    )}

                    {(submission.type === 'proposal') && (
                        <span className="old-bg-purple old-text-white old-p-1 old-px-2 old-rounded-pill">Proposal</span>
                    )}
                </div>

                <SubmissionOptionsDropdown submission={submission} {...optionsProps} />
            </div>
        </div>
    );
}

Overlay.propTypes = {
    submission: submissionListPropTypes.isRequired,
};

export default function SubmissionCard({
    entry: submission,
    onClick = undefined,
    blank = false,
    ...overlayProps
}) {
    const navigate = useNavigate();
    const location = useLocation();
    const documentMetaContext = useContext(DocumentMetaContext);

    const navigateOnClick = () => {
        if (onClick) {
            onClick(submission);
            return;
        }

        const current = documentMetaContext?.currentRoute || 'voting';
        navigate({
            pathname: generatePath(routes[`${current}Submission`], { username: submission.user.username, submissionId: submission.id }),
            search: location.search,
        }, {
            state: { scrollToTop: false },
        });
    };

    return (
        <ImageCard
            name="submission"
            blank={!submission || blank}
            image={submission?.thumbnail || ''}
            onClick={navigateOnClick}
            overlay={Overlay}
            overlayProps={{
                submission,
                ...overlayProps,
            }}
        />
    );
}

SubmissionCard.propTypes = {
    entry: submissionListPropTypes.isRequired,
    onClick: PropTypes.func,
    blank: PropTypes.bool,
};
