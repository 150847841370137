import { Link } from 'react-router-dom';
import { ProfileIcon } from 'features/profile';
import Badge from 'ui/status/Badge';
import PropTypes from 'prop-types';

export default function CardBody({
    userIcon = '',
    username = '',
    userPath = '',
    title = '',
    subTitle = '',
    // tags = [],
    status = '',
    isCompleted = false,
}) {
    return (
        <div className="old-p-3 old-pb-0 old-all-mb-3 old-all-mb-md-5 old-flex-grow-1 old-d-flex old-flex-column" data-testid="image-card-body">
            <div className="old-d-flex old-justify-content-between old-align-items-center">
                <Link
                    to={userPath}
                    className={`old-d-flex old-align-items-start old-align-items-center ${!userPath ? 'old-pe-none' : ''}`}
                    data-testid="image-card-profile"
                    onClick={(e) => { e.stopPropagation(); }}
                >
                    <ProfileIcon
                        icon={userIcon}
                        className="!size-8 sm:!size-10"
                        color="border"
                    />
                    <span className="old-ms-2 old-transition-underline old-fs-5 old-description-1">{username}</span>
                </Link>

                {(['submitting', 'voting', 'judging'].includes(status)) && (
                    <Badge className="old-bg-danger old-d-inline-block old-mb-2 old-py-2 old-px-3 uppercase old-text-white old-d-flex old-align-items-center old-fs-14 old-pulse-shadow">
                        Live
                    </Badge>
                )}

                {(isCompleted) && (
                    <Badge className="old-bg-gold old-d-inline-block old-mb-2 old-py-2 old-px-3 uppercase old-text-white old-d-flex old-align-items-center old-fs-14 old-shine-horizontal">
                        Completed
                    </Badge>
                )}
            </div>

            {subTitle && (
                <small className="old-description-1 font-bold old-fs-12 old-mb-3">{subTitle}</small>
            )}

            <p className="font-bold old-py-md-0 old-py-1 old-fs-5 old-mb-0 old-description-2 old-flex-grow-1 old-mb-3 old-mb-md-5">{title}</p>

            {/* <div className="old-gap-2 old-d-flex old-w-100 old-flex-wrap old-mb-3">
                {tags.map((tag) => (
                    <Badge className="old-d-inline-block old-p-2 old-rounded-4 font-bold capitalize old-text-white old-d-flex old-align-items-center" bg={tag.bg}>{tag.text}</Badge>
                ))}
            </div> */}
        </div>
    );
}
CardBody.propTypes = {
    userIcon: PropTypes.string,
    username: PropTypes.string,
    userPath: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    status: PropTypes.string,
    isCompleted: PropTypes.bool,
};
