const supertokensTranslations = {
    en: {
        EMAIL_PASSWORD_SIGN_IN_HEADER_SUBTITLE_SIGN_UP_LINK: 'Create New Account',
        EMAIL_PASSWORD_SIGN_UP_HEADER_TITLE: 'Create New Account',
        EMAIL_PASSWORD_SIGN_UP_SUBMIT_BTN: 'Create New Account',
        THIRD_PARTY_PASSWORDLESS_SIGN_IN_AND_UP_HEADER_TITLE: 'Oditi',
        PWLESS_USER_INPUT_CODE_HEADER_TITLE: 'Enter Code',
        PWLESS_USER_INPUT_CODE_HEADER_SUBTITLE: 'A secret code was sent to you at',
        PWLESS_USER_INPUT_CODE_INPUT_LABEL: 'Code',
        GENERAL_ERROR_OTP_UNDEFINED: 'Please enter your code',
        GENERAL_ERROR_OTP_INVALID: 'Invalid Code',
        GENERAL_ERROR_OTP_EXPIRED: 'Expired Code',
        GENERAL_ERROR_OTP_NON_STRING: 'Code must be of type string',
        GENERAL_ERROR_OTP_EMPTY: 'Code cannot be empty',
    },
};

export default supertokensTranslations;
