import { useEffect } from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import { SubmissionCard as DefaultSubmissionCard, withViewCounter, useSubmissions } from 'features/submission';
import { briefPropTypes } from 'features/brief';
import { Container, CardGrid, AutoScrollLoader } from 'ui';
import routes from 'default/routes';
import PropTypes from 'prop-types';

const SubmissionCard = withViewCounter(DefaultSubmissionCard);

export default function BriefSubmissions({ brief, setFinalPage }) {
    const navigate = useNavigate();

    const submissions = useSubmissions({
        brief_id: brief.id,
        published: true,
        sort: 'trending',
        winners: (brief.status === 'completed') ? false : null,
    });

    const { all: winners } = useSubmissions({
        brief_id: brief.id,
        winners: true,
        page_size: 100,
    });

    useEffect(() => setFinalPage(!submissions.hasNextPage), [submissions.hasNextPage]);

    return (
        <div className="bg-content1 old-rounded-top-4 old-position-relative old-sb-6" data-testid="brief-submissions">
            <Container className="old-pt-3 old-pt-md-6 old-rounded-top-4 old-mt-n6">
                <div className="old-mb-5">
                    <CardGrid
                        entries={[...winners, ...submissions.all]}
                        card={SubmissionCard}
                        onClick={(submission) => navigate(`${generatePath(routes.briefSubmission, { id: brief.id, submissionId: submission.id })}?tab=submissions`, {
                            state: {
                                scrollToTop: false,
                            },
                        })}
                        showBriefTitle={false}
                    />
                </div>

                <AutoScrollLoader
                    fetchNextPage={submissions.fetchNextPage}
                    hasNextPage={submissions.hasNextPage}
                    isLoading={submissions.isLoading || submissions.isFetchingNextPage}
                    error={submissions.error}
                    empty={!submissions.all.length}
                    className="old-my-6"
                >
                    There are no submissions here at the moment.
                    <br />
                    {' '}
                    Please come back at a later date.
                </AutoScrollLoader>
            </Container>
        </div>
    );
}
BriefSubmissions.propTypes = {
    brief: briefPropTypes.isRequired,
    setFinalPage: PropTypes.func.isRequired,
};
