// Config shared between all apps

// Routes
import { AuthModal } from 'features/authentication';
import { ErrorBoundary, NotFound } from 'features/report';
import routes from 'routes';

export const browserRoutes = {
    notFound: {
        path: routes.notFound,
        element: <NotFound />,
    },
    auth: {
        path: routes.auth,
        element: <AuthModal show />,
    },
};

export const createRoutes = (r) => {
    const finalRoutes = [];

    Object.values(r).forEach((route) => {
        finalRoutes.push({
            ...route,
            ...(route.children && { children: createRoutes(route.children) }),
            errorElement: route.errorElement || <ErrorBoundary />,
        });
    });

    return finalRoutes;
};
