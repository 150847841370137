import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import PropTypes from 'prop-types';
import api from 'adapters/api';
import { ProfileIcon } from 'features/profile';
import { useProtectedAction } from 'features/authentication';
import { UserContext } from 'features/user';
import { useSubmission } from 'features/submission';
import FormControl from 'forms/FormControl';
import { Modal } from 'ui';
import prospectlogo from 'images/prospectlogo.gif';

import feedbackPropTypes from '../feedbackSchema';

const MIN_FEEDBACK_LENGTH = 10;
export default function FeedbackPromptModal({
    show, setShow, request = undefined, submissionId,
}) {
    const onHide = () => setShow(false);
    const [feedbackText, setFeedbackText] = useState('');
    const [submitLoading, setSubmitLoading] = useState(false);
    const { data: submission, isLoading } = useSubmission(submissionId);
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    const [handleSubmitFeedback] = useProtectedAction(async (feedback, requestId = null) => {
        setSubmitLoading(true);
        const feedbackData = {
            content: feedback,
            ...(requestId && { parent: requestId }),
        };
        const res = await api.post({ url: `submissions/${submissionId}/feedback/`, data: feedbackData });
        if (res.success) {
            onHide();
            navigate(window.location.pathname, {
                replace: true,
                state: {
                    scrollToTop: false,
                    feedbackUpdate: true,
                },
            });
        }
        setSubmitLoading(false);
    });

    if (isLoading || Object.keys(submission).length === 0)
        return null;

    return (
        <Modal
            isOpen={show}
            onOpenChange={onHide}
            data-testid="feedback-prompt-modal"
        >
            <Modal.ModalBody className="p-6">
                <div className="old-d-flex old-justify-content-center">
                    {submission?.user.icon ? (
                        <ProfileIcon
                            icon={submission.user.icon}
                            className="!size-20"
                            iconClassName="old-p-2"
                            country={submission.user.country_code}
                        />
                    ) : (
                        <img src={prospectlogo} width={90} height={90} alt="Oditi Spinning Logo" />
                    )}
                </div>

                <section className="old-d-flex old-flex-column old-mt-3 text-center old-justify-content-center">
                    <span className="old-fs-2">
                        Hi
                        <span className="capitalize">{` ${user.given_name}` || ''}</span>
                        , thanks for your vote!
                    </span>
                    <span className="old-fs-6 uppercase old-text-lightgrey">One more step</span>
                </section>
                <FormControl
                    onUpdate={setFeedbackText}
                    debounceTime={10}
                    defaultValue={feedbackText}
                    placeholder={`What did you love most about ${submission?.user.username.toUpperCase()}'s design? Help them out by leaving some feedback (min. 10 characters).`}
                    name="feedbackText"
                    as="textarea"
                    rows={4}
                    wrapperClassName="old-mb-3"
                    minLength={MIN_FEEDBACK_LENGTH}
                    maxLength={600}
                />

                <Modal.ModalActionsFooter
                    onCancel={onHide}
                    onConfirm={() => handleSubmitFeedback(feedbackText, request?.id)}
                    cancelText="Skip"
                    confirmText="Send Feedback"
                    cancelProps={{
                        variant: 'light',
                    }}
                    confirmProps={{
                        endContent: <SendIcon />,
                        isDisabled: feedbackText.length < MIN_FEEDBACK_LENGTH || submitLoading,
                        isLoading: submitLoading,
                    }}
                />
            </Modal.ModalBody>
        </Modal>
    );
}

FeedbackPromptModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    request: feedbackPropTypes,
    submissionId: PropTypes.number.isRequired,
};
