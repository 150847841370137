import { useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { client } from 'api/client';

const defaultParams = { page_size: 12 };

const fetchMany = async ({ pageParam = 1, queryKey: [{ url, params }], signal }) => {
    const response = await client.get(url, {
        signal,
        params: {
            ...params,
            page: pageParam,
        },
    });
    return {
        data: response.data,
        pagination: JSON.parse(response.headers?.get('X-Pagination') || null),
    };
};

const useMany = (queryOptions) => {
    const queryKey = useMemo(() => (
        [{ ...queryOptions.queryKey[0], params: { ...defaultParams, ...queryOptions.queryKey[0].params } }]
    ), [queryOptions.queryKey]);

    const query = useInfiniteQuery({
        ...queryOptions,
        queryFn: fetchMany,
        queryKey,
        initialPageParam: queryKey[0].params?.page || 1,
        getNextPageParam: (lastPage) => lastPage?.pagination?.next_page,
    });

    const all = useMemo(() => query.data?.pages.flatMap((page) => page.data) || [], [query.data]);
    const total = useMemo(() => query.data?.pages[0]?.pagination?.total ?? all.length, [query.data]);

    return {
        ...query,
        total,
        all,
    };
};

export default useMany;
