import { useContext } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from 'api';
import { UserContext } from 'features/user';
import { ErrorToast, ErrorContext } from 'features/report';
import { useProtectedAction } from 'features/authentication';
import { submissionQueries, useSubmission } from 'features/submission';
import api from 'adapters/api';

const useSubmissionWithActions = (submissionId) => {
    const queryClient = useQueryClient();
    const { data: submission, isLoading } = useSubmission(submissionId);
    const { showError } = useContext(ErrorContext);
    const { user } = useContext(UserContext);

    const [handleVote] = useProtectedAction(async () => {
        const res = await api.put({ url: `submissions/${submissionId}/vote` });

        if (!res.success) {
            toast(<ErrorToast
                errorMessage={res.errorDetails.message}
                showErrorModal={() => showError({
                    defaultReason: 'issue',
                    defaultPage: 'submission_vote',
                    apiError: res.errorDetails,
                })}
            />, {
                limit: 1,
            });
            return;
        }

        if (res.data) {
            queryClient.setQueryData(submissionQueries.detail(submissionId), (prev) => ({
                ...prev,
                votes: prev.votes + 1,
                voted: true,
            }));
        }
    });

    const [handleSelectWinner] = useProtectedAction(async () => {
        const comment = 'winner';
        const res = await api.put({ url: `organisations/${user.organisation.id}/submissions/${submissionId}/winner`, data: { comment } });

        if (res.success) {
            queryClient.invalidateQueries({ queryKey: submissionQueries.lists() });
            queryClient.setQueryData(submissionQueries.detail(submissionId), (prev) => ({
                ...prev,
                is_winner: comment,
            }));
        }
    });

    const [handleDeselectWinner] = useProtectedAction(async () => {
        const res = await api.delete({ url: `organisations/${user.organisation.id}/submissions/${submissionId}/winner` });

        if (res.success) {
            queryClient.invalidateQueries({ queryKey: submissionQueries.lists() });
            queryClient.setQueryData(submissionQueries.detail(submissionId), (prev) => ({
                ...prev,
                is_winner: null,
            }));
        }
    });

    return {
        submission,
        isLoading,
        handleVote,
        handleSelectWinner,
        handleDeselectWinner,
    };
};

export default useSubmissionWithActions;
