import { useState, useEffect, useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { SubmissionCard, useSubmissions } from 'features/submission';
import { ProfileBanner } from 'features/profile';
import { NotFound } from 'features/report';
import { UserContext } from 'features/user';
import { PageLayout } from 'features/navigation';
import {
    Container, CardGrid, AutoScrollLoader, Chip, Tabs, Tab,
} from 'ui';
import CompleteOnboarding from 'default/pages/UserProfile/CompleteOnboarding';
import useKeyGen from 'hooks/useKeyGen';
import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';
import api from 'adapters/api';
import useToggleButtons from 'hooks/useToggleButtons';
import FormCheckboxDropdown from 'forms/FormCheckboxDropdown';

const searchOptions = {
    trending: { label: 'Trending', checked: true },
    newest: { label: 'Newest First', checked: false },
    oldest: { label: 'Oldest First', checked: false },
};

// TODO: switch to using tabs
export default function UserProfile() {
    const { user: currentUser } = useContext(UserContext);
    const { username } = useParams();
    const [searchParams] = useSearchParams();
    const [user, setUser] = useState({});
    const [userLoading, setUserLoading] = useState(true);
    const [sortOption, setSortOption] = useState(null);
    const defaultDraft = searchParams.get('status') === 'draft';
    const [submissionStatus, dispatchSubmissionStatus] = useToggleButtons({
        active: { checked: !defaultDraft, label: 'Submissions' },
        drafts: { checked: defaultDraft, label: 'Drafts' },
    });
    const keyGen = useKeyGen();
    const published = useSubmissions({
        user_id: username, published: true, type: ['submission', 'proposal'], sort: sortOption,
    });
    const drafts = useSubmissions({
        user_id: username, published: false, type: ['submission', 'proposal'], sort: sortOption,
    });
    const submissions = submissionStatus.active.checked ? published : drafts;

    useEffect(() => {
        const getUser = async () => {
            setUserLoading(true);

            // Whenever changing profile, we want to show active by default
            dispatchSubmissionStatus({ type: 'CHECKED', id: 'active', oneOrTheOther: true });

            const res = await api.get({ url: `users/${username}` });

            if (res.success)
                setUser(res.data);

            setUserLoading(false);
        };

        getUser();
    }, [username]);

    const handleOptions = (options) => {
        if (submissions.isLoading)
            return;
        setSortOption(Object.keys(options).find((option) => options[option].checked));
    };

    const handleTabs = (id) => !submissions.isLoading && dispatchSubmissionStatus({ type: 'CHECKED', id, oneOrTheOther: true });

    return (
        <PageLayout
            pageName="Profile"
            showFooter={!submissions.hasNextPage}
            meta={{
                currentRoute: 'userProfile',
            }}
        >
            {(Object.keys(user).length > 0 || userLoading || submissions.isLoading) ? (
                <>
                    <div className="bg-content2 old-rounded-top-4">
                        {currentUser.id === user.id && <CompleteOnboarding />}
                        {(!userLoading) ? (
                            <ProfileBanner.User
                                profile={user}
                                entries={drafts.total + published.total}
                                edit={(currentUser.id === user.id) ? '/user/edit' : ''}
                                key={keyGen.getKey(user)}
                            />
                        ) : (
                            <ProfileBanner.Loading />
                        )}
                    </div>

                    <div className="bg-background old-mt-n6 old-rounded-top-4">
                        <Container className="old-sb-6 old-py-6">
                            <div className="flex flex-wrap gap-4 items-center justify-end">
                                {((currentUser.id === user.id)) && (
                                    <Tabs
                                        color="primary"
                                        radius="full"
                                        size="lg"
                                        disabledKeys={[
                                            ...(drafts.total <= 0 ? ['drafts'] : []),
                                        ]}
                                        onSelectionChange={handleTabs}
                                        classNames={{
                                            base: 'w-full sm:w-auto',
                                            tabList: 'w-full sm:w-auto',
                                        }}
                                        defaultSelectedKey={defaultDraft ? 'drafts' : 'active'}
                                    >
                                        <Tab
                                            key="active"
                                            title={(
                                                <div className="flex items-center space-x-2">
                                                    <span>Submissions</span>
                                                    <Chip size="sm" variant="faded">{published.total}</Chip>
                                                </div>
                                        )}
                                        />
                                        <Tab
                                            key="drafts"
                                            title={(
                                                <div className="flex items-center space-x-2">
                                                    <span>Draft Submissions</span>
                                                    <Chip size="sm" variant="faded">{drafts.total}</Chip>
                                                </div>
                                        )}
                                        />
                                    </Tabs>
                                )}

                                <FormCheckboxDropdown
                                    defaultValue={searchOptions}
                                    placeholder="Sort By"
                                    className="old-dropdown-toggle-filter old-d-flex old-align-items-center old-px-0 old-border-0 uppercase old-bg-transparent old-ms-auto"
                                    onUpdate={handleOptions}
                                    data-role="form-sort-by"
                                    oneOrTheOther
                                />
                            </div>

                            <CardGrid
                                entries={submissions.all}
                                card={SubmissionCard}
                                sizing={{ md: 6, xl: 4 }}
                            />

                            <AutoScrollLoader
                                fetchNextPage={submissions.fetchNextPage}
                                hasNextPage={submissions.hasNextPage}
                                isLoading={submissions.isLoading || submissions.isFetchingNextPage}
                                error={submissions.error}
                                empty={!submissions.all.length}
                                errorButtonVariant="black"
                                className="old-my-6"
                                textClassName="font-roman old-text-thin"
                            >
                                <ImageNotSupportedOutlinedIcon className="!size-12 old-mb-3 old-text-lightgrey old-d-block old-mx-auto" />
                                <span className="font-roman old-text-thin old-text-lightgrey">No Submissions Yet</span>
                            </AutoScrollLoader>
                        </Container>
                    </div>
                </>
            ) : (
                <NotFound text={'Bummer. \n The requested user could not be found or does not exist.'} issuePage="profile" />
            )}
        </PageLayout>
    );
}
