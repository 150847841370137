import { useState, useEffect } from 'react';
import { Button } from 'ui';
import submissionPropTypes from 'features/submission/schemas/submissionSchema';
import HighlightIcon from '@mui/icons-material/Highlight';
import CancelIcon from '@mui/icons-material/Cancel';
import api from 'adapters/api';

export default function SubmissionSpotlightSelectButton({ submission }) {
    const [isSpotlight, setIsSpotlight] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getIsSpotlight = async () => {
            setLoading(true);
            const res = await api.get({ url: `submissions/${submission.id}/spotlight` });
            if (res.success)
                setIsSpotlight(res.data);
            setLoading(false);
        };
        getIsSpotlight();
    }, [submission]);

    const handleSelectSpotlight = async () => {
        setLoading(true);
        const res = await api.put({ url: `submissions/${submission.id}/spotlight` });
        if (res.success)
            setIsSpotlight(true);
        setLoading(false);
    };

    const handleDeselectSpotlight = async () => {
        setLoading(true);
        const res = await api.delete({ url: `submissions/${submission.id}/spotlight` });
        if (res.success)
            setIsSpotlight(false);
        setLoading(false);
    };

    return (
        <Button
            color="primary"
            size="lg"
            isLoading={loading}
            onClick={isSpotlight ? handleDeselectSpotlight : handleSelectSpotlight}
            endContent={isSpotlight ? <CancelIcon /> : <HighlightIcon />}
        >
            {isSpotlight ? 'Spotlighted' : 'Spotlight'}
        </Button>
    );
}

SubmissionSpotlightSelectButton.propTypes = {
    submission: submissionPropTypes.isRequired,
};
