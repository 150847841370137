import { Link } from 'react-router-dom';
import Row from 'ui/layout/Row';
import Col from 'ui/layout/Col';
import Button from 'ui/buttons/Button';
import PropTypes from 'prop-types';

function CallToAction({
    title,
    description,
    cta,
    to,
    className = '',
    ...ctaProps
}) {
    return (
        <Row className={className}>
            <Col xs={12} lg={6}>
                <h2 className="old-mb-5 old-mb-lg-0 old-home-header-font">{title}</h2>
            </Col>
            <Col xs={12} lg={6} className="old-all-mb-5 old-d-flex old-flex-column old-justify-content-center old-mw-section-text">
                <p className="old-fs-20">{description}</p>

                <div className="old-d-inline-block">
                    <Button
                        as={Link}
                        to={to}
                        size="lg"
                        {...ctaProps}
                    >
                        {cta}
                    </Button>
                </div>
            </Col>
        </Row>
    );
}
CallToAction.propTypes = {
    title: PropTypes.node.isRequired,
    description: PropTypes.string.isRequired,
    cta: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default CallToAction;
