import { useContext } from 'react';
import {
    SubmissionCard, SubmissionSpotlightBlock, SubmissionVoteBlock, useSubmissions,
} from 'features/submission';
import { BriefCard, useBriefs } from 'features/brief';
import { UserContext } from 'features/user';
import { PageLayout } from 'features/navigation';
import { Navigate } from 'react-router-dom';
import {
    Row, Col, ScrollSections, Container, TitleBanner, CallToAction, Swiper,
} from 'ui';
import HighlightCard from 'default/pages/Home/HighlightCard';
import highlights from 'default/pages/Home/highlightData';
import routes from 'default/routes';
import backgroundAnimation from 'images/home-bg.mp4';
import prospectIcon from 'images/icons/prospect100icon-inverted.svg';

export default function Home() {
    const { user } = useContext(UserContext);
    const briefs = useBriefs();
    const submissions = useSubmissions();

    if (user.doesSessionExist)
        return <Navigate to={routes.briefs} replace />;

    return (
        <PageLayout
            className="text-white"
            navbarProps={{
                size: 'none',
                icon: prospectIcon,
            }}
        >
            <TitleBanner
                titleComponents={[
                    'The Home of',
                    'Design briefs',
                ]}
                subText={(
                    <>
                        Tap into your creativity and co-create alongside
                        <br className="old-d-none old-d-sm-block" />
                        {' '}
                        your favourite brands from around the world.
                    </>
                  )}
                backgroundVideo={backgroundAnimation}
                titleClassName="old-home"
            />

            <ScrollSections>
                <ScrollSections.ScrollSection
                    className="old-bg-offwhite old-text-dark old-mt-n6 old-rounded-top-4"
                    first
                >
                    <CallToAction
                        title={(
                            <>
                                Briefs to keep
                                <br />
                                you on your toes
                            </>
                        )}
                        description="Briefs give you the chance to create alongside legendary organizations. Make your submission before the time runs out. Once the application deadline has arrived, all entries will be revealed, giving you just a matter of days to get as many supporters as possible before the host decides a winner."
                        cta="View All"
                        to={routes.briefs}
                        className="old-mb-6"
                        color="primary"
                    />

                    <div className="old-position-relative">
                        <div className="-mx-6">
                            <Swiper color="primary">
                                {briefs.all.map((brief) => (
                                    <div key={brief.id} className="max-w-80 min-w-80">
                                        <BriefCard entry={brief} variant="primary-100" />
                                    </div>
                                ))}
                            </Swiper>
                        </div>

                        {(briefs.all.length <= 0 && !briefs.isLoading) && (
                            <div className="old-position-absolute old-top-0 old-h-100 old-w-100 old-d-flex old-justify-content-center old-align-items-md-center text-center old-text-lightgrey">
                                <span className="uppercase old-fs-18 old-fs-md-20">
                                    There are no active briefs here at the moment.
                                    <br />
                                    Please come back at a later date.
                                </span>
                            </div>
                        )}
                    </div>
                </ScrollSections.ScrollSection>

                <ScrollSections.ScrollSection
                    className="old-bg-offdark old-rounded-top-4"
                    last
                >
                    <SubmissionVoteBlock
                        className="old-mb-6"
                        variant="faded"
                    />

                    <div className="old-position-relative">
                        <div className="-mx-6">
                            <Swiper color="default">
                                {submissions.all.map((submission) => (
                                    <div key={submission.id} className="max-w-80 min-w-80">
                                        <SubmissionCard entry={submission} />
                                    </div>
                                ))}
                            </Swiper>
                        </div>

                        {(submissions.all.length <= 0 && !submissions.all.isLoading) && (
                            <div className="old-position-absolute old-top-0 old-h-100 old-w-100 old-d-flex old-justify-content-center old-align-items-md-center text-center old-text-lightgrey">
                                <span className="uppercase old-fs-18 old-fs-md-20">
                                    There are no submissions here at the moment.
                                    <br />
                                    Please come back at a later date.
                                </span>
                            </div>
                        )}
                    </div>
                </ScrollSections.ScrollSection>
            </ScrollSections>

            <div className="old-bg-primary old-text-secondary old-py-6 old-position-relative old-rounded-top-4 old-mt-n6">
                <Container className="old-overflow-hidden old-sb-6">
                    <Row className="old-mb-6">
                        <Col xs={12} className="mb-6">
                            <h2 className="old-home-header-font">
                                Remain Inspired
                                <br className="old-d-none old-d-xl-block" />
                                {' '}
                                And stay up to Date
                            </h2>
                        </Col>

                        <Col xs={12}>
                            <div className="-mx-6">
                                <Swiper color="background">
                                    {highlights.map((submission) => (
                                        <div key={submission.id} className="max-w-96 min-w-80">
                                            <HighlightCard entry={submission} />
                                        </div>
                                    ))}
                                </Swiper>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="old-bg-offwhite old-text-secondary old-rounded-top-4 old-mt-n6 old-position-relative">
                <Container className="old-sb-6 old-py-6">
                    <SubmissionSpotlightBlock color="primary" />
                </Container>
            </div>
        </PageLayout>
    );
}
