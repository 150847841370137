import { OverlayTrigger, Tooltip } from 'ui';
import VerifiedBadge from 'icons/VerifiedBadge';
import P100Icon from 'icons/P100Icon';
import PropTypes from 'prop-types';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const badges = {
    blue_badge: {
        component: VerifiedBadge,
        tooltip: 'Founding Member',
        color: '#09f',
    },
    p100_engineer: {
        component: P100Icon,
        tooltip: 'Oditi Engineer',
    },
    green_badge: {
        component: VerifiedBadge,
        tooltip: 'Created 2+ Submissions',
        color: '#037F22',
    },
    purple_badge: {
        component: VerifiedBadge,
        tooltip: 'Created 5+ Submissions',
        color: '#8245ff',
    },
};

function SingleUserBadge({ badgeKey, className = '' }) {
    if (!badgeKey || !Object.prototype.hasOwnProperty.call(badges, badgeKey))
        return null;

    const BadgeComponent = badges[badgeKey].component;
    const badgeTooltip = badges[badgeKey].tooltip;
    const badgeColor = badges[badgeKey].color || '';

    return (
        <OverlayTrigger
            id="badge-tooltip"
            placement="right"
            overlay={(
                <Tooltip>
                    <div className="old-bg-white old-px-2 old-py-1 old-rounded-pill old-shadow-xl old-d-flex">
                        <small className="font-roman old-fs-10">{badgeTooltip}</small>
                    </div>
                </Tooltip>
              )}
        >
            <div className="old-d-inline">
                <BadgeComponent className={className} fill={badgeColor} />
            </div>
        </OverlayTrigger>
    );
}
SingleUserBadge.propTypes = {
    badgeKey: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export function WinnerBadge() {
    return (
        <OverlayTrigger
            id="winner-badge-tooltip"
            placement="bottom"
            overlay={(
                <Tooltip>
                    <div className="old-bg-white old-mt-1 old-px-2 old-py-1 old-rounded-pill old-shadow-xl">
                        <small className="font-roman old-fs-10">Brief Winner</small>
                    </div>
                </Tooltip>
            )}
        >
            <span className="old-bg-gold old-text-white old-p-2 old-p-sm-3 old-rounded-circle old-shadow">
                <EmojiEventsIcon />
            </span>
        </OverlayTrigger>
    );
}

export default function UserBadge({ profile, className = '' }) {
    if (!profile?.badges || profile?.badges.length === 0)
        return null;

    return (
        <div className="old-d-flex">
            {profile?.badges.map((badgeKey) => Object.prototype.hasOwnProperty.call(badges, badgeKey) && <SingleUserBadge key={badgeKey} badgeKey={badgeKey} className={className} />)}
        </div>
    );
}
UserBadge.propTypes = {
    profile: PropTypes.shape({
        badges: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    className: PropTypes.string,
};
