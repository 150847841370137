import {
    useState, forwardRef, useEffect,
} from 'react';
import { Row, Col, ToggleButton } from 'ui';
import useToggleButtons, { reducer as handleChange } from 'hooks/useToggleButtons';
import PropTypes from 'prop-types';
import FormWrapper, { getWrapperProps } from 'forms/wrappers/FormWrapper';

function DefaultAs({ topic }) {
    if (topic.text === undefined)
        return <span className="uppercase old-fs-14 old-fs-md-16 old-w-100 old-text-nowrap">{topic.label}</span>;

    return (
        <>
            <h4>{topic.label}</h4>
            <small className="font-roman">{topic.text}</small>
        </>
    );
}

DefaultAs.propTypes = {
    topic: PropTypes.shape({
        text: PropTypes.string,
        label: PropTypes.string.isRequired,
    }).isRequired,
};

const FormToggleButtons = forwardRef(({
    max, onUpdate, sizing, as, rowClassName, variant, oneOrTheOther, defaultValue, id, disableAll, ...props
}, ref) => {
    const [state, dispatch] = useToggleButtons(defaultValue);
    const [disableChecked, setDisableChecked] = useState(disableAll);
    const [wrapperProps, inputProps] = getWrapperProps(props);
    const As = as;

    useEffect(() => {
        dispatch({ payload: defaultValue });
        const selected = Object.values(defaultValue).filter((t) => t.checked);

        if (max !== undefined)
            setDisableChecked(selected.length >= max);
    }, [defaultValue]);

    const handleOnChange = (idToChange) => {
        const checkNewState = { type: 'TOGGLE', id: idToChange, oneOrTheOther };
        const newState = handleChange(state, checkNewState);
        const selected = Object.values(newState).filter((t) => t.checked);

        if (ref !== null)
            ref.current = selected;

        if (onUpdate)
            onUpdate(newState);

        if (max !== undefined)
            setDisableChecked(selected.length >= max);

        dispatch(checkNewState);
    };

    if (Object.values(defaultValue).length <= 0)
        return null;

    return (
        <FormWrapper
            {...wrapperProps}
            wrapperClassName={`${wrapperProps.wrapperClassName}`}
        >
            <Row className={`old-justify-content-center ${rowClassName}`}>
                {Object.keys(state).map((topic) => (
                    <Col key={topic + id} className="old-d-flex" {...sizing}>
                        <ToggleButton
                            id={topic + id}
                            checked={state[topic].checked}
                            type="checkbox"
                            variant={variant}
                            onChange={() => handleOnChange(topic)}
                            disabled={state[topic].checked ? false : disableChecked}
                            {...inputProps}
                        >
                            <As topic={state[topic]} />
                        </ToggleButton>
                    </Col>
                ))}
            </Row>
        </FormWrapper>
    );
});

FormToggleButtons.propTypes = {
    max: PropTypes.number,
    onUpdate: PropTypes.func,
    sizing: PropTypes.objectOf(PropTypes.number),
    as: PropTypes.elementType,
    rowClassName: PropTypes.string,
    oneOrTheOther: PropTypes.bool,
    defaultValue: PropTypes.objectOf(PropTypes.shape({
        checked: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
        text: PropTypes.string,
    })),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disableAll: PropTypes.bool,
    variant: PropTypes.string,
};

FormToggleButtons.defaultProps = {
    max: undefined,
    onUpdate: undefined,
    sizing: {},
    as: DefaultAs,
    rowClassName: '',
    oneOrTheOther: false,
    defaultValue: {},
    id: '',
    disableAll: false,
    variant: 'outline-secondary',
};

export default FormToggleButtons;
