import { useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'features/authentication';
import { UserContext } from 'features/user';
import { ProgressStep, Button } from 'ui';
import routes from 'default/routes';

export default function CompleteOnboarding() {
    const { showAuth } = useContext(AuthContext);
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    const completedAll = (
        (user.metadata?.completed_onboarding || false)
        && user.icon
        && user.description
    );

    const currentStep = useMemo(() => {
        if (!(user.metadata?.completed_onboarding || false)) {
            return {
                step: 1,
                ctaText: 'Complete Onboarding',
                ctaAction: () => showAuth({ onboarding: true }),
            };
        }
        if (!user.icon) {
            return {
                step: 2,
                ctaText: 'Add Profile Picture',
                ctaAction: () => navigate(routes.userProfileEdit),
            };
        }
        if (!user.description) {
            return {
                step: 3,
                ctaText: 'Add Profile Description',
                ctaAction: () => navigate(routes.userProfileEdit),
            };
        }
        return {
            step: 4,
            ctaText: 'done!',
        };
    }, [user]);

    if (completedAll)
        return null;

    return (
        <section className="old-d-block old-z-1 old-overflow-hidden text-center old-bg-offwhite old-rounded-top-4 old-border-bottom old-border-4" data-testid="complete-onboarding">
            <div className="old-text-dark old-py-5 old-all-mb-4">
                <h3>You&#39;re Almost Done!</h3>

                <div className="old-d-flex old-w-100 old-w-md-75 old-m-auto old-mb-3">
                    <ProgressStep
                        first
                        title="Create An Account"
                        step={1}
                        currentStep={currentStep.step}
                    />
                    <ProgressStep
                        title="Add Profile Picture"
                        step={2}
                        currentStep={currentStep.step}
                    />
                    <ProgressStep
                        title="Add Description"
                        step={3}
                        currentStep={currentStep.step}
                    />
                    <ProgressStep
                        last
                        title="Done!"
                        step={4}
                        currentStep={currentStep.step}
                    />
                </div>

                <Button
                    color="primary"
                    size="lg"
                    trackingName="view complete onboarding"
                    onClick={currentStep.ctaAction}
                >
                    {currentStep.ctaText}
                </Button>
            </div>
        </section>
    );
}
