import PropTypes from 'prop-types';

export default function Shimmer({ className = '', ...props }) {
    return (
        <div className={`old-shimmer ${className}`} {...props} />
    );
}

Shimmer.propTypes = {
    className: PropTypes.string,
};
