import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BriefCard, useBriefs } from 'features/brief';
import { ProfileBanner } from 'features/profile';
import { NotFound } from 'features/report';
import { PageLayout } from 'features/navigation';
import { Container, CardGrid, AutoScrollLoader } from 'ui';
import useKeyGen from 'hooks/useKeyGen';
import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';
import api from 'adapters/api';

export default function Organisation() {
    const { id } = useParams();
    const [orgLoading, setOrgLoading] = useState(true);
    const [organisation, setOrganisation] = useState({});
    const keyGen = useKeyGen();
    const briefs = useBriefs({ org_id: id, status: null });

    useEffect(() => {
        const getOrganisation = async () => {
            setOrgLoading(true);

            const res = await api.get({ url: `organisations/${id}` });

            if (res.success)
                setOrganisation(res.data);

            setOrgLoading(false);
        };

        getOrganisation();
    }, [id]);

    return (
        <PageLayout
            pageName="Organisation"
            showFooter={!briefs.hasNextPage}
        >
            {(Object.keys(organisation).length > 0 || orgLoading || briefs.isLoading) ? (
                <>
                    <div className="old-bg-offwhite old-rounded-top-4">
                        {(!orgLoading) ? (
                            <ProfileBanner.Organisation
                                profile={organisation}
                                briefs={briefs.total}
                                key={keyGen.getKey(organisation)}
                            />
                        ) : (
                            <ProfileBanner.Loading />
                        )}
                    </div>

                    <div className="old-bg-white old-mt-n6 old-rounded-top-4">
                        <Container className="old-sb-6 old-py-6">
                            <CardGrid
                                entries={briefs.all}
                                card={BriefCard}
                                sizing={{ md: 6, xl: 4 }}
                            />

                            <AutoScrollLoader
                                fetchNextPage={briefs.fetchNextPage}
                                hasNextPage={briefs.hasNextPage}
                                isLoading={briefs.isLoading || briefs.isFetchingNextPage}
                                error={briefs.error}
                                empty={!briefs.all.length}
                                errorButtonVariant="black"
                                className="old-my-6 old-pt-6"
                                textClassName="font-roman old-text-thin"
                            >
                                <ImageNotSupportedOutlinedIcon className="!size-12 old-mb-3 old-text-lightgrey old-d-block old-mx-auto" />
                                <span className="font-roman old-text-thin old-text-lightgrey">No Briefs Yet</span>
                            </AutoScrollLoader>
                        </Container>
                    </div>
                </>
            ) : (
                <NotFound text={'Bummer. \n The requests organisation could not be found or does not exist.'} issuePage="profile" />
            )}
        </PageLayout>
    );
}
