import { Button as NextButton } from '@nextui-org/react';
import Mixpanel from 'adapters/mixpanel';
import PropTypes from 'prop-types';

export default function Button({
    onClick = undefined,
    radius = 'full',
    trackingLocation = '',
    trackingName = '',
    className = 'uppercase',
    children,
    ...props
}) {
    const handleTrackOnClick = (e) => {
        Mixpanel.trackButton('Button', trackingName, trackingLocation);
        if (onClick)
            onClick(e);
    };

    return (
        <NextButton
            onClick={handleTrackOnClick}
            radius={radius}
            className={className}
            {...props}
        >
            {children}
        </NextButton>
    );
}
Button.propTypes = {
    onClick: PropTypes.func,
    radius: PropTypes.string,
    trackingLocation: PropTypes.string,
    trackingName: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};
