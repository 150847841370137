import Mixpanel from 'mixpanel-browser';

const init = () => {
    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
        Mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
    } else {
        // Must initialize with dummy token
        Mixpanel.init('test mixpanel token');
    }
};

const getUTMParameters = () => {
    const utmParams = ['utm_source', 'utm_campaign', 'utm_medium', 'utm_term', 'utm_content'];
    const queryParams = new URLSearchParams(window.location.search);

    const params = {};
    utmParams.forEach((param) => {
        const value = queryParams.get(param);
        if (value)
            params[param] = value;
    });

    return params;
};

const trackButton = (type, name = '', location = '', props = {}) => {
    Mixpanel.track('Button Press', {
        Type: type,
        ...(name !== '' && { 'Button Name': name }),
        ...(location !== '' && { 'Tracking Location': location }),
        ...props,
    });
};

init();
Mixpanel.getUTMParameters = getUTMParameters;
Mixpanel.trackButton = trackButton;
export default Mixpanel;
