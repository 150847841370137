import { CallToAction } from 'ui';
import routes from 'default/routes';

export default function SubmissionSpotlightBlock(props) {
    return (
        <CallToAction
            title={(
                <>
                    Oditi
                    <br className="old-d-none old-d-xxxl-block" />
                    {' '}
                    Spotlight
                </>
            )}
            description="Oditi Spotlight showcases some of the entire community&#39;s favourite designs. Spotlight updates every month or so, make sure you check back regularly!"
            cta="View Spotlight"
            to={routes.spotlight}
            trackingName="view spotlight"
            {...props}
        />
    );
}
