import { useState, useEffect } from 'react';

export default function useScrollDirection(delay = 0) {
    const [scrollDirection, setScrollDirection] = useState(null);

    useEffect(() => {
        let lastScrollY = window.pageYOffset;

        const handleScrollDirection = () => {
            const scrollY = window.pageYOffset;
            const direction = scrollY > lastScrollY ? 'down' : 'up';

            if (direction !== scrollDirection && (scrollY - lastScrollY > delay || scrollY - lastScrollY < -delay))
                setScrollDirection(direction);

            lastScrollY = scrollY > 0 ? scrollY : 0;
        };
        window.addEventListener('scroll', handleScrollDirection); // add event listener
        return () => {
            window.removeEventListener('scroll', handleScrollDirection); // clean up
        };
    }, [scrollDirection]);

    return scrollDirection;
}
