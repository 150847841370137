import { useEffect, useState } from 'react';
import {
    useParams, Link, generatePath, useSearchParams, useLocation,
} from 'react-router-dom';
import routes from 'default/routes';
import { ProfileIcon } from 'features/profile';
import {
    BriefStatusModal, BriefStatus, briefStatusContent, useBrief,
} from 'features/brief';
import { NotFound } from 'features/report';
import { PageLayout, NavbarSpacer } from 'features/navigation';
import {
    ScrollSections, Row, Col, Container, ScrollToTopButton, ModalClickContainer, Tabs, Tab, Chip, Button,
} from 'ui';
import useToggleButtons from 'hooks/useToggleButtons';
import BriefDetails from 'default/pages/Brief/BriefDetails';
import BriefWorkspace from 'default/pages/Brief/BriefWorkspace';
import BriefSubmissions from 'default/pages/Brief/BriefSubmissions';
import prospectIcon from 'images/icons/prospect100icon-inverted.svg';

export default function Brief() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [finalPage, setFinalPage] = useState(true);
    const [callToAction, setCallToAction] = useState({});
    const [searchParams] = useSearchParams();
    const [tabs, dispatchTabs] = useToggleButtons({
        details: { checked: true, label: 'Overview' },
    });
    const { state } = useLocation();
    const { data, isLoading } = useBrief(id);
    const brief = data ?? {};

    useEffect(() => {
        if (brief.id == null)
            return;

        const newTabs = { details: { checked: false, label: 'Overview' } };
        if (brief.user_submission_count > 0)
            newTabs.workspace = { checked: false, label: 'Workspace', count: brief.user_submission_count };
        if (brief.status === 'voting' || brief.status === 'judging' || brief.status === 'completed')
            newTabs.submissions = { checked: false, label: 'Submissions' };
        if (newTabs.workspace && (['submitting', 'proposing', 'selecting'].includes(brief.status) || searchParams.get('tab') === 'workspace'))
            newTabs.workspace.checked = true;
        else if (newTabs.submissions && (brief.status !== 'completed' || searchParams.get('tab') === 'submissions'))
            newTabs.submissions.checked = true;
        else
            newTabs.details.checked = true;

        dispatchTabs({ action: 'RESET', payload: newTabs });
        setCallToAction(briefStatusContent(brief)[brief.status].callToAction);
        setLoading(false);

        window.history.replaceState({}, document.title);
    }, [brief, state?.entered]);

    const handleTabs = (tabId) => {
        if (loading)
            return;

        setFinalPage(tabId === 'details' || tabId === 'workspace' || brief.total_submission_count <= 0);
        dispatchTabs({ type: 'CHECKED', id: tabId, oneOrTheOther: true });
    };

    if (!isLoading && Object.keys(brief).length <= 0)
        return <NotFound text={'Bummer. \n The requested brief could not be found or does not exist.'} issuePage="enter_brief" />;

    return (
        <PageLayout
            isLoading={loading}
            pageName="Brief"
            meta={{
                image: brief.banner?.url || brief.thumbnail,
                description: `Oditi Brief: ${brief.title}`,
            }}
            className="text-white"
            showFooter={finalPage}
            navbarProps={{
                size: 'none',
                icon: prospectIcon,
            }}
        >
            {(Object.keys(brief).length > 0) && (
                <ScrollSections data-testid="brief">
                    <ScrollSections.ScrollSectionFluid className="old-contain-paint old-text-white">
                        <img
                            className="old-position-absolute old-w-100 old-h-100 old-object-fit-cover"
                            src={brief.banner?.url || brief.thumbnail}
                            alt="brief banner"
                        />

                        <div className="old-position-absolute old-top-0 old-start-0 old-bg-black old-w-100 old-h-100 old-bg-opacity-50" />

                        <Container className={Object.keys(tabs).length <= 1 && 'old-pb-6 old-mb-6'}>
                            <NavbarSpacer />

                            <Row className="old-py-lg-6 old-gx-6">
                                <Col xs={12} lg={4} className="old-d-flex old-flex-column old-all-mb-3 old-all-mb-lg-4 old-pe-4">
                                    <hr className="old-d-lg-none old-d-block old-flex-grow-1 old-border-4 old-border-offwhite old-opacity-100 old-rounded-pill" />
                                    <Link to={generatePath(routes.organisation, { id: brief.organisation.id })} className="old-d-flex old-align-items-center old-mt-lg-none old-mt-lg-0 old-mt-2">
                                        <ProfileIcon
                                            icon={brief.organisation.icon}
                                            alt={`${brief.organisation.name} Company Icon`}
                                            className="!size-8 sm:!size-10 old-me-2 old-shadow"
                                        />
                                        <h4 className="old-mb-0 old-fs-5 old-text-collapse old-transition-underline old-font-bold">{brief.organisation.name}</h4>
                                    </Link>

                                    <div className="old-d-flex old-align-items-center old-align-items-lg-start old-flex-lg-column old-flex-grow-1">
                                        <ModalClickContainer
                                            modal={BriefStatusModal}
                                            brief={brief}
                                        >
                                            <BriefStatus brief={brief} />
                                        </ModalClickContainer>

                                        {(callToAction && brief?.entered) && (
                                            <div className="old-d-none old-d-lg-block old-align-items-end old-w-100">
                                                <Button
                                                    color="primary"
                                                    size="lg"
                                                    fullWidth
                                                    as={Link}
                                                    to={callToAction.path}
                                                    trackingName={callToAction.trackingName}
                                                    data-testid="brief-cta"
                                                >
                                                    {callToAction.text}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </Col>

                                <Col xs={12} lg={8} className="old-order-first">
                                    <div className="old-mw-brief-text">
                                        <h2 className="old-mb-3 old-home-header-font old-mt-lg-n3 old-mt-4">{brief.title}</h2>
                                    </div>

                                    <div className="old-d-flex old-gap-2 old-gap-md-3 old-mb-2 old-mb-lg-0">
                                        {brief.design_interests.map((category) => (
                                            <Link
                                                key={category}
                                                className="old-btn old-btn-purple old-px-3 old-p-1 old-rounded-pill old-text-capitalize old-fs-12 old-fs-md-14"
                                                to={`${routes.briefs}?category=${category}`}
                                            >
                                                {category.replace('_', ' ')}
                                            </Link>
                                        ))}
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                        {Object.keys(tabs).length > 1 && (
                            <Container className="old-w-100 old-d-flex old-align-items-center old-justify-content-center old-mb-6 old-pb-6 old-mt-lg-0 old-mt-4">
                                <hr className="old-d-none old-d-sm-block old-flex-grow-1 old-border-4 old-border-offwhite old-opacity-100 old-rounded-pill" />

                                <Tabs
                                    size="lg"
                                    radius="full"
                                    color="primary"
                                    items={Object.entries(tabs)}
                                    onSelectionChange={handleTabs}
                                    defaultSelectedKey={Object.keys(tabs).find((t) => tabs[t].checked)}
                                    fullWidth
                                    classNames={{
                                        base: 'max-w-full sm:max-w-[75%] lg:max-w-[50%] 2xl:max-w-[33%] sm:mx-4',
                                        tabList: 'sm:p-2 gap-0 sm:gap-2',
                                        tab: 'uppercase text-xs sm:text-base h-9 sm:h-auto sm:py-2',
                                    }}
                                >
                                    {([key, value]) => (
                                        <Tab
                                            key={key}
                                            title={(
                                                <div className="flex items-center space-x-1 sm:space-x-2">
                                                    <span>{value.label}</span>
                                                    {value?.count > 0 && (
                                                        <Chip size="sm" variant="faded" className="px-0.5 sm:px-1">{value.count}</Chip>
                                                    )}
                                                </div>
                                          )}
                                        />
                                    )}
                                </Tabs>

                                <hr className="old-d-none old-d-sm-block old-flex-grow-1 old-border-4 old-border-offwhite old-opacity-100 old-rounded-pill" />
                            </Container>
                        )}
                    </ScrollSections.ScrollSectionFluid>

                    {(tabs.details.checked) && (
                        <BriefDetails brief={brief} />
                    )}

                    {(tabs.workspace?.checked) && (
                        <BriefWorkspace brief={brief} />
                    )}

                    {(tabs.submissions?.checked) && (
                        <BriefSubmissions brief={brief} setFinalPage={setFinalPage} />
                    )}
                </ScrollSections>
            )}

            <ScrollToTopButton />
        </PageLayout>
    );
}
