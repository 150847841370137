import { useState, useContext } from 'react';
import { UserContext } from 'features/user';
import { InView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import api from 'adapters/api';

export default function withViewCounter(SubmissionCardComponent) {
    function SubmissionCardWithInView({ entry, ...props }) {
        const { user } = useContext(UserContext);
        const [hasViewed, setHasViewed] = useState(false);

        const handleInView = async (inView) => {
            if (inView && user.doesSessionExist && !hasViewed) {
                await api.put({ url: `submissions/${entry.id}/view` });
                setHasViewed(true);
            }
        };

        return (
            <InView as="div" onChange={handleInView}>
                <SubmissionCardComponent entry={entry} {...props} />
            </InView>
        );
    }

    SubmissionCardWithInView.propTypes = {
        entry: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
    };

    return SubmissionCardWithInView;
}
