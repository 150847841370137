import { useState, useEffect, useContext } from 'react';
import { issueSchema } from 'utils/validation/validationSchemas';
import { useProtectedAction } from 'features/authentication';
import { UserContext } from 'features/user';
import { Form, Modal } from 'ui';
import api from 'adapters/api';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import ValidateForm from 'forms/wrappers/ValidationWrappers';
import FormControl from 'forms/FormControl';
import FormSelect from 'forms/FormSelect';
import SendIcon from '@mui/icons-material/Send';

export default function ReportFeedbackModal({
    onHide,
    isOrg = false,
    show = false,
    defaultReason = '',
    defaultPage = '',
    apiError = {},
}) {
    const [issueSent, setIssueSent] = useState(false);
    const [reason, setReason] = useState('');
    const { user } = useContext(UserContext);

    // Reset reason on modal close
    useEffect(() => {
        setReason('');
        setIssueSent(false);
    }, [show]);

    const [handleSendIssue] = useProtectedAction(async (formData) => {
        delete formData.show_page;

        const res = await api.post({ url: `users/${user.id}/issue`, data: formData });

        if (res.success) {
            setIssueSent(true);
            return {};
        }

        return {
            errors: {
                ...res.parseError(formData),
                api: res.errorDetails,
            },
        };
    });

    const toggleValidatePage = (formData) => {
        formData.show_page = reason === 'issue';
        return formData;
    };

    return (
        <Modal
            isOpen={show}
            onOpenChange={onHide}
            data-testid="report-feedback-modal"
            scrollBehavior="inside"
        >
            <Modal.ModalHeader>Give Feedback</Modal.ModalHeader>

            <ValidateForm
                onSubmit={handleSendIssue}
                validationSchema={issueSchema}
                parseData={toggleValidatePage}
                scrollOnSubmit={false}
                errorPage="other"
            >
                {({
                    handleSubmit,
                    errors,
                    loading,
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Modal.ModalBody className="font-roman gap-0">
                            {(!issueSent) ? (
                                <>
                                    <FormSelect
                                        name="reason"
                                        label="Reason"
                                        className="old-bg-offwhite old-border-offwhite old-p-3 old-rounded-4 old-d-inline-block"
                                        error={errors.reason}
                                        defaultValue={defaultReason}
                                        onUpdate={setReason}
                                    >
                                        <option value="issue">Report an Issue</option>
                                        <option value="platform">Suggestion for the platform</option>
                                        <option value="brief">Suggestion for a brief</option>
                                    </FormSelect>

                                    {(reason === 'issue') && (
                                        <FormSelect
                                            name="page"
                                            label="Affecting Page"
                                            className="old-bg-offwhite old-border-offwhite old-p-3 old-rounded-4 old-d-inline-block"
                                            defaultValue={defaultPage}
                                            error={errors.page}
                                        >
                                            <option value="onboarding">Onboarding</option>
                                            <option value="profile">Profile page / Edit profile</option>
                                            <option value="enter_brief">Entering a brief</option>
                                            <option value="voting">Voting page</option>
                                            <option value="briefs">Briefs page</option>
                                            <option value="submissions">Viewing a Submission</option>
                                            <option value="submission_vote">Voting for a Submission</option>
                                            <option value="feedback">Feedback</option>
                                            {(isOrg) && (
                                                <option value="create_brief">Creating a brief</option>
                                            )}
                                            <option value="other">Other (please describe)</option>
                                        </FormSelect>
                                    )}

                                    <FormControl
                                        name="details"
                                        label="Please describe your problem / feedback"
                                        className="old-bg-offwhite old-border-offwhite old-p-3 old-rounded-4 old-d-inline-block"
                                        wrapperClassName="old-m-0"
                                        as="textarea"
                                        maxLength={600}
                                        rows={4}
                                        error={errors.details}
                                    />

                                    {(apiError.message) && (
                                        <FormControl
                                            name="api"
                                            wrapperClassName="old-mb-0"
                                            className="old-d-none"
                                            defaultValue={apiError.message}
                                            hidden
                                        />
                                    )}

                                    {(apiError.timestamp) && (
                                        <FormControl
                                            name="timestamp"
                                            wrapperClassName="old-mb-0"
                                            className="old-d-none"
                                            defaultValue={dayjs(apiError.timestamp).format('MMMM DD, HH:mm:ss')}
                                            hidden
                                        />
                                    )}
                                </>
                            ) : (
                                <div className="text-center">
                                    <small>
                                        Thank you for your feedback.
                                        <br />
                                        We may be in touch to ask for further information.
                                    </small>
                                </div>
                            )}
                        </Modal.ModalBody>

                        <Modal.ModalActionsFooter
                            onCancel={onHide}
                            cancelText={issueSent ? 'Close' : 'Cancel'}
                            confirmText="Send"
                            confirmProps={{
                                isDisabled: loading || issueSent,
                                isLoading: loading,
                                endContent: <SendIcon className="!size-4" />,
                            }}
                        />
                    </Form>
                )}
            </ValidateForm>
        </Modal>
    );
}

ReportFeedbackModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    isOrg: PropTypes.bool,
    show: PropTypes.bool,
    defaultReason: PropTypes.string,
    defaultPage: PropTypes.string,
    apiError: PropTypes.exact({
        message: PropTypes.string,
        timestamp: PropTypes.number,
    }),
};
