import { useState } from 'react';
import { ProfileIcon } from 'features/profile';
import { ImageCropModal } from 'ui';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import PropTypes from 'prop-types';

function PreviewIcon({ previewSrc, changeText = '' }) {
    return (
        <div className="old-mx-auto text-center">
            <div className="old-hover-overlay old-position-relative old-d-inline-block old-mb-3">
                <ProfileIcon
                    icon={previewSrc}
                    className="!size-52"
                    iconClassName="old-p-3"
                />

                <div className="old-overlay-fade-top old-position-absolute old-top-0 old-start-0 old-w-100 old-h-100 old-rounded-circle old-text-white old-d-flex old-align-items-center old-justify-content-center old-flex-column">
                    <FileUploadOutlinedIcon className="!size-10" />
                    <span>Upload</span>
                </div>
            </div>

            <div className="old-d-flex old-align-items-center old-justify-content-center old-text-lightgrey">
                <FileUploadOutlinedIcon className="old-me-1" />
                <span>{changeText}</span>
            </div>
        </div>
    );
}
PreviewIcon.propTypes = {
    previewSrc: PropTypes.string.isRequired,
    changeText: PropTypes.string,
};

export default function FormFilePreviewIcon({ placeholder = 'Change Profile Picture', defaultValue = null, ...props }) {
    const [previewSrc, setPreviewSrc] = useState(defaultValue);

    return (
        <ImageCropModal
            onComplete={setPreviewSrc}
            wrapperClassName="old-mb-0"
            fileAs={PreviewIcon}
            fileProps={{
                previewSrc,
                changeText: placeholder,
            }}
            {...props}
        />
    );
}
FormFilePreviewIcon.propTypes = {
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
};
