import { useState, forwardRef, useContext } from 'react';
import api, { validationErrorToObject } from 'adapters/api';
import { usernameSchema } from 'utils/validation/validationSchemas';
import { scroller } from 'react-scroll';
import { ValidationError, ObjectSchema } from 'yup';
import { toast } from 'react-toastify';
import { ErrorToast, ErrorContext } from 'features/report';
import PropTypes from 'prop-types';

const scrollToFirstError = (elements, errors) => {
    if (!errors)
        return;

    const validElements = Object.keys(errors).filter((key) => elements.querySelector(`[name="${key}"]`) !== null);

    // We cant find any elements to scroll to
    if (!validElements.length)
        return;

    const firstErrorKey = validElements.reduce((a, b) => {
        const aHeight = elements.querySelector(`[name="${a}"]`).getBoundingClientRect().top;
        const bHeight = elements.querySelector(`[name="${b}"]`).getBoundingClientRect().top;
        return aHeight < bHeight ? a : b;
    });

    scroller.scrollTo(firstErrorKey, {
        delay: 0,
        duration: 100,
        offset: -100,
    });
};

function ValidateForm({
    validationSchema,
    onSubmit,
    errorPage = '',
    toastError = ErrorToast,
    parseData = undefined,
    parseSchema = undefined,
    scrollOnSubmit = true,
    children,
}) {
    const [formErrors, setFormErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const { showError } = useContext(ErrorContext);

    const ToastError = toastError;

    const submitForm = async (e, formRef) => {
        e.preventDefault();
        e.stopPropagation();
        setFormErrors({});
        setLoading(true);
        const elements = formRef?.current || e.currentTarget;
        const fd = new FormData(elements);
        let formData = {};

        new Set(fd.keys()).forEach((key) => {
            const value = fd.getAll(key);
            formData[key] = value.length > 1 ? value : value[0];
        });

        // We can add custom parsing of the data before it is validated
        if (parseData)
            formData = parseData(formData);

        // We can add custom parsing of the schema before it is validated against
        if (parseSchema)
            validationSchema = parseSchema(validationSchema);

        try {
            await validationSchema.validate(formData, { abortEarly: false });
            const { errors } = await onSubmit(formData) || {};

            if (Object.keys(errors || {}).length > 0)
                throw new ValidationError(errors);
        } catch (err) {
            // eslint-disable-next-line no-nested-ternary
            const errors = (err.message.constructor === Object) ? err.message
                : (err instanceof ValidationError) ? validationErrorToObject(err) : { api: 'Something went wrong!' };

            if (scrollOnSubmit || ('api' in errors && Object.keys(errors).length >= 2))
                scrollToFirstError(elements, errors);

            if (!document.getElementsByName(Object.keys(errors)[0]).length && 'api' in errors && ErrorToast) {
                toast(<ToastError
                    errorMessage={errors.api.message}
                    showErrorModal={() => showError({
                        defaultReason: 'issue',
                        defaultPage: errorPage,
                        apiError: errors.api,
                    })}
                />, {
                    limit: 1,
                });
            }

            setFormErrors(errors);
        }

        setLoading(false);
    };

    return (
        <>
            {children({
                handleSubmit: submitForm,
                errors: formErrors,
                loading,
            })}
        </>
    );
}

ValidateForm.propTypes = {
    validationSchema: PropTypes.instanceOf(ObjectSchema).isRequired,
    onSubmit: PropTypes.func.isRequired,
    errorPage: PropTypes.string,
    toastError: PropTypes.elementType,
    parseData: PropTypes.func,
    parseSchema: PropTypes.func,
    scrollOnSubmit: PropTypes.bool,
    children: PropTypes.func.isRequired,
};

const ValidateUsername = forwardRef(({
    currentUsername,
    children,
}, ref) => {
    const [usernameError, setUsernameError] = useState('');

    const handleCheckUsername = async (newUsername) => {
        setUsernameError('');
        ref.current = true;

        // We don't want to check their pre-assigned username
        if (newUsername === currentUsername)
            return;

        // Validate the username using the yup schema
        try {
            await usernameSchema.validate(newUsername);
        } catch (err) {
            setUsernameError(err.message);
            ref.current = false;
            return;
        }

        // Check the username is not taken
        const res = await api.get({ url: 'users/available', params: { username: newUsername } });
        if (res.success && !res.data.available) {
            setUsernameError('This username has already been taken');
            ref.current = false;
        }
    };

    return children({
        handleCheckUsername,
        usernameError,
    });
});

ValidateUsername.propTypes = {
    currentUsername: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
};

export default ValidateForm;
export {
    ValidateUsername,
};
