import { useMemo, useState, useContext } from 'react';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from 'features/user';
import { DocumentMetaContext } from 'features/navigation';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import LinkIcon from 'icons/LinkIcon';
import TwitterIcon from 'icons/TwitterIcon';
import FacebookIcon from 'icons/FacebookIcon';
import WhatsappIcon from 'icons/WhatsappIcon';
import OverlayTrigger from 'ui/popups/OverlayTrigger';
import ToolTip from 'ui/popups/Tooltip';
import Mixpanel from 'adapters/mixpanel';
import PropTypes from 'prop-types';

const shareTypes = {
    facebook: {
        button: FacebookShareButton,
        icon: FacebookIcon,
    },
    twitter: {
        button: TwitterShareButton,
        icon: TwitterIcon,
    },
    whatsapp: {
        button: WhatsappShareButton,
        icon: WhatsappIcon,
    },
};

export default function ShareButton({
    title = '',
    url = '',
    trackingLocation = '',
    className = '!size-8 bg-primary text-primary-foreground old-rounded-circle old-p-1',
    wrapperClassName = '',
    types = ['facebook', 'twitter', 'whatsapp'],
}) {
    const [shareClicked, setShareClicked] = useState(false);
    const [searchParams] = useSearchParams();
    const documentMetaContext = useContext(DocumentMetaContext);
    const { user } = useContext(UserContext);

    const urlWithTracking = useMemo(() => {
        const shareUrl = `${url ? (window.location.origin + url) : documentMetaContext?.url}?${searchParams.toString()}`;

        if (!user.doesSessionExist)
            return shareUrl;

        return `${shareUrl}${searchParams.length > 0 ? '&' : '?'}utm_source=referral&utm_content=${user.auth0}`;
    }, [user]);

    const handleTrackOnClick = (e, trackingName, cb) => {
        e.stopPropagation();
        Mixpanel.trackButton('Share Button', trackingName, trackingLocation, {
            'Share URL': urlWithTracking,
        });

        if (cb)
            cb();
    };

    const copyLink = () => {
        navigator.clipboard.writeText(urlWithTracking);
        setShareClicked(true);

        // Reset back after a fixed time
        setTimeout(() => {
            setShareClicked(false);
        }, 2000);
    };

    return (
        <div className={wrapperClassName}>
            {types.map((type) => {
                if (!(type in shareTypes))
                    return null;

                const ShareButtonComponent = shareTypes[type].button;
                const ShareIcon = shareTypes[type].icon;

                return (
                    <OverlayTrigger
                        placement="left"
                        overlay={(
                            <ToolTip>
                                <div className="old-bg-white old-rounded-pill old-shadow-xl old-d-flex old-px-2 old-py-1">
                                    <small className="font-roman old-fs-10 capitalize">{type}</small>
                                </div>
                            </ToolTip>
                          )}
                        key={type}
                    >
                        <ShareButtonComponent
                            key={type}
                            url={urlWithTracking}
                            title={title || documentMetaContext?.description}
                            onClick={(e) => handleTrackOnClick(e, type)}
                        >
                            <ShareIcon className={className} />
                        </ShareButtonComponent>
                    </OverlayTrigger>
                );
            })}

            {(shareClicked) ? (
                <VerifiedOutlinedIcon className={`${className} old-p-1`} />
            ) : (
                <OverlayTrigger
                    placement="left"
                    overlay={(
                        <ToolTip>
                            <div className="old-bg-white old-rounded-pill old-shadow-xl old-d-flex old-px-2 old-py-1">
                                <small className="font-roman old-fs-10 capitalize">Copy Link</small>
                            </div>
                        </ToolTip>
                      )}
                >
                    <span onClick={(e) => { handleTrackOnClick(e, 'link', copyLink); }} role="button" tabIndex={0} className="inline-block">
                        <LinkIcon className={`${className} old-p-1`} role="button" />
                    </span>
                </OverlayTrigger>
            )}
        </div>
    );
}

ShareButton.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    trackingLocation: PropTypes.string,
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
    types: PropTypes.arrayOf(PropTypes.string),
};
