import { useState, useRef, useContext } from 'react';
import { PulseLoader } from 'react-spinners';
import { UserContext } from 'features/user';
import { Button } from 'ui';
import TagsWrapper from 'forms/wrappers/TagsWrapper';
import api from 'adapters/api';
import FormToggleButtons from 'forms/FormToggleButtons';
import PropTypes from 'prop-types';

export default function OnboardingTopics({ onComplete }) {
    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const topicsRef = useRef([]);

    const handleCompleteUserOnboarding = async () => {
        setLoading(true);

        const payload = {
            metadata: JSON.stringify({
                completed_onboarding: true,
            }),
        };

        const tags = {
            values: topicsRef.current.map((topic) => topic.value),
            group: 'design_interest',
        };

        const results = await Promise.all([
            api.patch({ url: 'users/', data: payload, headers: { 'Content-Type': 'multipart/form-data' } }),
            api.post({ url: `users/${user.id}/tags`, data: tags }),
        ]);

        if (results.filter((res) => res.success).length === 2)
            onComplete();

        setLoading(false);
    };

    return (
        <div data-testid="user-onboarding-topics">
            <h2 className="capitalize old-fs-1">Personalize your feed</h2>
            <p className="old-mb-5">(Optionally) Pick a maximum of 3 topics</p>

            <TagsWrapper
                selectedUrl={`users/${user.id}/tags`}
                group="design_interest"
            >
                {({
                    tags: topics,
                    loading: topicsLoading,
                }) => (
                    <div className="old-mb-5">
                        <FormToggleButtons
                            defaultValue={topics}
                            className="old-rounded-4 old-border-2 old-w-100 old-d-flex old-align-items-center old-justify-content-center old-my-2 old-p-3"
                            sizing={{ xs: 6 }}
                            max={3}
                            ref={topicsRef}
                            key={Object.values(topics)}
                        />

                        <PulseLoader className="old-loader text-center" loading={topicsLoading} speedMultiplier={0.5} size={15} />
                    </div>
                )}
            </TagsWrapper>

            <Button
                onClick={handleCompleteUserOnboarding}
                color="primary"
                isLoading={loading}
                trackingName="continue onboarding"
                fullWidth
            >
                Continue
            </Button>
        </div>
    );
}

OnboardingTopics.propTypes = {
    onComplete: PropTypes.func.isRequired,
};
