import { forwardRef, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import PropTypes from 'prop-types';
import { submissionPropTypes } from 'features/submission';
import { useProtectedAction } from 'features/authentication';
import { useCreateFeedback } from 'features/feedback';
import { Button } from 'ui';

import feedbackPropTypes from '../feedbackSchema';

const MIN_FEEDBACK_LENGTH = 1;
const emojis = ['🤩', '😍', '❤️', '🚀', '👏', '🔥'];

const FeedbackInput = forwardRef(({
    submission,
    parent = null,
    onCancelReply,
}, ref) => {
    const [feedbackText, setFeedbackText] = useState('');
    const { mutate, isPending } = useCreateFeedback(submission.id);
    const [handleSubmitFeedback] = useProtectedAction(async (feedback) => {
        const feedbackData = {
            content: feedback,
            ...(parent && { parent: parent.id }),
        };
        mutate(feedbackData, {
            onSuccess: () => {
                setFeedbackText('');
                if (parent)
                    onCancelReply();
            },
        });
    });
    const handleEmojiClick = (emoji) => setFeedbackText((prevText) => prevText + emoji);

    return (
        <div className="flex flex-col grow pb-2 px-4 pt-2 rounded-3xl bg-default-100">
            <div className="flex flex-wrap justify-between mt-2 mb-3 px-2 sm:px-6">
                {emojis.map((emoji) => (
                    <button
                        className="btn p-1 text-lg sm:text-2xl"
                        onClick={() => handleEmojiClick(emoji)}
                        type="button"
                    >
                        {emoji}
                    </button>
                ))}
            </div>
            <div className="relative">
                <textarea
                    ref={ref}
                    value={feedbackText}
                    onChange={(e) => setFeedbackText(e.target.value)}
                    placeholder={parent ? `Replying to ${parent.user.username.toUpperCase()}...` : 'Add some feedback...'}
                    className="bg-white border-0 rounded-3xl p-4 pb-6 w-full font-roman focus:ring-0"
                    rows="3"
                    minLength={MIN_FEEDBACK_LENGTH}
                    maxLength={600}
                />

                <Button
                    color="primary"
                    onClick={() => handleSubmitFeedback(feedbackText)}
                    isDisabled={feedbackText.length < MIN_FEEDBACK_LENGTH}
                    isLoading={isPending}
                    trackingName="give feedback"
                    data-testid="send-feedback"
                    isIconOnly
                    className="absolute bottom-4 right-2"
                >
                    <SendIcon className="ml-1" />
                </Button>
            </div>
            {parent && (
                <Button
                    onClick={onCancelReply}
                    variant="light"
                    className="ml-auto pe-3 my-1 text-default-500 hover:text-default-700"
                >
                    Cancel Reply
                </Button>
            )}
        </div>
    );
});
FeedbackInput.propTypes = {
    submission: submissionPropTypes.isRequired,
    parent: feedbackPropTypes.isRequired,
    onCancelReply: PropTypes.func.isRequired,
};

export default FeedbackInput;
