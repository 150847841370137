import { useSearchParams } from 'react-router-dom';
import { PageLayout } from 'features/navigation';
import {
    SubmissionCard as DefaultSubmissionCard, withViewCounter, SubmissionSpotlightBlock, useSubmissions,
} from 'features/submission';
import {
    Container, CardGrid, ScrollToTopButton, AutoScrollLoader,
} from 'ui';

import Filters from './Filters';

const SubmissionCard = withViewCounter(DefaultSubmissionCard);

export default function Voting() {
    const [searchParams] = useSearchParams();
    const submissions = useSubmissions({
        search: searchParams.get('search') || '',
        brief_id: searchParams.get('brief') || [],
        sort: searchParams.get('sort') || [],
        published: true,
    });

    return (
        <PageLayout
            pageName="Voting"
            showFooter={!submissions.hasNextPage}
            color="secondary-100"
            navbarProps={{
                size: 'lg',
            }}
        >
            <Container id="voting" className="old-sb-6 old-contain-paint">
                <Filters />

                <div className="old-mb-5">
                    <CardGrid
                        entries={submissions.all.slice(0, 12)}
                        card={SubmissionCard}
                    />

                    {(submissions.all.length > 0) && (
                        <Container className="bg-content2 old-text-dark old-rounded-4 old-mb-3 old-py-3 old-py-md-6">
                            <SubmissionSpotlightBlock color="primary" />
                        </Container>
                    )}

                    <CardGrid
                        entries={submissions.all.slice(12)}
                        card={SubmissionCard}
                    />
                </div>

                <AutoScrollLoader
                    fetchNextPage={submissions.fetchNextPage}
                    hasNextPage={submissions.hasNextPage}
                    isLoading={submissions.isLoading || submissions.isFetchingNextPage}
                    error={submissions.error}
                    empty={!submissions.all.length}
                    className="old-mb-5"
                >
                    There are no submissions here at the moment.
                    <br />
                    Please come back at a later date.
                </AutoScrollLoader>
            </Container>

            <ScrollToTopButton />
        </PageLayout>
    );
}
